<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import { useLoading } from 'vue-loading-overlay'
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';
import Api from '../../services/api';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Swal from 'sweetalert2';

import 'vue-select/dist/vue-select.css';

import $ from 'jquery';

let tableBatasWindspeedInstance;
const tableBatasWindspeed = ref();
const tableBatasWindspeedData = ref([]);

const selectedColorValue = ref(null);
const selectColorOptions = ref([
    { code: 'bg-primary', label: 'bg-primary' },
    { code: 'bg-secondary', label: 'bg-secondary' },
    { code: 'bg-success', label: 'bg-success' },
    { code: 'bg-danger', label: 'bg-danger' },
    { code: 'bg-warning', label: 'bg-warning' },
    { code: 'bg-info', label: 'bg-info' },
    { code: 'bg-light', label: 'bg-light' },
    { code: 'bg-dark', label: 'bg-dark' },
]);

const typeSubmit = ref()

const modalAudioUrl = ref(null)

const batasWindspeedModalLabel = ref('')

const modalBatasWindspeedData = ref({ key_id: null, keterangan: null, min: null, max: null, color: null, sound: null });
let modalBatasWindspeedInstance = null;

const loading = useLoading({});
const configObject = { keyboard: false, backdrop: 'static' }
const toast = ref(null);
const toastEl = ref({});
const dataTableOptions = ref({
    order: [],
    scrollX: true,
    initComplete: function () {
        // 
    },
    drawCallback: function (settings) {
        //
    },
});

DataTable.use(DataTablesCore);

const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

const columns = [
    {
        title: "No.",
        data: 'key_id',
        width: "20px",
        render: function (data, type, row, meta) {
            return meta.row + meta.settings._iDisplayStart + 1;
        }
    },
    { title: "Keterangan", data: 'keterangan' },
    { title: "Min. (knots)", data: 'min' },
    { title: "Max. (knots)", data: 'max' },
    {
        title: 'Color',
        orderable: false,
        data: null,
        width: '80px',
        render: function (data, type, row) {
            const disabled = false ? "disabled" : ""
            const div = `<div class="d-flex ${row.color}" style="">
                                <div style="height: 1rem; width: 5rem;"></div>
                            </div>`;
            return div;
        }
    },
    {
        title: 'Sound',
        orderable: false,
        data: null,
        width: '80px',
        render: function (data, type, row) {
            const disabled = false ? "disabled" : ""
            const btn = row.path_file_real ? `<button class="btn btn-sm btn-outline-secondary btn-play-batas-windspeed" ${disabled} title="Play Sound" data-id=${row.key_id} data-url="${row.path_file_real}"><i class="operation-icon fas fa-volume-up"></i></button>` : ''
            const div = `<div class="d-flex">
                                ${btn}
                            </div>`;
            return div;
        }
    },
    {
        title: 'Aksi',
        orderable: false,
        data: null,
        width: '80px',
        render: function (data, type, row) {
            const disabled = false ? "disabled" : ""
            const div = `<div class="d-flex">
                                <button class="btn btn-sm btn-outline-primary btn-edit-batas-windspeed mr-2" ${disabled} title="Ubah Batas Windspeed" data-id=${row.key_id} data-keterangan="${row.keterangan}" data-min="${row.min}" data-max="${row.max}" data-color="${row.color}" data-url="${row.path_file_real}"><i class="operation-icon fas fa-pen"></i></button>
                                <button class="btn btn-sm btn-outline-danger btn-remove-batas-windspeed" ${disabled} title="Hapus Batas Windspeed" data-id=${row.key_id} ><i class="operation-icon fas fa-trash-alt"></i></button>
                            </div>`;
            return div;
        }
    },
];

const createBatasWindspeed = async () => {
    $('#fileTargetIdEl').hide()
    $("#fileSoundIdEl").val(null).trigger('reset')
    const loader = loading.show({});
    try {
        modalBatasWindspeedData.value.key_id = null
        modalBatasWindspeedData.value.keterangan = null
        modalBatasWindspeedData.value.min = 0
        modalBatasWindspeedData.value.max = 0
        modalBatasWindspeedData.value.color = selectColorOptions.value[0].code
        modalBatasWindspeedData.value.sound = null

        selectedColorValue.value = selectColorOptions.value[0].code
        showBatasWindspeedModal(null, 'create')
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide()
    }
}

const editBatasWindspeed = async (id, keterangan, min, max, color, url) => {
    $("#fileSoundIdEl").val(null).trigger('reset')
    const loader = loading.show({});
    try {
        modalBatasWindspeedData.value.key_id = id
        modalBatasWindspeedData.value.keterangan = keterangan
        modalBatasWindspeedData.value.min = min
        modalBatasWindspeedData.value.max = max
        modalBatasWindspeedData.value.color = color
        modalBatasWindspeedData.value.sound = null

        if (url == 'null') {
            modalAudioUrl.value = null
            $('#fileTargetIdEl').hide()
        } else {
            modalAudioUrl.value = url
            $('#fileTargetIdEl').show()
        }

        selectedColorValue.value = color
        showBatasWindspeedModal(id, 'edit')
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide()
    }
}


const showBatasWindspeedModal = (id, operation) => {
    batasWindspeedModalLabel.value = operation === 'edit' ? 'Ubah Batas Windspeed' : 'Tambah Batas Windspeed';
    typeSubmit.value = operation === 'edit' ? 'edit' : 'create';

    if (!modalBatasWindspeedInstance) {
        const modalElement = document.getElementById('batas-windspeed-modal');
        modalBatasWindspeedInstance = new bootstrap.Modal(modalElement, configObject);
    }
    modalBatasWindspeedInstance.show();
};

const submitBatasWindspeedHandler = async () => {
    // return console.log(modalBatasWindspeedData.value);

    if (modalBatasWindspeedData.value.sound && $("#fileSoundIdEl").val() != '') {
        const fileSound = document.getElementById('fileSoundIdEl').files[0];

        // check fileFormat
        const splitedFileName = fileSound.name.split('.');
        let validFile = true;
        if (splitedFileName.length == 1) {
            validFile = false;
        } else {
            const ext = splitedFileName[splitedFileName.length - 1].toLowerCase()
            const allowedType = ["mp3"];
            if (!allowedType.includes(ext)) {
                console.log(`ext is ${ext}`);
                validFile = false;
            }
        }
        if (!validFile) {
            return showToast('Format sound tidak sesuai', 'danger');
        }
    } else if (!modalBatasWindspeedData.value.key_id) {
        modalBatasWindspeedData.value.sound = null
        Swal.fire({
            icon: "warning",
            text: "Harap isi file sound",
        });
        return
    } else {
        modalBatasWindspeedData.value.sound = null
    }

    if (modalBatasWindspeedData.value.max < modalBatasWindspeedData.value.min) {
        Swal.fire({
            icon: "warning",
            text: "Max. tidak boleh lebih kecil dari min.",
        });
        return
    }

    const loader = loading.show({});
    try {
        const { data } = await Api.post(`api/batas_windspeed/save`, modalBatasWindspeedData.value, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        if (data.success) {
            modalBatasWindspeedInstance.hide();
            showToast(data.message, 'success');
            fetchData()
        } else {
            showToast(data.message, 'danger');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        showToast(error, 'danger');
    } finally {
        loader.hide()
    }
};

const fetchData = async () => {
    const loader = loading.show({});
    try {
        const { data } = await Api.get('api/batas_windspeed/preload', {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        tableBatasWindspeedData.value = data.data;
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        loader.hide()
        setTimeout(() => {
            tableBatasWindspeedInstance.columns.adjust().draw()
        }, 0);
    }
};

const showToast = (message, bg) => {
    toastEl.value.message = message;
    toastEl.value.bg = `text-bg-${bg}`;
    toast.value.show();

    setTimeout(() => {
        toast.value.hide();
    }, 3000);
};

const clickEventHandler = async (event) => {
    const targetEditBatasWindspeed = event.target.closest('.btn-edit-batas-windspeed');
    if (targetEditBatasWindspeed) {
        const id = targetEditBatasWindspeed.getAttribute('data-id');
        const keterangan = targetEditBatasWindspeed.getAttribute('data-keterangan');
        const min = targetEditBatasWindspeed.getAttribute('data-min');
        const max = targetEditBatasWindspeed.getAttribute('data-max');
        const color = targetEditBatasWindspeed.getAttribute('data-color');
        const url = targetEditBatasWindspeed.getAttribute('data-url')
        editBatasWindspeed(id, keterangan, min, max, color, url);
    }

    const targetDeleteBatasWindspeed = event.target.closest('.btn-remove-batas-windspeed');
    if (targetDeleteBatasWindspeed) {
        const id = targetDeleteBatasWindspeed.getAttribute('data-id');
        const { isConfirmed, value } = await Swal.fire({
            text: 'Anda yakin akan menghapus batas windspeed ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            inputPlaceholder: 'Alasan reject',
            showLoaderOnConfirm: true,
            confirmButtonClass: 'btn btn-primary w-xs me-2',
            cancelButtonClass: 'btn btn-danger w-xs',
            buttonsStyling: false,
        })

        if (isConfirmed) {
            const loader = loading.show({});
            try {
                const { data } = await Api.delete(`api/batas_windspeed/delete`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + localStorage.token
                    }, data: {
                        id
                    }
                });
                if (data.success) {
                    showToast(data.message, 'success');
                    fetchData();
                } else {
                    showToast(data.message, 'danger');
                }
            } catch (error) {
                console.error(error);
                showToast(error, 'danger');
            } finally {
                loader.hide()
            }
        }
    }

    const playButton = event.target.closest('.btn-play-batas-windspeed');
    if (playButton) {
        const url = playButton.getAttribute('data-url');
        const audio = new Audio(cacheBustedUrl(url));
        audio.play();
    }
}

const cacheBustedUrl = (url) => {
    const timestamp = new Date().getTime();
    const separator = url.includes('?') ? '&' : '?';
    return `${url}${separator}cb=${timestamp}`;
};

const handleChangeColor = (value) => {
    const temp = modalBatasWindspeedData
    if (value) {
        temp.value.color = value.code
    } else {
        temp.value.color = null
    }
    modalBatasWindspeedData.value = temp.value
};

const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        modalBatasWindspeedData.value.sound = file;
    }
};

const playModalPreviewSound = () => {
    const audio = new Audio(cacheBustedUrl(modalAudioUrl.value));
    audio.play();
}

onMounted(async () => {
    console.log(Api)
    tableBatasWindspeedInstance = tableBatasWindspeed.value.dt;
    await fetchData();

    // event listeners
    document.removeEventListener('click', clickEventHandler);
    document.addEventListener('click', clickEventHandler);

    toast.value = new bootstrap.Toast(document.getElementById('toast'), { delay: 3000 });
});

onBeforeUnmount(() => {
    document.removeEventListener('click', clickEventHandler);
});
</script>

<template>
    <div class="toast-container position-fixed top-0 end-0 p-3">
        <div id="toast" :class="'toast align-items-center border-0 ' + toastEl.bg" role="alert" aria-live="assertive"
            aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    {{ toastEl.message }}
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                    aria-label="Close"></button>
            </div>
        </div>
    </div>

    <div id="batas-windspeed-modal" class="modal fade" aria-labelledby="batas-windspeed-modal-label" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ batasWindspeedModalLabel }}</h5>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form method="post" id="batas-kerja-form" @submit.prevent="submitBatasWindspeedHandler">
                        <div class="row">
                            <input type="hidden" class="form-control" v-model="modalBatasWindspeedData.key_id"
                                required />
                            <div class="col-12">
                                <div class="mt-0">
                                    <label for="keterangan" class="form-label">Keterangan</label>
                                    <input type="text" name="keterangan" class="form-control"
                                        v-model="modalBatasWindspeedData.keterangan" required
                                        placeholder="Masukkan keterangan">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="mt-3">
                                    <label for="min" class="form-label">Min. (knots)</label>
                                    <input type="number" name="min" class="form-control" step="1" min="0"
                                        onkeyup="this.value=this.value.replace(/[^\d]/,'')"
                                        v-model="modalBatasWindspeedData.min" required placeholder="Masukkan nilai minimal">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="mt-3">
                                    <label for="max" class="form-label">Max. (knots)</label>
                                    <input type="number" name="min" class="form-control" step="1" min="0"
                                        onkeyup="this.value=this.value.replace(/[^\d]/,'')"
                                        v-model="modalBatasWindspeedData.max" required placeholder="Masukkan nilai maksimal">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="mt-3">
                                    <label for="color" class="form-label">Color</label>
                                    <v-select v-model="selectedColorValue" :options="selectColorOptions"
                                        :clearable="false" @update:modelValue="handleChangeColor"
                                        placeholder="Harap pilih warna" required>
                                        <template #option="option">
                                            <div style="display: flex; align-items: center;">
                                                <div :class="[option.code]"
                                                    :style="{ width: '1rem', height: '1rem', marginRight: '8px' }">
                                                </div>
                                                <span>{{ option.label }}</span>
                                            </div>
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="mt-3">
                                    <label class="form-label">File Sound</label>
                                    <input type="file" class="form-control" id="fileSoundIdEl" accept=".mp3"
                                        @change="handleFileChange">
                                </div>
                            </div>
                            <div class="col-12 " id="fileTargetIdEl">
                                <div class="mt-3 row">
                                    <div class="col-12">
                                        <label class="form-label">Uploaded File Sound</label>
                                    </div>
                                    <div class="col-12">
                                        <button type="button" class="btn btn-sm btn-primary"
                                            @click="playModalPreviewSound" title="Pratinjau">Play</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="submit" form="batas-kerja-form" class="btn btn-success"
                        data-bs-dismiss="modal">Simpan</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="card">
            <div class="card-header">
                <h5 class="mb-0">Batas Windspeed</h5>
            </div>
            <div class="card-body">
                <button class="btn btn-primary mb-2" @click="createBatasWindspeed">Tambah Batas Windspeed</button>
                <DataTable :columns="columns" :data="tableBatasWindspeedData" :options="dataTableOptions"
                    ref="tableBatasWindspeed" class="table table-bordered datatableBatasWindspeedClass" width="100%">
                </DataTable>
            </div>
        </div>
    </div>
</template>

<style>
@import 'bootstrap';
@import 'datatables.net-bs5';
</style>

<style scoped>
.select2-dropdown {
    z-index: 99999 !important;
    /* Ensure this is higher than the modal z-index */
}

.xxx {
    position: relative;
    /* Ensure the parent has positioning */
}
</style>

<style>
table.datatable-details>thead>tr>th {
    padding: 8px 30px 8px 8px !important;
    min-height: 41.2px !important;
}

div.dt-scroll-body>table.datatable-details>thead {
    /* display: none !important; */
    /* height: 1px !important; */
}

div.dt-scroll-body>table.datatableBatasWindspeedClass {
    overflow: hidden !important;
}
</style>