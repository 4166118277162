<script setup>
//import reactive and ref from vue
import { reactive, onMounted, onUnmounted, onBeforeUnmount, ref } from 'vue'

//import store user
import { useUser } from '../../stores/user'

//define variable userStore 
const userStore = useUser()

//import useRouter from vue router
import { useRouter } from 'vue-router'

import Swal from 'sweetalert2';

// Import component
import { useLoading } from 'vue-loading-overlay'
import $ from 'jquery';
import Datepicker from 'vue3-datepicker'
import moment from 'moment';
import 'moment/locale/id';

//inisialisasi vue router on Composition API
const router = useRouter()
const $loading = useLoading({});
const fullPage = ref(false);
const notification = ref([])
const toast = ref(null);
const toastEl = ref({});

const periodeFormat = 'dd-MM-yyyy'
const selectedPeriodeAwal = ref(new Date())
const selectedPeriodeAkhir = ref(new Date())
const inputPeriodeAwalRef = ref(null)
const inputPeriodeAkhirRef = ref(null)
const periodeAwalLimit1 = ref(new Date());
const periodeAkhirLimit1 = ref(new Date());
const periodeAwalLimit2 = ref(new Date());
const periodeAkhirLimit2 = ref(new Date());

const configObject = { keyboard: false, backdrop: 'static' }

const modalDelHistoryManualData = ref({ periode_awal: '', periode_akhir: '' });
let modalDelHistoryManualInstance = null;
const datetimeserver = ref();
const isAdmin = ref(false)

import Api from '../../services/api';

let intervalId

const getNotification = async () => {
	// console.log(JSON.parse(localStorage.user));
	// const user_id = JSON.parse(localStorage.user).id
	const user = JSON.parse(localStorage.getItem('user') || '{}');
	const user_id = user?.id || null;

	if (user_id) {
		try {
			const { data } = await Api.get('api/get_notification', {
				params: { user_id },
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json",
					'Authorization': 'Bearer ' + localStorage.token
				},
			});
			isAdmin.value = data.isAdmin
			// console.log(data.data[0]);
			notification.value = data.data
			if (data.data.length > 0) {
				$('#target-bell').removeClass()
				$('#target-bell').addClass("fas fa-bell text-primary goyang-dombret fs-5")
				$('#notif_count_badge').removeClass('d-none')
			} else {
				$('#target-bell').removeClass()
				$('#target-bell').addClass("far fa-bell fs-5")
				$('#notif_count_badge').addClass('d-none')
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			// loader.hide()
		}
	}
};

const clickEventHandler = async (event) => {
	const delHistoryAuto = event.target.closest('.del-history-auto');
	if (delHistoryAuto) {
		const id = delHistoryAuto.getAttribute('data-id');
		// return console.log(id);
		const loader = $loading.show({});
		try {
			const { data } = await Api.post('api/delete_history/delAutoNotif', { id }, {
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json",
					'Authorization': 'Bearer ' + localStorage.token
				},
			});
			// console.log(data);
			if (data.success) {
				showToast(data.message, 'success');
				await getNotification()
			} else {
				showToast(data.message, 'danger');
			}
		} catch (error) {
			console.error('Error fetching data:', error);
			showToast(error, 'danger');
		} finally {
			loader.hide()
		}
	}

	const delHistoryManual = event.target.closest('.del-history-manual');
	if (delHistoryManual) {
		showDelHistoryManualModal();
	}
}

const showDelHistoryManualModal = async () => {
	const loader = $loading.show({});
	try {
		const { data } = await Api.get('api/delete_history/manualInfo', {
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + localStorage.token
			},
		});

		// console.log(data.data[0]);

		periodeAwalLimit1.value = new Date(data.data[0].start_deleted_date);
		periodeAwalLimit2.value = new Date(data.data[0].start_deleted_date);
		periodeAkhirLimit1.value = new Date(data.data[0].start_deleted_date);
		periodeAkhirLimit2.value = new Date(data.data[0].end_deleted_date);

		selectedPeriodeAwal.value = new Date(data.data[0].start_deleted_date)
		selectedPeriodeAkhir.value = new Date(data.data[0].end_deleted_date)

		modalDelHistoryManualData.value.periode_awal = moment(data.data[0].start_deleted_date).format('YYYY-MM-DD')
		modalDelHistoryManualData.value.periode_akhir = moment(data.data[0].end_deleted_date).format('YYYY-MM-DD')

		showDelHistoryManualModalDirectly()
	} catch (error) {
		console.error(error);
	} finally {
		loader.hide()
	}
}

const showDelHistoryManualModalDirectly = () => {
	if (!modalDelHistoryManualInstance) {
		const modalElement = document.getElementById('del-history-manual-modal');
		modalDelHistoryManualInstance = new bootstrap.Modal(modalElement, configObject);
	}
	modalDelHistoryManualInstance.show();
};

let activityTimeout;

const resetActivityTimer = () => {
	// console.log('reset');
	
    clearTimeout(activityTimeout);
    activityTimeout = setTimeout(() => {
        // Logout or handle inactivity
        alert("User inactive, logging out...");
        localStorage.removeItem('token');
        // localStorage.removeItem('refresh_token');
		localStorage.setItem('position', '/')
        window.location.href = '/'; // Redirect to login
    }, 1000 * 60 * 30); // ms * s * m
}

onMounted(async () => {
	window.Echo.channel('waktu-server')
		.listen('WaktuServer', (e) => {
			datetimeserver.value = e.datetime
		})
	console.log('notif-loop-initiated');
	$("#notification-target-parent").hide()
	await getNotification()

	// console.log(isAdmin.value);
	if (isAdmin.value) {
		$("#notification-target-parent").show()
		if (notification.value.length >= 1) {
			await Swal.fire({
				icon: "warning",
				text: "Harap cek notifikasi",
			});
			setTimeout(() => {
				$($.fn.dataTable.tables(true)).DataTable().columns.adjust();
			}, 200);
		}
		intervalId = setInterval(async () => {
			await getNotification()
		}, 20000)
	} else {
	}

	inputPeriodeAwalRef.value = document.getElementById('periode_awal_parent_v')
	inputPeriodeAkhirRef.value = document.getElementById('periode_akhir_parent_v')

	document.removeEventListener('click', clickEventHandler);
	document.addEventListener('click', clickEventHandler);

	toast.value = new bootstrap.Toast(document.getElementById('toast_parent_on_navbar'), { delay: 10000 });

	//
	document.addEventListener('mousemove', resetActivityTimer);
	document.addEventListener('keydown', resetActivityTimer);

	resetActivityTimer();
})

const showToast = (message, bg) => {
	toastEl.value.message = message;
	toastEl.value.bg = `text-bg-${bg}`;
	toast.value.show();

	setTimeout(() => {
		toast.value.hide();
	}, 10000);
};

const handleChangePeriodeAkhir = (value) => {
	if (inputPeriodeAkhirRef.value) {
		inputPeriodeAkhirRef.value.blur()
	}
	const temp = modalDelHistoryManualData
	temp.value.periode_akhir = moment(value).format('YYYY-MM-DD')
	modalDelHistoryManualData.value = temp.value

	periodeAkhirLimit1.value = new Date(value)
};

const submitDelHistoryManualHandler = async () => {

	// return console.log(moment(modalDelHistoryManualData.value.periode_akhir).format('DD MMMM YYYY'));
	// return console.log(modalDelHistoryManualData.value);

	const { isConfirmed, value } = await Swal.fire({
		text: 'Anda yakin akan menghapus data transaksi mulai ' + moment(modalDelHistoryManualData.value.periode_awal).format('DD MMMM YYYY') + ' s/d ' + moment(modalDelHistoryManualData.value.periode_akhir).format('DD MMMM YYYY') + ' ?',
		showCancelButton: true,
		confirmButtonText: 'Ya',
		cancelButtonText: 'Batal',
		inputPlaceholder: 'Alasan reject',
		showLoaderOnConfirm: true,
		confirmButtonClass: 'btn btn-primary w-xs me-2',
		cancelButtonClass: 'btn btn-danger w-xs',
		buttonsStyling: false,
	})

	if (!isConfirmed) {
		return modalDelHistoryManualInstance.hide();
	}

	const loader = $loading.show({});
	try {
		const { data } = await Api.post(`api/delete_history/saveManual`, modalDelHistoryManualData.value, {
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + localStorage.token
			},
		});

		if (data.success) {
			showToast(data.message, 'success');
			modalDelHistoryManualInstance.hide();
			await getNotification()
		} else {
			showToast(data.message, 'danger');
		}
	} catch (error) {
		console.error('Error submitting form:', error);
		showToast(error, 'danger');
	} finally {
		loader.hide()
	}
};

onUnmounted(() => {
	console.log('notif-loop-terminated');

	if (isAdmin.value) {
		clearInterval(intervalId);
	} else {

	}

	document.removeEventListener('mousemove', resetActivityTimer);
	document.removeEventListener('mousemove', resetActivityTimer);
})

onBeforeUnmount(() => {
	document.removeEventListener('click', clickEventHandler);
	document.removeEventListener('mousemove', resetActivityTimer);
	document.removeEventListener('mousemove', resetActivityTimer);
});

//method logout
function logout() {
	const loader = $loading.show({});
	//call action pinia "logout"

	userStore.logout()
		.then(() => {
			//redirect to login
			// router.push({ name: 'windspeed' })
		}).catch(error => {
			console.error(error)
			// this.$swal('Hello Vue world!!!'); //error kalau pakai swal
		})
	setTimeout(() => {
		loader.hide()
		router.push({ name: 'windspeed' })
	}, 5000)
}
</script>
<template>
	<div class="toast-container position-fixed top-0 end-0 p-3">
		<div id="toast_parent_on_navbar" :class="'toast align-items-center border-0 ' + toastEl.bg" role="alert"
			aria-live="assertive" aria-atomic="true">
			<div class="d-flex">
				<div class="toast-body">
					{{ toastEl.message }}
				</div>
				<button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
					aria-label="Close"></button>
			</div>
		</div>
	</div>

	<div id="del-history-manual-modal" class="modal fade" aria-labelledby="del-history-manual-modal-label"
		aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Hapus Data Scheduler</h5>
					<button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<form method="post" id="del-history-manual-form" @submit.prevent="submitDelHistoryManualHandler">
						<div class="row">
							<div class="col-12">
								<label for="periode_awal_parent_v" class="form-label">Periode Awal</label>
								<Datepicker id="periode_awal_parent_v" class="form-control bg-white"
									v-model="selectedPeriodeAwal" :inputFormat="periodeFormat"
									:upper-limit="periodeAkhirLimit1" :lower-limit="periodeAwalLimit1"
									placeholder="Pilih periode awal" startingView="day" minimumView="day" />
							</div>
							<div class="col-12 mt-3">
								<label for="periode_akhir_parent_v" class="form-label">Periode Akhir</label>
								<Datepicker id="periode_akhir_parent_v" class="form-control bg-white"
									v-model="selectedPeriodeAkhir" @update:modelValue="handleChangePeriodeAkhir"
									:inputFormat="periodeFormat" :upper-limit="periodeAkhirLimit2"
									:lower-limit="periodeAwalLimit2" placeholder="Pilih periode akhir"
									startingView="day" minimumView="day" />
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer">
					<button type="submit" form="del-history-manual-form" class="btn btn-success"
						data-bs-dismiss="modal">Hapus</button>
					<button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
				</div>
			</div>
		</div>
	</div>

	<nav class="main-header navbar navbar-expand navbar-white navbar-light px-2">
		<ul class="navbar-nav">
			<li class="nav-item">
				<a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
			</li>
			<li class="nav-item">
				<!-- <a class="nav-link fw-bolder" href="#">
					Aplikasi Dashboard Windspeed
				</a> -->
				<div class="nav-link fw-bolder">
					<span>Aplikasi Dashboard Windspeed</span>
				</div>
				<div class="nav-link d-block d-sm-none py-0 h-25" style="font-size: 10px">
					<span>Waktu Server: {{ datetimeserver }}</span>
				</div>
			</li>
		</ul>

		<ul class="navbar-nav ml-auto">
			<li class="nav-item">
				<div class="nav-link d-none d-sm-block">
					<span>Waktu Server: {{ datetimeserver }}</span>
				</div>
			</li>
			<li class="nav-item dropdown me-2" id="notification-target-parent">
				<a class="nav-link" data-toggle="dropdown" href="#">
					<i class="far fa-bell fs-5" id="target-bell"></i>
					<span class="position-absolute top-2 start-80 translate-middle badge rounded-pill bg-danger d-none"
						id="notif_count_badge">
						{{ notification.length }}
						<span class="visually-hidden">unread messages</span>
					</span>
					<!-- <span class="badge badge-danger navbar-badge">3</span> -->
				</a>
				<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right py-1" style="width: 400px;">
					<span class="Xdropdown-item dropdown-header text-start" @click.stop>{{ notification.length }}
						Notifications</span>
					<div v-if="notification.length != 0" class="dropdown-divider"></div>
					<div style="max-height: 50vh !important; overflow-y: scroll;">
						<div v-for="(item, index) in notification" :key="index">
							<div href="#" class="dropdown-item make-pointer del-history-auto" :data-id="item.key_id"
								v-if="item.judul == 'Hapus Data Otomatis'">
								<div class="media">
									<div class="media-body">
										<h3 class="dropdown-item-title">
											[ {{ item.judul }} ]
										</h3>
										<p class="text-sm mt-2">
											{{ item.pesan }}
										</p>
									</div>
								</div>
							</div>
							<div href="#" class="dropdown-item make-pointer del-history-manual"
								v-else-if="item.judul == 'Hapus Data Scheduler'">
								<div class="media">
									<div class="media-body">
										<h3 class="dropdown-item-title">
											[ {{ item.judul }} ]
										</h3>
										<p class="text-sm mt-2">
											{{ item.pesan }}
										</p>
									</div>
								</div>
							</div>
							<div href="#" class="dropdown-item make-pointer indikator-kerusakan-nothing"
								v-else-if="item.judul == 'Kerusakan Alat'">
								<div class="media">
									<div class="media-body">
										<h3 class="dropdown-item-title">
											[ {{ item.judul }} ]
										</h3>
										<p class="text-sm mt-2">
											{{ item.pesan }}
										</p>
									</div>
								</div>
							</div>
							<router-link class="" style="text-decoration: none;" :to="item.interface" v-else>
								<div href="#" class="dropdown-item">
									<div class="media">
										<div class="media-body">
											<h3 class="dropdown-item-title">
												[ {{ item.judul }} ]
											</h3>
											<p class="text-sm mt-2">
												{{ item.pesan }}
											</p>
										</div>
									</div>
								</div>
							</router-link>
							<div v-if="index != notification.length - 1" class="dropdown-divider"></div>
						</div>
					</div>

					<!-- <a href="#" class="dropdown-item">
						<div class="media">
							<img src="dist/img/user8-128x128.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3">
							<div class="media-body">
								<h3 class="dropdown-item-title">
									John Pierce
									<span class="float-right text-sm text-muted"><i class="fas fa-star"></i></span>
								</h3>
								<p class="text-sm">I got your message bro</p>
								<p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
							</div>
						</div>
					</a> -->
				</div>
			</li>
			<li class="nav-item dropdown">
				<a class="nav-link" data-toggle="dropdown" href="#">
					<i class="far fa-user fs-5"></i>
				</a>
				<div class="dropdown-menu dropdown-menu-right">
					<!-- <a href="#" class="dropdown-item">
						<i class="fas fa-user mr-2"></i> My Profile
					</a>
					<div class="dropdown-divider"></div> -->
					<!-- <button class="btn btn-danger btn-md border-0 shadow" @click="logout">Log Out</button> -->
					<a href="#" @click="logout" class="dropdown-item">
						<i class="fas fa-sign-out-alt mr-2"></i> Log Out
					</a>
				</div>
			</li>
		</ul>
	</nav>
</template>

<style>
@-webkit-keyframes tada {
	from {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}

	10%,
	20% {
		-webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
		transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
	}

	30%,
	50%,
	70%,
	90% {
		-webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
		transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
	}

	40%,
	60%,
	80% {
		-webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
		transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
	}

	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

@keyframes tada {
	from {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}

	10%,
	20% {
		-webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
		transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
	}

	30%,
	50%,
	70%,
	90% {
		-webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
		transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
	}

	40%,
	60%,
	80% {
		-webkit-transform: rotate3d(0, 0, 1, -10deg);
		transform: rotate3d(0, 0, 1, -10deg);
	}

	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

.goyang-dombret {
	-webkit-animation: tada 1.5s ease infinite;
	animation: tada 1.5s ease infinite;
}

#notif_count_badge {
	font-size: .6em;
}

.make-pointer:hover {
	cursor: pointer;
}
</style>