// src/utils/xlsxUtils.js
import * as XLSX from 'xlsx-js-style';

const clamp_range = (range) => {
    if (range.e.r >= (1 << 20)) range.e.r = (1 << 20) - 1;
    if (range.e.c >= (1 << 14)) range.e.c = (1 << 14) - 1;
    return range;
}

var crefregex = /(^|[^._A-Z0-9])([$]?)([A-Z]{1,2}|[A-W][A-Z]{2}|X[A-E][A-Z]|XF[A-D])([$]?)([1-9]\d{0,5}|10[0-3]\d{4}|104[0-7]\d{3}|1048[0-4]\d{2}|10485[0-6]\d|104857[0-6])(?![_.\(A-Za-z0-9])/g;


export const insert_rows = (ws, start_row, nrows, opts) => {
    if (!ws) throw new Error("operation expects a worksheet");
    var dense = Array.isArray(ws);
    if (!nrows) nrows = 1;
    if (!start_row) start_row = 0;
    if (!opts) opts = {};

    /* extract original range */
    var range = XLSX.utils.decode_range(ws["!ref"]);
    var R = 0, C = 0;

    var formula_cb = function ($0, $1, $2, $3, $4, $5) {
        var _R = XLSX.utils.decode_row($5), _C = XLSX.utils.decode_col($3);
        if (!opts.fill ? (_R >= start_row) : (R >= start_row)) _R += nrows;
        return $1 + ($2 == "$" ? $2 + $3 : XLSX.utils.encode_col(_C)) + ($4 == "$" ? $4 + $5 : XLSX.utils.encode_row(_R));
    };

    var addr, naddr, newcell;
    /* move cells and update formulae */
    if (dense) {
        /* cells after the insert */
        for (R = range.e.r; R >= start_row; --R) {
            if (ws[R]) ws[R].forEach(function (cell) { if (cell.f) cell.f = cell.f.replace(crefregex, formula_cb); });
            ws[R + nrows] = ws[R];
        }

        /* TODO: dense mode; newly created space */
        for (R = start_row; R < start_row + nrows; ++R) ws[R] = [];

        /* cells before insert */
        for (R = 0; R < start_row; ++R) {
            if (ws[R]) ws[R].forEach(function (cell) { if (cell.f) cell.f = cell.f.replace(crefregex, formula_cb); });
        }
        range.e.r += nrows;
    } else {
        /* cells after the insert */
        for (R = range.e.r; R >= start_row; --R) {
            for (C = range.s.c; C <= range.e.c; ++C) {
                addr = XLSX.utils.encode_cell({ r: R, c: C });
                naddr = XLSX.utils.encode_cell({ r: R + nrows, c: C });
                if (!ws[addr]) { delete ws[naddr]; continue; }
                if (opts.fill && (ws[addr].s || ws[addr].f)) {
                    newcell = {};
                    if (ws[addr].f) { newcell.f = ws[addr].f; newcell.t = ws[addr].t; }
                    else { newcell.t = "z"; }
                }
                if (ws[addr].f) ws[addr].f = ws[addr].f.replace(crefregex, formula_cb);
                ws[naddr] = ws[addr];
                if (opts.fill) { ws[addr] = newcell; console.log(ws[addr], newcell); }
                if (range.e.r < R + nrows) range.e.r = R + nrows;
            }
        }

        /* newly created space */
        if (!opts.fill) for (R = start_row; R < start_row + nrows; ++R) {
            for (C = range.s.c; C <= range.e.c; ++C) {
                addr = XLSX.utils.encode_cell({ r: R, c: C });
                delete ws[addr];
            }
        }

        /* cells before insert */
        for (R = 0; R < start_row; ++R) {
            for (C = range.s.c; C <= range.e.c; ++C) {
                addr = XLSX.utils.encode_cell({ r: R, c: C });
                if (ws[addr] && ws[addr].f) ws[addr].f = ws[addr].f.replace(crefregex, formula_cb);
            }
        }
    }

    /* write new range */
    ws["!ref"] = XLSX.utils.encode_range(clamp_range(range));

    /* merge cells */
    if (ws["!merges"]) ws["!merges"].forEach(function (merge, idx) {
        var mergerange;
        switch (typeof merge) {
            case 'string': mergerange = XLSX.utils.decode_range(merge); break;
            case 'object': mergerange = merge; break;
            default: throw new Error("Unexpected merge ref " + merge);
        }
        if (mergerange.s.r >= start_row) mergerange.s.r += nrows;
        if (mergerange.e.r >= start_row) mergerange.e.r += nrows;
        clamp_range(mergerange);
        ws["!merges"][idx] = mergerange;
    });

    /* rows */
    var rowload = [start_row, 0];
    for (R = 0; R < nrows; ++R) rowload.push(void 0);
    if (ws["!rows"]) ws["!rows"].splice.apply(ws["!rows"], rowload);
}
