<script setup>

</script>

<template>
    <div class="error-page">
        <h3 class="headline text-warning">404</h3>
        <div class="error-content pt-4">
            <h3><i class="fas fa-exclamation-triangle text-warning"></i> Oops! Page not found.</h3>
            <p>
                We could not find the page you were looking for.
                Meanwhile, you may <a href="/">return to dashboard</a>
            </p>
        </div>
    </div>
</template>