//import pinia
import { defineStore } from 'pinia'

//import services api
import Api from '../services/api'

//define store
export const useUser = defineStore('user', {
    state: () => {
        return {
            user: JSON.parse(localStorage.getItem('user')) || {},
            token: localStorage.getItem('token') || '',
            pathlist: JSON.parse(localStorage.getItem('pathlist')) || {},
        }
    },
    actions: {
        //action "register"
        async register(credentials) {
            //fetch API
            await Api.post('/api/register', credentials) 
        },

        //action "login"
        async login(credentials) {
            //fetch API
            await Api.post('/api/login', credentials)
            .then((response) => {

                //set state
                this.user = response.data.user
                this.token = response.data.token

                //set localStorage untuk menyimpan token dan data user
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('user', JSON.stringify(response.data.user))
                localStorage.setItem('pathlist', JSON.stringify(response.data.pathlist))
                window.location.href = "/"
            })
        },

        //action "logout"
        async logout() {
            // set header Authorization with Bearer + token
            Api.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
            //fetch API
            await Api.post('/api/logout')
            .then((response) => {

                //set state
                this.user = {}
                this.token = ''

                //set localStorage untuk menyimpan token dan data user
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('pathlist')
                localStorage.setItem('position', '/')
            })
        }
    },
    getters: {
        getUser: (state) => state.user,
        getToken: (state) => state.token, 
        getPathList: (state) => state.pathlist
    }
})