import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '../layouts/MainLayout.vue'
import HomeView from '../views/HomeView.vue'
import WindspeedView from '../views/WindspeedView.vue'
import DashboardView from '../views/DashboardView.vue'
import LoginView from '../views/LoginView.vue'
import RoleView from '../views/setting/role.vue'
import UserView from '../views/setting/user.vue'
import MenuView from '../views/setting/menu.vue'
import MasterLocDev from '../views/master/MasterLocDev.vue'
import MasterGroup from '../views/master/MasterGroup.vue'
import MasterKalibrasi from '../views/master/MasterKalibrasi.vue'
import ReportHistory from '../views/report/ReportHistory.vue'
import ReportAverage from '../views/report/ReportAverage.vue'
import DeleteHistory from '../views/setting/DelHistory.vue'
import LoggerDeleteHistory from '../views/setting/LoggerDelHistory.vue'
import IndikatorKerusakan from '../views/setting/IndikatorKerusakan.vue'
import BatasWindspeed from '../views/setting/BatasWindspeed.vue'
import UnitKerja from '../views/setting/UnitKerja.vue'
import ResetData from '../views/setting/ResetData.vue'
import NotFound from '../views/NotFound.vue'

//import store user
import { useUser } from '../stores/user'

import Api from '../services/api';

// token = useCheckToken();

let pathList = []

const isAllowed = to => {
	if (useUser().getPathList) {
		const parsedPathList = useUser().getPathList
		// parsedPathList.map(item => {
		// 	// console.log(item);
		// })
		// console.log(parsedPathList);
		if (Array.isArray(parsedPathList)) {
			if (parsedPathList.includes(to)) {
				localStorage.setItem('position', to)
				return true
			} else {
				localStorage.setItem('position', '/home')
				return false
			}
		} else {
			localStorage.setItem('position', '/home')
			return false
		}
		// console.log(data);
	} else {
		localStorage.setItem('position', '/home')
		return false
	}
}

const routes = [
	// {
	//   path : '',
	//   name : 'windspeed',
	//   beforeRouteEnter : (to, from, next) => {
	//     console.log('Awal', useUser().getToken) 
	//     useUser().getToken ? next('/home') : next()
	//   },
	//   component: WindspeedView,

	// },
	{
		path: '',
		name: 'windspeed',
		beforeEnter: (to, from, next) => {
			// useUser().getToken ? next('/home') : next()
			if (useUser().getToken) {
				localStorage.setItem('position', '/home')
				next('/home')
			} else {
				localStorage.setItem('position', to.path)
				next()
			}
		},
		component: WindspeedView,

	},
	{
		path: '/home',
		component: MainLayout,
		children: [
			{
				path: '/home',
				name: 'home',
				beforeEnter: (to, from, next) => {
					// useUser().getToken ? next() : next('/login')
					if (useUser().getToken) {
						localStorage.setItem('position', to.path)
						next()
					} else {
						localStorage.setItem('position', '/login')
						next('/login')
					}
				},
				component: HomeView
			},
			{
				path: '/user',
				name: 'user',
				beforeEnter: (to, from, next) => {
					// useUser().getToken ? next() : next('/login')
					if (useUser().getToken) {
						localStorage.setItem('position', to.path)
						next()
					} else {
						localStorage.setItem('position', '/login')
						next('/login')
					}
				},
				component: HomeView
			},
			// {
			// 	path: 'about',
			// 	name: 'about',
			// 	component: function () {
			// 		return import('../views/AboutView.vue')
			// 	}
			// },
			{
				path: '/setting/roles',
				name: 'setting.roles',
				beforeEnter: (to, from, next) => {
					useUser().getUser ? (isAllowed(to.path) ? next() : next('/home')) : next('/login')
				},
				component: RoleView
			},
			{
				path: '/setting/users',
				name: 'setting.users',
				beforeEnter: (to, from, next) => {
					useUser().getUser ? (isAllowed(to.path) ? next() : next('/home')) : next('/login')
				},
				component: UserView
			},
			{
				path: '/setting/menu',
				name: 'setting.menu',
				beforeEnter: (to, from, next) => {
					useUser().getUser ? (isAllowed(to.path) ? next() : next('/home')) : next('/login')
				},
				component: MenuView
			},
			{
				path: '/master/lokdev',
				name: 'master.lokdev',
				beforeEnter: (to, from, next) => {
					useUser().getUser ? (isAllowed(to.path) ? next() : next('/home')) : next('/login')
				},
				component: MasterLocDev
			},
			{
				path: '/master/group',
				name: 'master.group',
				beforeEnter: (to, from, next) => {
					useUser().getUser ? (isAllowed(to.path) ? next() : next('/home')) : next('/login')
				},
				component: MasterGroup
			},
			{
				path: '/master/kalibrasi',
				name: 'master.kalibrasi',
				beforeEnter: (to, from, next) => {
					useUser().getUser ? (isAllowed(to.path) ? next() : next('/home')) : next('/login')
				},
				component: MasterKalibrasi
			},
			{
				path: '/report/history',
				name: 'report.history',
				beforeEnter: (to, from, next) => {
					useUser().getUser ? (isAllowed(to.path) ? next() : next('/home')) : next('/login')
				},
				component: ReportHistory
			}, 
			{
				path: '/report/average',
				name: 'report.average',
				beforeEnter: (to, from, next) => {
					useUser().getUser ? (isAllowed(to.path) ? next() : next('/home')) : next('/login')
				},
				component: ReportAverage
			}, 
			{
				path: '/setting/delete_history',
				name: 'setting.delete_history',
				beforeEnter: (to, from, next) => {
					useUser().getUser ? (isAllowed(to.path) ? next() : next('/home')) : next('/login')
				},
				component: DeleteHistory
			}, 
			{
				path: '/setting/logger_delete_history',
				name: 'setting.logger_delete_history',
				beforeEnter: (to, from, next) => {
					useUser().getUser ? (isAllowed(to.path) ? next() : next('/home')) : next('/login')
				},
				component: LoggerDeleteHistory
			}, 
			{
				path: '/setting/indikator_kerusakan',
				name: 'setting.indikator_kerusakan',
				beforeEnter: (to, from, next) => {
					useUser().getUser ? (isAllowed(to.path) ? next() : next('/home')) : next('/login')
				},
				component: IndikatorKerusakan
			}, 
			{
				path: '/setting/batas_windspeed',
				name: 'setting.batas_windspeed',
				beforeEnter: (to, from, next) => {
					useUser().getUser ? (isAllowed(to.path) ? next() : next('/home')) : next('/login')
				},
				component: BatasWindspeed
			}, 
			{
				path: '/setting/unit_kerja',
				name: 'setting.unit_kerja',
				beforeEnter: (to, from, next) => {
					useUser().getUser ? (isAllowed(to.path) ? next() : next('/home')) : next('/login')
				},
				component: UnitKerja
			},
			{
				path: '/setting/reset_data',
				name: 'setting.reset_data',
				beforeEnter: (to, from, next) => {
					useUser().getUser ? (isAllowed(to.path) ? next() : next('/home')) : next('/login')
				},
				component: ResetData
			}, 
		]
	},
	// {
	//   path : '/login',
	//   name : 'login',
	//   component: LoginView,
	// },
	{
		path: '/register',
		name: 'register',
		beforeEnter: (to, from, next) => {
			// useUser().getToken ? next('/home') : next()
			if (useUser().getToken) {
				localStorage.setItem('position', '/')
				next('/')
			} else {
				localStorage.setItem('position', '/')
				next('/')
			}
		},
		component: () => import( /* webpackChunkName: "home" */ '../views/auth/register.vue')
	},
	{
		path: '/login',
		name: 'login',
		beforeEnter: (to, from, next) => {
			// useUser().getToken ? next('/home') : next()
			if (useUser().getToken) {
				localStorage.setItem('position', '/home')
				next('/home')
			} else {
				localStorage.setItem('position', to.path)
				next()
			}
		},
		component: () => import( /* webpackChunkName: "home" */ '../views/auth/login.vue')
	},
	{
		// Catch-all route for 404 Not Found
		path: '/:pathMatch(.*)*',
		name: 'not-found',
		beforeEnter: (to, from, next) => {
			localStorage.setItem('position', '/')
			next()
		},
		component: NotFound,
	},
]

const router = createRouter({
	// history: createWebHistory(process.env.BASE_URL),
	// routes
	history: createWebHistory(),
	routes // <-- routes,
})

export default router
