<script>
import NavItem from './NavItem.vue'
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

//import services api
import Api from '../../services/api'


// const users = JSON.parse(localStorage.getItem('user'));

export default {
	components: {
		NavItem, Loading,
	},
	data() {
		return {
			nama: JSON.parse(localStorage.user).name,
			// menus: [
			//   {key: 0, icon: "fas fa-home", link: "/dashboard", title: "Home"},
			//   {key: 1, icon: "fas fa-cogs", link: "#", title: "Setting", 
			//     children: [
			//       {key: 3, icon: "fas fa-user-cog", link: "#", title: "User", 
			//         children: [
			//           {key: 5, icon: "", link: "/user1", title: "User 1"},
			//           {key: 6, icon: "", link: "/user2", title: "User 2"},
			//         ]},
			//       {key: 4, icon: "", link: "/role", title: "Role"},
			//     ]
			//   },
			//   {key: 2, icon: "fas fa-info-circle", link: "/about", title: "About"},
			// ],
			menus: [
				{ key: 0, icon: "fas fa-home", route: "/dashboard", title: "Home" },
				{
					key: 1, icon: "fas fa-cogs", route: "#", title: "Setting",
					children: [
						{
							key: 3, icon: "fas fa-user-cog", route: "#", title: "User",
							children: [
								{ key: 5, icon: "", route: "/user1", title: "User 1" },
								{ key: 6, icon: "", route: "/user2", title: "User 2" },
							]
						},
						{ key: 4, icon: "", route: "/role", title: "Role" },
					]
				},
				{ key: 2, icon: "fas fa-info-circle", route: "/about", title: "About" },
			],
			menus: null,
			loading: false,
			fullPage: false
		}
	},
	methods: {
		async fecthMenuTree() {
			this.loading = true;
			const user_id = JSON.parse(localStorage.user).id
			// const {data} = await axios.get('http://localhost:8989/api/menutree', {
			try {
				const { data } = await Api.get('api/menutree', {
					params: { user_id },
					headers: {
						"Accept": "application/json",
						'Authorization': 'Bearer ' + localStorage.token
					},
				});
				// console.log(data);
				// console.log('token: ', localStorage.token);
				// this.loading = false;
				this.menus = data.data;
			} catch (error) {
				// console.log(error);
				// this.$swal({
				//   icon: "error",
				//   title: "Oops...",
				//   text: error.code + ': ' + error.message,
				// });
			} finally {
				this.loading = false;
			}
		},
		setPosition() {
			// console.log(window.location.pathname);
			// const path = window.location.pathname
			// localStorage.setItem('position', path)
		}
	},
	async mounted() {
		if (!this.menus) {
			await this.fecthMenuTree();
		}

		if (localStorage.getItem('position') != null) {
			const path = localStorage.getItem('position')
			const listItem = document.querySelectorAll(`li > a[href="${path}"]`);
			listItem.forEach((li) => {
				li.classList.add('router-link-active', 'router-link-exact-active', 'active')
				li.parentElement.parentElement.style.display = 'block'

				const grandparent = li.parentElement?.parentElement?.parentElement;
				// console.log(grandparent);

				if (grandparent && grandparent.classList.contains('nav-item')) {
					console.log('The grandparent <li> has the class nav-item.');
					grandparent.classList.add('menu-is-opening', 'menu-open')
					// only 2 level
				}
			})
		}
	}
}
</script>

<!-- v1 -->
<!-- src="/adminlte/dist/img/AdminLTELogo.png" -->
<!-- class="brand-image img-circle elevation-3" -->
<!-- class="img-circle elevation-2" -->
<!-- <div class="input-group" data-widget="sidebar-search"> -->

<!-- v2 -->
<!-- src="/windspeed.png" -->
<!-- class="brand-image elevation-0" -->
<!-- class="img-circle" -->
<!-- <div class="input-group d-none" data-widget="sidebar-search"> -->

<template>
	<aside class="main-sidebar sidebar-dark-primary elevation-4 vl-parent" style="height: 100vh !important;">
		<loading v-model:active="loading" :is-full-page="fullPage" />
		<a href="/" class="brand-link">
			<img src="/windspeed.png" :alt="$store.state.app.name" class="brand-image" style="opacity: 0.8" />
			<span class="brand-text font-weight-light">{{ $store.state.app.name }}</span>
		</a>
		<div class="sidebar pb-2">
			<div class="user-panel mt-3 pb-3 d-flex">
				<div class="image">
					<img src="/adminlte/dist/img/custom-user-icon.jpg" class="img-circle" alt="User Image" />
				</div>
				<div class="info">
					<span href="#" class="d-block username-el text-white">{{ nama }}</span>
				</div>
			</div>

			<div class="form-inline">
				<div class="input-group d-none" data-widget="sidebar-search">
					<input class="form-control form-control-sidebar" type="search" placeholder="Search"
						aria-label="Search" />
					<div class="input-group-append">
						<button class="btn btn-sidebar">
							<i class="fas fa-search fa-fw"></i>
						</button>
					</div>
				</div>
			</div>

			<nav class="mt-2">
				<ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
					data-accordion="false">
					<nav-item v-for="menu in menus" :key="menu.key" :route="menu" @click="setPosition()"></nav-item>
				</ul>
			</nav>
		</div>
	</aside>
</template>


<style scoped>
.brand-link,
.brand-link:hover,
.username-el,
.username-el:hover {
	text-decoration: none !important;
}
</style>