<script setup>
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import { useLoading } from 'vue-loading-overlay'
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';
// import 'datatables.net-responsive';
import * as Popper from "@popperjs/core"
import Api from '../../services/api';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Swal from 'sweetalert2';

import treeview from 'vue3-treeview';
import 'vue3-treeview/dist/style.css';

let tableUnitKerjaInstance;
const tableUnitKerja = ref();
const tableUnitKerjaData = ref([]);

let hirarkiModalInstance = null;
let unitModalInstance = null;

const loading = useLoading({});
const configObject = { keyboard: false, backdrop: 'static' }
const hirarkiModalLabel = ref('')
const unitModalLabel = ref('')
const toast = ref(null);
const toastEl = ref({});
const dataTableOptions = ref({
    order: [],
    scrollX: true
});
const selectedNodes = ref([]);
const config = ref({
    roots: [
        // "id1", "id2", "id3"
    ],
    keyboardNavigation: false,
    dragAndDrop: false,
    checkboxes: false,
    checkMode: 1,
    editable: false,
    disabled: false,
    padding: 25,
});

const selectedUnitFilter = ref(null)
const unitFilterOption = ref([])

const tempSelectedTreeName = ref(null)

const unitKerjaModalLabel = ref('')

const modalUnitKerjaData = ref({ key_id: null, kd_unit_kerja: null, nm_unit_kerja: null, kd_wilayah: null, kd_regional: null });
let modalUnitKerjaInstance = null;

const nodes = ref({});
const hirarkiDisabled = ref(true)
const unitDisabled = ref(true)
const disabledRoot = ref(true)
const inputFieldPrev = ref('')
const selectedTree = ref({
    key_id: null,
    name: null,
    type: null,
    parent_id: null,
    key_id_unit_kerja: null
})
const selectedUnitValue = ref(null);
const selectUnitOptions = ref([]);

DataTable.use(DataTablesCore);

const columns = [
    {
        title: "No.",
        data: 'key_id',
        width: "20px",
        render: function (data, type, row, meta) {
            return meta.row + meta.settings._iDisplayStart + 1;
        }
    },
    { title: "Nama Unit Kerja", data: 'nm_unit_kerja' },
    {
        title: 'Aksi',
        orderable: false,
        data: null,
        width: '80px',
        render: function (data, type, row) {
            const disabled = false ? "disabled" : ""
            const div = `<div class="d-flex">
                                <button class="btn btn-sm btn-outline-primary btn-edit-unit-kerja mr-2" ${disabled} title="Ubah Unit Kerja" data-id=${row.key_id} data-kd-unit-kerja="${row.kd_unit_kerja}" data-nm-unit-kerja="${row.nm_unit_kerja}" data-kd-wilayah="${row.kd_wilayah}" data-kd-regional="${row.kd_regional}"><i class="operation-icon fas fa-pen"></i></button>
                                <button class="btn btn-sm btn-outline-danger btn-remove-unit-kerja" ${disabled} title="Hapus Unit Kerja" data-id=${row.key_id} data-kd-unit-kerja="${row.kd_unit_kerja}" data-nm-unit-kerja="${row.nm_unit_kerja}" data-kd-wilayah="${row.kd_wilayah}" data-kd-regional="${row.kd_regional}"><i class="operation-icon fas fa-trash-alt"></i></button>
                            </div>`;
            return div;
        }
    },
];

const fetchData = async () => {
    const loader = loading.show({});
    try {
        const { data } = await Api.get('api/unit_kerja/preload', {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        tableUnitKerjaData.value = data.unit_kerja;
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        loader.hide()
        setTimeout(() => {
            tableUnitKerjaInstance.columns.adjust().draw()
        }, 300);
    }
};

const showHirarkiModal = (operation) => {
    hirarkiModalLabel.value = operation === 'edit' ? 'Ubah hirarki' : 'Tambah hirarki';

    if (!hirarkiModalInstance) {
        const modalElement = document.getElementById('hirarki-modal');
        hirarkiModalInstance = new bootstrap.Modal(modalElement, configObject);
    }
    hirarkiModalInstance.show();
};

const showUnitModal = (operation) => {
    unitModalLabel.value = operation === 'edit' ? 'Ubah unit kerja' : 'Pasang unit kerja';

    if (!unitModalInstance) {
        const modalElement = document.getElementById('unit-modal');
        unitModalInstance = new bootstrap.Modal(modalElement, configObject);
    }
    unitModalInstance.show();
};

const handleHirarkiSubmit = async () => {

    const transformedArray = Object.values(nodes.value).map(item => ({
        text: item.text,
        type: item.type
    }));
    const foundItem = transformedArray.find(item => item.text.toLowerCase().trim() == selectedTree.value.name.toLowerCase().trim() && item.type == 0);
    // console.log(foundItem);

    if (selectedTree.value.key_id == null) {
        console.log('create');
        
        if (foundItem != null) {
            Swal.fire({
                icon: "warning",
                html: `Hirarki <b>${selectedTree.value.name}</b> tidak tersedia/sudah ada`,
            });
            return
        }
    } else {
        console.log('edit');

        if (tempSelectedTreeName.value.toLowerCase().trim() != selectedTree.value.name.toLowerCase().trim()) {
            if (foundItem != null) {
                Swal.fire({
                    icon: "warning",
                    html: `Hirarki <b>${selectedTree.value.name}</b> tidak tersedia/sudah ada`,
                });
                return
            }
        }
    }

    // console.log('passed');

    const loader = loading.show({});
    try {
        const { data } = await Api.post(`api/unit_kerja/tree`, selectedTree.value, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        // console.log(data.message);
        if (data.success) {
            hirarkiModalInstance.hide();
            showToast(data.message, 'success');
            await fetchTree()
            await fetchData()
        } else {
            showToast(data.message, 'danger');
        }

    } catch (error) {
        console.error('Error submitting form:', error);
        showToast(error, 'danger');
    } finally {
        loader.hide()
    }
};

const handleUnitSubmit = async () => {
    // console.log(selectedUnitValue.value);
    // return
    if (selectedUnitValue.value == null) {
        Swal.fire({
            icon: "warning",
            text: "Harap isi minimal 1 unit kerja",
        });

        return
    }
    // console.log({
    //     key_id: selectedTree.value.key_id,
    //     parent_id: selectedTree.value.parent_id, 
    //     type: selectedTree.value.type, 
    //     key_id_unit_kerja: selectedUnitValue.value.code, 
    //     name_unit_kerja: selectedUnitValue.value.label
    // });

    let checkResult

    if (selectedTree.value.type == 1) {
        // edit
        if (selectedTree.value.key_id_unit_kerja == selectedUnitValue.value.code) {
            // bypass
            checkResult = null;
        } else {
            checkResult = findNodeByUnitKerjaAndType(nodes.value, selectedUnitValue.value.code, 1);
        }
    } else {
        checkResult = findNodeByUnitKerjaAndType(nodes.value, selectedUnitValue.value.code, 1);
    }

    // return 


    // console.log(checkResult);
    if (checkResult != null) {
        // console.log(checkResult.key);
        const x = getAllParents(nodes.value, `key_id${checkResult.key}`)
        Swal.fire({
            icon: "warning",
            html: `<span>Unit kerja sudah terpasang, lakukan hapus unit kerja dahulu di </span><br />${x.join(' ► ')}`,
        });

        return
    }
    const loader = loading.show({});
    try {
        const { data } = await Api.post(`api/unit_kerja/unit`,
            {
                key_id: selectedTree.value.key_id,
                parent_id: selectedTree.value.parent_id,
                type: selectedTree.value.type,
                key_id_unit_kerja: selectedUnitValue.value.code,
                name_unit_kerja: selectedUnitValue.value.label
            }, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        if (data.success) {
            unitModalInstance.hide();
            showToast(data.message, 'success');
            await fetchTree();
            await fetchData()
        } else {
            showToast(data.message, 'danger');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        showToast(data.message, 'danger');
    } finally {
        loader.hide()
    }
};

const findNodeByUnitKerjaAndType = (data, unitKerja, type) => {
    for (const key in data) {
        if (data[key].key_id_unit_kerja == unitKerja && data[key].type == type) {
            return data[key];
        }
    }
    return null; // if no match is found
}

const showToast = (message, bg) => {
    toastEl.value.message = message;
    toastEl.value.bg = `text-bg-${bg}`;
    toast.value.show();

    setTimeout(() => {
        toast.value.hide();
    }, 3000);
};

const showUnitKerjaModal = (id, operation) => {
    unitKerjaModalLabel.value = operation === 'edit' ? 'Ubah Unit Kerja' : 'Tambah Unit Kerja';

    if (!modalUnitKerjaInstance) {
        const modalElement = document.getElementById('unit-kerja-modal');
        modalUnitKerjaInstance = new bootstrap.Modal(modalElement, configObject);
    }
    modalUnitKerjaInstance.show();
};

const createUnitKerja = async () => {
    const loader = loading.show({});
    try {
        modalUnitKerjaData.value.key_id = null
        modalUnitKerjaData.value.kd_unit_kerja = null
        modalUnitKerjaData.value.nm_unit_kerja = null
        showUnitKerjaModal(null, 'create')
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide()
    }
}

const editUnitKerja = async (id, kd_unit_kerja, nm_unit_kerja) => {
    const loader = loading.show({});
    try {
        modalUnitKerjaData.value.key_id = id
        modalUnitKerjaData.value.kd_unit_kerja = kd_unit_kerja
        modalUnitKerjaData.value.nm_unit_kerja = nm_unit_kerja
        showUnitKerjaModal(id, 'edit')
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide()
    }
}

const nodeBlur = (node) => {
    // console.log('blur');
    // setTimeout(() => {
    //     hirarkiDisabled.value = true
    //     unitDisabled.value = true    
    // }, 300);   
}

const refactorNode = async () => {
    await sleepCustom(1)
    const transformedArray = Object.values(nodes.value).map(item => ({
        text: item.text,
        type: item.type
    }));
    const treeNodes = document.querySelectorAll('li.tree-node');
    Array.from(treeNodes).map((item, i) => {
        const spanElement = item.querySelector('div.node-wrapper > div.input-wrapper > span');
        const text = spanElement.textContent
        const foundItem = transformedArray.find(item => item.text == text);
        while (spanElement.firstChild) {
            spanElement.removeChild(spanElement.firstChild);
        }
        if (foundItem.type == 0) {
            const boldElement = document.createElement('b');
            boldElement.textContent = text;
            spanElement.appendChild(boldElement)
        } else {
            const italicElement = document.createElement('em');
            italicElement.textContent = text;
            spanElement.appendChild(italicElement)
        }
    })
}

const nodeOpened = node => {
    console.log('opened');
    refactorNode()
}

const nodeClosed = node => {
    console.log('closed');
    refactorNode()
}

const nodeFocus = (node) => {
    console.log('node-focus: ', node);
    if (node.type == 0) {
        if (node.key == 0) {
            disabledRoot.value = true
        } else {
            disabledRoot.value = false

        }
        hirarkiDisabled.value = false
        unitDisabled.value = true

        const x = getAllParents(nodes.value, `key_id${node.key}`)
        inputFieldPrev.value = x.join(' ► ')

        selectedUnitFilter.value = null
    } else {
        hirarkiDisabled.value = true
        unitDisabled.value = false

        // inputFieldPrev.value = node.text;
        const x = getAllParents(nodes.value, `key_id${node.key}`)
        inputFieldPrev.value = x.join(' ► ')

        const y = unitFilterOption.value.filter(item => item.code == node.key_id_unit_kerja)
        // console.log(y[0]);
        
        selectedUnitFilter.value = y[0]
    }
    const str = node.key;
    let parent_id
    if ((node.type == 0 && node.key == 0)) {
        parent_id = parseInt(0, 10)
    } else {
        // parent_id = parseInt(str.replace(/\D/g, ''), 10)
        parent_id = parseInt(str, 10)
    }
    // selectedNode = node
    selectedTree.value = {
        key_id: node.key,
        name: node.text,
        type: node.type,
        parent_id: parent_id,
        key_id_unit_kerja: node.key_id_unit_kerja
    }
}

const clickEventHandler = async (event) => {
    const targetCloseHirarkiModal = event.target.closest('.hirarki-close-el');
    if (targetCloseHirarkiModal) {
        hirarkiDisabled.value = true
        unitDisabled.value = true
    }

    const targetCloseUnitModal = event.target.closest('.unit-close-el');
    if (targetCloseUnitModal) {
        hirarkiDisabled.value = true
        unitDisabled.value = true
        selectedUnitFilter.value = null
    }

    const targetEditUnitKerja = event.target.closest('.btn-edit-unit-kerja');
    if (targetEditUnitKerja) {
        const id = targetEditUnitKerja.getAttribute('data-id');
        const kd_unit_kerja = targetEditUnitKerja.getAttribute('data-kd-unit-kerja');
        const nm_unit_kerja = targetEditUnitKerja.getAttribute('data-nm-unit-kerja');
        editUnitKerja(id, kd_unit_kerja, nm_unit_kerja);
    }

    const targetDeleteUnitKerja = event.target.closest('.btn-remove-unit-kerja');
    if (targetDeleteUnitKerja) {
        const id = targetDeleteUnitKerja.getAttribute('data-id');
        const { isConfirmed, value } = await Swal.fire({
            text: 'Anda yakin akan menghapus unit kerja ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            inputPlaceholder: 'Alasan reject',
            showLoaderOnConfirm: true,
            confirmButtonClass: 'btn btn-primary w-xs me-2',
            cancelButtonClass: 'btn btn-danger w-xs',
            buttonsStyling: false,
        })

        if (isConfirmed) {
            const loader = loading.show({});
            try {
                const { data } = await Api.delete(`api/unit_kerja/delete`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + localStorage.token
                    }, data: {
                        id
                    }
                });
                if (data.success) {
                    showToast(data.message, 'success');
                    await fetchTree()
                    await fetchData()
                } else {
                    showToast(data.message, 'danger');
                }
            } catch (error) {
                console.error(error);
                showToast(error, 'danger');
            } finally {
                loader.hide()
            }
        }
    }
}

const submitUnitKerjaHandler = async () => {
    const loader = loading.show({});
    try {
        const { data } = await Api.post(`api/unit_kerja/save`, modalUnitKerjaData.value, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        if (data.success) {
            modalUnitKerjaInstance.hide();
            showToast(data.message, 'success');
            await fetchTree()
            await fetchData()
        } else {
            showToast(data.message, 'danger');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        showToast(error, 'danger');
    } finally {
        loader.hide()
    }
};

const fetchTree = async () => {
    hirarkiDisabled.value = true
    unitDisabled.value = true
    inputFieldPrev.value = ''
    const loader = loading.show({});
    // await menuInit();
    try {
        const { data } = await Api.get('api/unit_kerja/tree', {
            headers: {
                "Accept": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        nodes.value = data.data.nodes
        config.value.roots = data.data.roots
        selectedNodes.value = []

        const res = await Api.get('api/unit_kerja/list', {
            headers: {
                "Accept": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        // console.log(res.data);
        selectUnitOptions.value = res.data.unitkerja

        //
        const transformedArray = Object.values(nodes.value).map(item => ({
            text: item.text,
            type: item.type
        }));
        const unitFilOpt = []
        Object.values(nodes.value).map(item => {
            if (item.type == 1) {
                unitFilOpt.push({
                    code: item.key_id_unit_kerja, 
                    label: item.text
                })
            }
        });
        // console.log(unitFilOpt);
        unitFilterOption.value = unitFilOpt
        
        const treeNodes = document.querySelectorAll('li.tree-node');
        Array.from(treeNodes).map((item, i) => {
            const spanElement = item.querySelector('div.node-wrapper > div.input-wrapper > span');
            const text = spanElement.textContent
            const foundItem = transformedArray.find(item => item.text == text);
            while (spanElement.firstChild) {
                spanElement.removeChild(spanElement.firstChild);
            }
            if (foundItem.type == 0) {
                const boldElement = document.createElement('b');
                boldElement.textContent = text;
                spanElement.appendChild(boldElement)
            } else {
                const italicElement = document.createElement('em');
                italicElement.textContent = text;
                spanElement.appendChild(italicElement)
            }
            
        })
        selectedUnitFilter.value = null
        //
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide();
    }
}

const addHirarki = () => {
    selectedTree.value.key_id = null
    selectedTree.value.name = null
    showHirarkiModal('add')
}

const editHirarki = () => {
    tempSelectedTreeName.value = selectedTree.value.name
    
    showHirarkiModal('edit')
}

const deleteHirarki = async () => {
    const { isConfirmed, value } = await Swal.fire({
        text: 'Anda yakin akan menghapus hirarki ini?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        inputPlaceholder: 'Alasan reject',
        showLoaderOnConfirm: true,
        confirmButtonClass: 'btn btn-primary w-xs me-2',
        cancelButtonClass: 'btn btn-danger w-xs',
        buttonsStyling: false,
    })

    if (isConfirmed) {
        const loader = loading.show({});
        try {
            const { data } = await Api.delete(`api/unit_kerja/tree`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + localStorage.token
                }, data: {
                    key_id: selectedTree.value.key_id
                }
            });
            if (data.success) {
                showToast(data.message, 'success');
                await fetchTree()
                await fetchData()
            } else {
                showToast(data.message, 'danger');
            }
        } catch (error) {
            console.error(error);
            showToast(error, 'danger');
        } finally {
            loader.hide()
        }
    }
    hirarkiDisabled.value = true
    unitDisabled.value = true
}

const addUnitNew = () => {
    createUnitKerja();
}

const addUnit = () => {
    selectedUnitValue.value = null
    showUnitModal('add')
}

const editUnit = () => {
    selectedUnitValue.value = null
    const result = selectUnitOptions.value.find(item => item.code == selectedTree.value.key_id_unit_kerja);

    selectedUnitValue.value = result
    showUnitModal('edit')
}

const deleteUnit = async () => {
    const { isConfirmed, value } = await Swal.fire({
        text: 'Anda yakin akan menghapus unit kerja ini?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        inputPlaceholder: 'Alasan reject',
        showLoaderOnConfirm: true,
        confirmButtonClass: 'btn btn-primary w-xs me-2',
        cancelButtonClass: 'btn btn-danger w-xs',
        buttonsStyling: false,
    })

    if (isConfirmed) {
        const loader = loading.show({});
        try {
            const { data } = await Api.delete(`api/unit_kerja/unit`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + localStorage.token
                }, data: {
                    key_id: selectedTree.value.key_id
                }
            });
            if (data.success) {
                showToast(data.message, 'success');
                await fetchTree()
                await fetchData()
            } else {
                showToast(data.message, 'danger');
            }
        } catch (error) {
            console.error(error);
            showToast(error, 'danger');
        } finally {
            loader.hide()
        }
    }
    hirarkiDisabled.value = true
    unitDisabled.value = true
}

const getAllParents = (data, nodeKey) => {
    const path = [];
    let currentKey = nodeKey;

    while (currentKey) {
        const node = data[currentKey];
        if (!node) break; // If the node doesn't exist, stop

        // Add the current node's text to the path
        path.push(node.text);

        // If there's a parentName, find the key of the parent node
        if (node.parentName && node.parentName !== "") {
            const parentNode = Object.values(data).find(n => n.text === node.parentName);
            if (parentNode) {
                currentKey = Object.keys(data).find(key => data[key].text === node.parentName);
            } else {
                break; // If no parent node is found, stop
            }
        } else {
            break; // If there is no parentName, stop
        }
    }

    // return path.reverse().join(' ► '); // Reverse to get the path from root to the given node and join with '>'
    return path.reverse()
}

const getAllParentsCustom = (data, nodeKey) => {
    const path = [];
    let currentKey = nodeKey;

    while (currentKey) {
        const node = data[currentKey];
        if (!node) break; // If the node doesn't exist, stop

        // Add the current node's text to the path
        path.push(node.id);

        // If there's a parentName, find the key of the parent node
        if (node.parentName && node.parentName !== "") {
            const parentNode = Object.values(data).find(n => n.text === node.parentName);
            if (parentNode) {
                currentKey = Object.keys(data).find(key => data[key].text === node.parentName);
            } else {
                break; // If no parent node is found, stop
            }
        } else {
            break; // If there is no parentName, stop
        }
    }

    // return path.reverse().join(' ► '); // Reverse to get the path from root to the given node and join with '>'
    return path.reverse()
}

const handleTabChange = (event) => {
    const activeTab = event.target.getAttribute('aria-controls');
    console.log(`Active tab changed to: ${activeTab}`);
    setTimeout(() => {
        tableUnitKerjaInstance.columns.adjust().draw()
    }, 0);
};

const sleepCustom = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const handleChangeUnitFilter = async value => {
    // const loader = loading.show({});

    if (value) {
        // phase 1, close all hierarcy
        Object.values(nodes.value).map(item => {
            if (item.type == 0) {
                item.state.opened = true    
            } else {
                item.state.opened = false
            }
        });

        await sleepCustom(1)

        // phase 2, get parents key id
        const checkResult = findNodeByUnitKerjaAndType(nodes.value, value.code, 1);
        const x = getAllParentsCustom(nodes.value, `key_id${checkResult.key}`)

        // phase 3 open hierarcy listed on array
        Object.values(nodes.value).map(item => {
            if (item.type == 0) {
                if (x.includes(item.id)) {
                    item.state.opened = true
                } else {
                    item.state.opened = false    
                }
            } else {
                item.state.opened = false
            }            
        });

        // phase 4, click on tree element
        const transformedArray = Object.values(nodes.value).map(item => ({
            text: item.text,
            type: item.type
        }));
        const treeNodes = document.querySelectorAll('li.tree-node');
        Array.from(treeNodes).map((item, i) => {
            const spanElement = item.querySelector('div.node-wrapper > div.input-wrapper > span');
            const text = spanElement.textContent
            const foundItem = transformedArray.find(item => item.text == text);
            if (foundItem.type == 1) {
                if (spanElement.textContent == value.label) {
                    console.log('clicked');
                    spanElement.parentNode.parentNode.click()
                }
            }
        })
    } else {
        Object.values(nodes.value).map(item => {
            if (item.type == 0) {
                item.state.opened = true    
            } else {
                item.state.opened = false
            }
        });
        hirarkiDisabled.value = true
        unitDisabled.value = true
        // document.activeElement.blur();
        const transformedArray = Object.values(nodes.value).map(item => ({
            text: item.text,
            type: item.type
        }));
        const treeNodes = document.querySelectorAll('li.tree-node');
        Array.from(treeNodes).map((item, i) => {
            const spanElement = item.querySelector('div.node-wrapper > div.input-wrapper > span');
            const text = spanElement.textContent
            const foundItem = transformedArray.find(item => item.text == text);
            if (foundItem.type == 0) {
                if (spanElement.textContent == "PELINDO") {
                    console.log('clicked');
                    spanElement.parentNode.parentNode.click()
                }
            }
        })
        await sleepCustom(1)

        document.activeElement.blur();
        hirarkiDisabled.value = true
        unitDisabled.value = true
    }
    // loader.hide()
};

onMounted(async () => {
    tableUnitKerjaInstance = tableUnitKerja.value.dt;
    await fetchTree()
    await fetchData()

    document.removeEventListener('click', clickEventHandler);
    document.addEventListener('click', clickEventHandler);

    toast.value = new bootstrap.Toast(document.getElementById('toast'), { delay: 3000 });

    const tabElements = document.querySelectorAll('#nav-tab-tree button[data-bs-toggle="tab"]');
    tabElements.forEach(tab => {
        tab.addEventListener('shown.bs.tab', handleTabChange);
    });
});

onBeforeUnmount(() => {
    document.removeEventListener('click', clickEventHandler);
});
</script>

<template>
    <div class="toast-container position-fixed top-0 end-0 p-3">
        <div id="toast" :class="'toast align-items-center border-0 ' + toastEl.bg" role="alert" aria-live="assertive"
            aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    {{ toastEl.message }}
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                    aria-label="Close"></button>
            </div>
        </div>
    </div>

    <div id="hirarki-modal" class="modal fade" tabindex="-1" aria-labelledby="hirarki-modal-label" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="hirarki-modal-label">{{ hirarkiModalLabel }}</h5>
                    <button type="button" class="btn-close hirarki-close-el" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form method="post" id="hirarki-form" @submit.prevent="handleHirarkiSubmit">
                        <input type="hidden" name="key_id" class="form-control" v-model="selectedTree.key_id"
                            required />
                        <input type="hidden" name="type" class="form-control" v-model="selectedTree.type" required />
                        <input type="hidden" name="type" class="form-control" v-model="selectedTree.parent_id"
                            required />
                        <div class="mb-3">
                            <label for="hirarki_name" class="form-label">Nama hirarki</label>
                            <input type="text" name="hirarki_name" class="form-control" v-model="selectedTree.name"
                                required placeholder="Masukkan nama hirarki">
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="submit" form="hirarki-form" class="btn btn-success"
                        data-bs-dismiss="modal">Simpan</button>
                    <button type="button" class="btn btn-secondary hirarki-close-el" data-dismiss="modal">Batal</button>
                </div>
            </div>
        </div>
    </div>

    <div id="unit-modal" class="modal fade" tabindex="-1" aria-labelledby="unit-modal-label" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="unit-modal-label">{{ unitModalLabel }}</h5>
                    <button type="button" class="btn-close unit-close-el" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form method="post" id="unit-form" @submit.prevent="handleUnitSubmit">
                        <input type="hidden" name="key_id" class="form-control" v-model="selectedTree.key_id"
                            required />
                        <input type="hidden" name="type" class="form-control" v-model="selectedTree.type" required />
                        <input type="hidden" name="type" class="form-control" v-model="selectedTree.parent_id"
                            required />
                        <div class="mb-3">
                            <v-select v-model="selectedUnitValue" :options="selectUnitOptions" :clearable="false"
                                required placeholder="Harap pilih unit kerja"></v-select>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="submit" form="unit-form" class="btn btn-success"
                        data-bs-dismiss="modal">Simpan</button>
                    <button type="button" class="btn btn-secondary unit-close-el" data-dismiss="modal">Batal</button>
                </div>
            </div>
        </div>
    </div>

    <div id="unit-kerja-modal" class="modal fade" aria-labelledby="unit-kerja-modal-label" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ unitKerjaModalLabel }}</h5>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form method="post" id="unit-kerja-form" @submit.prevent="submitUnitKerjaHandler">
                        <div class="row">
                            <input type="hidden" class="form-control" v-model="modalUnitKerjaData.key_id" required />
                            <div class="col-12">
                                <div class="">
                                    <label class="form-label">Kode Unit Kerja</label>
                                    <input type="text" class="form-control" placeholder="Masukkan Kode Unit Kerja" v-model="modalUnitKerjaData.kd_unit_kerja" required>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="mt-3 mt-md-3">
                                    <label class="form-label">Nama Unit Kerja</label>
                                    <input type="text" class="form-control" placeholder="Masukkan Nama Unit Kerja" v-model="modalUnitKerjaData.nm_unit_kerja" required>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="submit" form="unit-kerja-form" class="btn btn-success"
                        data-bs-dismiss="modal">Simpan</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="card">
            <div class="card-header">
                <h5 class="mb-0">Unit Kerja</h5>
            </div>
            <div class="card-body">
                <nav>
                    <div class="nav nav-tabs" id="nav-tab-tree" role="tablist">
                        <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#nav-tree" type="button"
                            role="tab" aria-controls="nav-tree" aria-selected="true">Hirarki</button>
                        <button class="nav-link" data-bs-toggle="tab" data-bs-target="#nav-data" type="button"
                            role="tab" aria-controls="nav-data" aria-selected="false">Unit Kerja</button>
                    </div>
                </nav>
                <div class="tab-content">
                    <div class="tab-pane fade show active" id="nav-tree" role="tabpanel" aria-labelledby="nav-tree-tab"
                        tabindex="0">
                        <div class="row mt-3">
                            <div :class="['col-12', 'col-md-6', 'order-2', 'order-md-1', hirarkiDisabled && unitDisabled ? '' : 'border-2 border border-gray border-top-0 border-start-0 border-bottom-0']">
                                <div id="target-tree">
                                    <treeview :nodes="nodes" :config="config" selectable @nodeFocus="nodeFocus" @nodeBlur="nodeBlur" @nodeOpened="nodeOpened" @nodeClosed="nodeClosed">
                                    </treeview>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 order-1 order-md-2 mb-3 mb-md-0">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label">Filter unit kerja</label>
                                        <v-select v-model="selectedUnitFilter" :options="unitFilterOption" :clearable="true"
                                            required placeholder="Filter unit kerja" @update:modelValue="handleChangeUnitFilter"></v-select>
                                    </div>
                                </div>
                                <div :class="['row', 'mt-2', hirarkiDisabled ? 'd-none' : '']">
                                    <div class="col-12">
                                        <label class="form-label">Posisi hirarki</label>
                                        <input type="text" class="form-control" :disabled="true"
                                            v-model="inputFieldPrev" required>
                                    </div>
                                    <div class="col-12 mt-2">
                                        <div class="d-flex table-responsive">
                                            <button class="btn btn-success" :disabled="hirarkiDisabled"
                                                @click="addHirarki">Tambah
                                                hirarki</button>
                                            <button class="btn btn-primary mx-2"
                                                :disabled="hirarkiDisabled || disabledRoot" @click="editHirarki">Ubah
                                                hirarki</button>
                                            <button class="btn btn-danger" :disabled="hirarkiDisabled || disabledRoot"
                                                @click="deleteHirarki">Hapus
                                                hirarki</button>
                                            <button class="btn btn-warning ms-2" :disabled="hirarkiDisabled"
                                                @click="addUnit">Pasang unit kerja</button>
                                        </div>
                                    </div>
                                </div>
                                <div :class="['row', 'mt-2', unitDisabled ? 'd-none' : '']">
                                    <div class="col-12">
                                        <label class="form-label">Posisi hirarki</label>
                                        <input type="text" class="form-control" :disabled="true"
                                            v-model="inputFieldPrev" required>
                                    </div>
                                    <div class="col-12 mt-2">
                                        <div class="d-flex">
                                            <button class="btn btn-primary mr-2" :disabled="unitDisabled"
                                                @click="editUnit">Ubah
                                                unit
                                                kerja</button>
                                            <button class="btn btn-danger" :disabled="unitDisabled"
                                                @click="deleteUnit">Hapus
                                                unit kerja</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="nav-data" role="tabpanel" aria-labelledby="nav-data-tab"
                        tabindex="0">
                        <div class="mt-3">
                            <button class="btn btn-primary mb-2" @click="addUnitNew">Tambah Unit Kerja</button>
                            <DataTable :columns="columns" :data="tableUnitKerjaData" :options="dataTableOptions"
                                ref="tableUnitKerja" class="table table-bordered" width="100%">
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
@import 'bootstrap';
@import 'datatables.net-bs5';
</style>

<style scoped>
#target-tree {
    max-height: 50vh !important;
    overflow-y: scroll;
}
</style>