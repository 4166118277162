<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useLoading } from 'vue-loading-overlay'
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';
// import 'datatables.net-responsive';
import * as Popper from "@popperjs/core"
import Api from '../../services/api';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Swal from 'sweetalert2';

import 'vue-select/dist/vue-select.css';

const tableData = ref([]);
const modalData = ref({ id: '', new_pw: '' });
const modalData2 = ref({ id: '', username: '', name: '', nip: '', email: '', status: false, roles: null, unit_kerja: null, group: [] });
const modalData3 = ref({ id: '', username: '', name: '', nip: '', email: '', password: '', status: false, roles: null, unit_kerja: null, group: [] });
let modalInstance = null;
let modalInstance2 = null;
let modalInstance3 = null;
const loading = useLoading({});
const configObject = { keyboard: false, backdrop: 'static' }
const toast = ref(null);
const toastEl = ref({});
const dataTableOptions = ref({
    order: [],
    scrollX: true,
    initComplete: function () {
        // You can add your custom code here
    },
    drawCallback: function (settings) {
        // console.log('DataTable draw event.');
    },
});
const disabledRoleId = [1, 11, 0]
const selectedValue = ref(null);
const selectOptions = ref([]);
const selectedValue2 = ref(null);
const selectOptions2 = ref([]);
const selectedValue3 = ref(null);
const selectOptions3 = ref([]);
const selectedValue4 = ref(null);
const selectOptions4 = ref([]);
const selectedValueGroup = ref(null)
const selectOptionsGroup = ref([]);
const selectedValueUnitKerja = ref(null)
const selectOptionsUnitKerja = ref([]);
const validateitem4 = ref(true);

let table1Instance;
const table1 = ref();

DataTable.use(DataTablesCore);

const columns = [
    // { title: "ID", data: 'id', width: "20px" },
    {
        title: "No.",
        data: 'id',
        width: "20px",
        render: function (data, type, row, meta) {
            return meta.row + meta.settings._iDisplayStart + 1;
        }
    },
    { title: "Username", data: 'username' },
    { title: "Nama", data: 'name' },
    { title: "NIP", data: 'nip' },
    { title: "Email", data: 'email' },
    {
        title: 'Status',
        orderable: false,
        data: null,
        render: function (data, type, row) {
            const text = row.is_active ? 'Aktif' : 'Tidak aktif';
            const color = row.is_active ? 'bg-success' : 'bg-secondary';

            const div = "<span class='badge " + color + "'>" + text + "</span>"
            return div;
        }
    },
    { title: "Role", data: 'role_names' },
    { title: "Unit Kerja", data: 'nm_unit_kerja' },
    { title: "Group", data: 'group_names' },
    {
        title: 'Aksi',
        orderable: false,
        data: null,
        render: function (data, type, row) {
            const disabled = row.id == 1 ? "disabled" : ""
            const div = `<div class="d-flex">
                                <button class="btn btn-sm btn-outline-success btn-menu-user" ${disabled} title="Ubah User" data-id=${row.id}><i class="operation-icon fas fa-pen"></i></button>
                                <button class="btn btn-sm btn-outline-primary btn-edit-user-pw mx-2" ${disabled} title="Ubah Password User" data-id=${row.id} data-name=${row.name}><i class="operation-icon fas fa-key"></i></button>
                                <button class="btn btn-sm btn-outline-danger btn-remove-user" ${disabled} title="Hapus User" data-id=${row.id}><i class="operation-icon fas fa-trash-alt"></i></button>
                            </div>`;
            return div;
        }
    },
];

const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

const fetchData = async () => {
    const loader = loading.show({});
    try {
        const { data } = await Api.get('api/users', {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        tableData.value = data.data;
        // console.log(this);
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        loader.hide()
        setTimeout(() => {
            table1Instance.columns.adjust().draw()
        }, 0);
    }
};

const showModal = (id) => {
    modalData.value.id = id;
    modalData.value.new_pw = ''

    if (!modalInstance) {
        const modalElement = document.getElementById('new-pw-modal');
        modalInstance = new bootstrap.Modal(modalElement, configObject);
    }
    modalInstance.show();
};

const showModal2 = async (id) => {
    const loader = loading.show({});
    try {
        const { data } = await Api.get(`api/users/get_info`, {
            params: { id },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        selectOptions.value = [
            { code: true, label: 'Aktif' },
            { code: false, label: 'Tidak Aktif' }
        ]
        // console.log(data.data[0].is_active);
        selectedValue.value = data.data[0].is_active ? { code: true, label: 'Aktif' } : { code: false, label: 'Tidak Aktif' }

        selectOptions2.value = data.roles
        selectedValue2.value = data.user_roles
        selectOptionsGroup.value = data.group
        selectedValueGroup.value = data.user_group
        selectOptionsUnitKerja.value = data.unit_kerja
        selectedValueUnitKerja.value = data.user_unit_kerja

        modalData2.value.id = data.data[0].id;
        modalData2.value.username = data.data[0].username
        modalData2.value.name = data.data[0].name
        modalData2.value.nip = data.data[0].nip
        modalData2.value.email = data.data[0].email
        modalData2.value.status = data.data[0].is_active
        // modalData2.value.roles = data.data[0].role_ids ? data.data[0].role_ids.split(", ") : []
        modalData2.value.roles = data.data[0].role_ids
        modalData2.value.unit_kerja = data.data[0].key_id_unit_kerja
        modalData2.value.group = data.data[0].group_ids ? data.data[0].group_ids.split(", ") : []

        console.log(disabledRoleId.includes(parseInt(data.data[0].role_ids)));
        if (disabledRoleId.includes(parseInt(data.data[0].role_ids))) {
            validateitem4.value = false;
        } else {
            validateitem4.value = true;
        }

        if (!modalInstance2) {
            const modalElement = document.getElementById('menu-user-modal');
            modalInstance2 = new bootstrap.Modal(modalElement, configObject);
        }
        modalInstance2.show();
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide()
    }
};

const handleSubmitNewPw = async () => {
    const loader = loading.show({});
    try {
        const { data } = await Api.post(`api/users/new_pw`, modalData.value, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        if (data.success) {
            modalInstance.hide();
            showToast(data.message, 'success');
            fetchData();
        } else {
            showToast(data.message, 'danger');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        showToast(error, 'danger');
    } finally {
        loader.hide()
    }
};

const handleSubmitMenuUser = async () => {
    // if (modalData2.value.roles.length == 0) {
    //     Swal.fire({
    //         icon: "warning",
    //         text: "Harap isi minimal 1 role",
    //     });

    //     return
    // }
    // if (modalData2.value.group.length == 0) {

    // }
    // return console.log(modalData2.value);
    if (isEmptyObject(modalData2.value.roles) || modalData2.value.roles == null) {
        Swal.fire({
            icon: "warning",
            text: "Harap isi minimal 1 role",
        });

        return
    }

    // if (modalData2.value.roles.includes(1) || modalData2.value.roles.includes('1')) {
    console.log(disabledRoleId);
    console.log(modalData2.value.roles);
    
    
    if (disabledRoleId.includes(parseInt(modalData2.value.roles))) {
        console.log('admin');
    } else {
        console.log('non-admin');
        if (isEmptyObject(modalData2.value.unit_kerja) || modalData2.value.unit_kerja == null) {
            Swal.fire({
                icon: "warning",
                text: "Harap isi minimal 1 unit kerja",
            });

            return
        }
    }
    const loader = loading.show({});
    try {
        const { data } = await Api.post(`api/users/save`, modalData2.value, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        if (data.success) {
            modalInstance2.hide();
            showToast(data.message, 'success');
            fetchData();
        } else {
            showToast(data.message, 'danger');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        showToast(data.message, 'danger');
    } finally {
        loader.hide()
    }
};

const handleSubmitMenuUserCreate = async () => {
    // if (modalData3.value.roles.length == 0) {
    //     Swal.fire({
    //         icon: "warning",
    //         text: "Harap isi minimal 1 role",
    //     });

    //     return
    // }

    if (isEmptyObject(modalData3.value.roles) || modalData3.value.roles == null) {
        Swal.fire({
            icon: "warning",
            text: "Harap isi minimal 1 role",
        });

        return
    }

    console.log(modalData3.value);
    // if (modalData3.value.roles.includes(1) || modalData3.value.roles.includes('1')) {
    if (disabledRoleId.includes(parseInt(modalData3.value.roles))) {
        console.log('admin');
    } else {
        console.log('non-admin');
        if (isEmptyObject(modalData3.value.unit_kerja) || modalData3.value.unit_kerja == null) {
            Swal.fire({
                icon: "warning",
                text: "Harap isi minimal 1 unit kerja",
            });

            return
        }
    }
    const loader = loading.show({});
    try {
        const { data } = await Api.post(`api/users/save`, modalData3.value, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        if (data.success) {
            modalInstance3.hide();
            showToast(data.message, 'success');
            fetchData();
        } else {
            showToast(data.message, 'danger');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        showToast(data.message, 'danger');
    } finally {
        loader.hide()
    }
};

const createUser = async () => {
    const loader = loading.show({});
    try {
        modalData3.value.id = '';
        modalData3.value.new_pw = '';

        const { data } = await Api.get(`api/users/get_info_create`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        selectOptions3.value = [
            { code: true, label: 'Aktif' },
            { code: false, label: 'Tidak Aktif' }
        ]
        selectedValue3.value = { code: true, label: 'Aktif' }

        selectOptions4.value = data.roles
        selectedValue4.value = null
        selectOptionsUnitKerja.value = data.unit_kerja
        selectedValueUnitKerja.value = null
        selectOptionsGroup.value = data.group
        selectedValueGroup.value = []

        modalData3.value.id = '';
        modalData3.value.username = ''
        modalData3.value.password = ''
        modalData3.value.name = ''
        modalData3.value.nip = ''
        modalData3.value.email = ''
        modalData3.value.status = true
        modalData3.value.roles = null
        modalData3.value.group = []
        modalData3.value.unit_kerja = null

        if (!modalInstance3) {
            const modalElement = document.getElementById('create-user-modal');
            //here
            modalInstance3 = new bootstrap.Modal(modalElement, configObject);
        }
        modalInstance3.show();
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide()
    }
}

const showToast = (message, bg) => {
    toastEl.value.message = message;
    toastEl.value.bg = `text-bg-${bg}`;
    toast.value.show();

    setTimeout(() => {
        toast.value.hide();
    }, 3000);
};

const handleChange = (value) => {
    const temp = modalData2
    temp.value.status = value.code
    modalData2.value = temp.value
    // console.log('Selected value:', modalData2);
};

const handleChange2 = (value) => {
    // let arrTemp = []
    // value.map(item => {
    //     // console.log(item.label);
    //     arrTemp.push(item.code)
    // })
    // temp.value.roles = arrTemp
    // // temp.value.unit_kerja = null
    // // selectedValueUnitKerja.value = null
    // modalData2.value = temp.value

    var stringArray = disabledRoleId;  // -- cek array admin dan kanpus ---
    if (stringArray.indexOf(value.code) > -1 ) { 
        validateitem4.value = false;
        selectedValueUnitKerja.value = null
        selectedValueGroup.value = null
        modalData2.value.unit_kerja = null
        modalData2.value.group = []
    } else { 
        validateitem4.value = true;
    }

    // let arrTemp = []
    // value.map(item => {
    //     arrTemp.push(item.code)
    // })
    // temp.value.roles = arrTemp
    // modalData3.value = temp.value

    const temp = modalData2
    temp.value.roles = value.code
    modalData2.value = temp.value
};

const handleChange3 = (value) => {
    const temp = modalData3
    temp.value.status = value.code
    modalData3.value = temp.value
};

const handleChange4 = (value) => {
    // const temp = modalData3 
    //  -------------------------------------------------------------------------
    //  tambahan by ming --
    //  Mohon maaf ... pesanan kumendan -- kalau salah mohon di sesuaikan saja atau hapus saja.
    //  terima kasih
    // -------------------------------------------------------------------------
    var stringArray = disabledRoleId;  // -- cek array admin dan kanpus ---
    if (stringArray.indexOf(value.code) > -1 ) { 
        validateitem4.value = false;
        selectedValueUnitKerja.value = null
        selectedValueGroup.value = null
        modalData3.value.unit_kerja = null
        modalData3.value.group = []
    } else { 
        validateitem4.value = true;
    }

    // let arrTemp = []
    // value.map(item => {
    //     arrTemp.push(item.code)
    // })
    // temp.value.roles = arrTemp
    // modalData3.value = temp.value

    const temp = modalData3
    temp.value.roles = value.code
    modalData3.value = temp.value
};

const handleChangeGroup = (value) => {
    const temp = modalData2
    console.log(value);
    let arrTemp = []
    value.map(item => {
        arrTemp.push(item.code)
    })
    temp.value.group = arrTemp
    modalData2.value = temp.value
};

const handleChangeGroupCreate = (value) => {
    const temp = modalData3
    console.log(value);
    let arrTemp = []
    value.map(item => {
        arrTemp.push(item.code)
    })
    temp.value.group = arrTemp
    modalData3.value = temp.value
};

const handleChangeUnitKerja = (value) => {
    const temp = modalData2
    if (value) {
        temp.value.unit_kerja = value.code
    } else {
        temp.value.unit_kerja = null
    }
    modalData2.value = temp.value

    // console.log(value);
    // console.log('Selected value:', modalData2);
};

const handleChangeUnitKerjaCreate = (value) => {
    const temp = modalData3
    if (value) {
        temp.value.unit_kerja = value.code
    } else {
        temp.value.unit_kerja = null
    }
    modalData3.value = temp.value
};

const clickEventHandler = async (event) => {
    const targetEdit = event.target.closest('.btn-edit-user-pw');
    if (targetEdit) {
        const id = targetEdit.getAttribute('data-id');
        showModal(id);
    }

    const targetMenuUser = event.target.closest('.btn-menu-user');
    if (targetMenuUser) {
        const id = targetMenuUser.getAttribute('data-id');
        showModal2(id);
    }

    const targetDelete = event.target.closest('.btn-remove-user');
    if (targetDelete) {
        const id = targetDelete.getAttribute('data-id');
        const { isConfirmed, value } = await Swal.fire({
            text: 'Anda yakin akan menghapus user ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            inputPlaceholder: 'Alasan reject',
            showLoaderOnConfirm: true,
            confirmButtonClass: 'btn btn-primary w-xs me-2',
            cancelButtonClass: 'btn btn-danger w-xs',
            buttonsStyling: false,
        })

        if (isConfirmed) {
            const loader = loading.show({});
            try {
                const { data } = await Api.delete(`api/users/delete`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + localStorage.token
                    }, data: {
                        id
                    }
                });
                if (data.success) {
                    showToast(data.message, 'success');
                    console.log('x');
                    fetchData();
                } else {
                    showToast(data.message, 'danger');
                }
            } catch (error) {
                console.error(error);
                showToast(error, 'danger');
            } finally {
                loader.hide()
            }
        }
    }
}

onMounted(async () => {
    table1Instance = table1.value.dt;
    await fetchData();

    // event listeners
    document.removeEventListener('click', clickEventHandler);
    document.addEventListener('click', clickEventHandler);

    toast.value = new bootstrap.Toast(document.getElementById('toast'), { delay: 3000 });
});

onBeforeUnmount(() => {
    document.removeEventListener('click', clickEventHandler);
});
</script>

<template>
    <div class="toast-container position-fixed top-0 end-0 p-3">
        <div id="toast" :class="'toast align-items-center border-0 ' + toastEl.bg" role="alert" aria-live="assertive"
            aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    {{ toastEl.message }}
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                    aria-label="Close"></button>
            </div>
        </div>
    </div>

    <div id="new-pw-modal" class="modal fade" tabindex="-1" aria-labelledby="new-pw-modal-label" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Ubah Password</h5>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form method="post" id="new-pw-form" @submit.prevent="handleSubmitNewPw">
                        <input type="hidden" name="new_pw_id" class="form-control" v-model="modalData.id" required />
                        <div class="mb-3">
                            <label for="new_pw_name" class="form-label">Password Baru</label>
                            <input type="password" name="new_pw_name" class="form-control" v-model="modalData.new_pw"
                                required  placeholder="Masukkan password baru">
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="submit" form="new-pw-form" class="btn btn-success"
                        data-bs-dismiss="modal">Simpan</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
                </div>
            </div>
        </div>
    </div>

    <div id="menu-user-modal" class="modal fade" aria-labelledby="menu-user-modal-label" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Ubah User</h5>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form method="post" id="menu-user-form" @submit.prevent="handleSubmitMenuUser">
                        <div class="row">
                            <input type="hidden" name="menu_user_id" class="form-control" v-model="modalData2.id"
                                required />
                            <div class="col-12 col-md-3">
                                <div class="mt-3 mt-md-0">
                                    <label for="menu_user_name" class="form-label">Username</label>
                                    <input type="text" name="menu_user_name" class="form-control"
                                        v-model="modalData2.username" required placeholder="Masukkan username">
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="mt-3 mt-md-0">
                                    <label for="menu_user" class="form-label">Nama</label>
                                    <input type="text" name="menu_user" class="form-control" v-model="modalData2.name"
                                        required placeholder="Masukkan nama">
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="mt-3 mt-md-0">
                                    <label for="menu_nip" class="form-label">NIP</label>
                                    <input type="text" name="menu_nip" class="form-control" v-model="modalData2.nip"
                                        required  placeholder="Masukkan NIP">
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="mt-3 mt-md-0">
                                    <label for="menu_email" class="form-label">Email</label>
                                    <input type="email" name="menu_email" class="form-control"
                                        v-model="modalData2.email" required  placeholder="Masukkan email">
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="mt-3 mt-md-3">
                                    <label for="menu_status" class="form-label">Status</label>
                                    <v-select id="menu_status" v-model="selectedValue" :options="selectOptions"
                                        :clearable="false" @update:modelValue="handleChange" required></v-select>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="mt-3 mt-md-3">
                                    <label for="menu_role" class="form-label">Role</label>
                                    <v-select id="menu_role" v-model="selectedValue2" :options="selectOptions2"
                                        :clearable="false" @update:modelValue="handleChange2" required
                                        placeholder="Harap pilih role">
                                        <!-- <template #search="{ attributes, events }">
                                    <input class="vs__search" :required="!selectedValue2" v-bind="attributes" v-on="events" />
                                </template> -->
                                    </v-select>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="mt-3 mt-md-3">
                                    <label for="menu_unit_kerja" class="form-label">Unit Kerja</label>
                                    <v-select id="menu_unit_kerja" v-model="selectedValueUnitKerja"
                                        :options="selectOptionsUnitKerja" :clearable="true"
                                        @update:modelValue="handleChangeUnitKerja" placeholder="Harap pilih unit kerja"
                                        required :disabled="!validateitem4"></v-select>
                                </div>
                            </div>
                            <div class="col-12 col-md-12">
                                <div class="mt-3 mt-md-3">
                                    <label for="menu_group" class="form-label">Group</label>
                                    <v-select id="menu_group" v-model="selectedValueGroup" :options="selectOptionsGroup"
                                        :clearable="false" @update:modelValue="handleChangeGroup" multiple required
                                        placeholder="Harap pilih group" :disabled="!validateitem4">
                                    </v-select>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="submit" form="menu-user-form" class="btn btn-success"
                        data-bs-dismiss="modal">Simpan</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
                </div>
            </div>
        </div>
    </div>

    <div id="create-user-modal" class="modal fade" aria-labelledby="create-user-modal-label" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Tambah User</h5>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form method="post" id="create-user-form" @submit.prevent="handleSubmitMenuUserCreate">
                        <input type="hidden" name="create_user_id" class="form-control" v-model="modalData3.id"
                            required />
                        <div class="row">
                            <div class="col-12 col-md-3">
                                <div class="mt-3 mt-md-0">
                                    <label for="create_user_name" class="form-label">Username</label>
                                    <input type="text" name="create_user_name" class="form-control"
                                        v-model="modalData3.username" required  placeholder="Masukkan username">
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="mt-3 mt-md-0">
                                    <label for="create_user" class="form-label">Nama</label>
                                    <input type="text" name="create_user" class="form-control" v-model="modalData3.name"
                                        required  placeholder="Masukkan nama">
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="mt-3 mt-md-0">
                                    <label for="create_nip" class="form-label">NIP</label>
                                    <input type="text" name="create_nip" class="form-control" v-model="modalData3.nip"
                                        required  placeholder="Masukkan NIP">
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="mt-3 mt-md-0">
                                    <label for="create_email" class="form-label">Email</label>
                                    <input type="email" name="create_email" class="form-control"
                                        v-model="modalData3.email" required  placeholder="Masukkan email">
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="mt-3 mt-md-3">
                                    <label for="create_password" class="form-label">Password</label>
                                    <input type="password" name="create_password" class="form-control"
                                        v-model="modalData3.password" required  placeholder="Masukkan password">
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="mt-3 mt-md-3">
                                    <label for="create_status" class="form-label">Status</label>
                                    <v-select id="create_status" v-model="selectedValue3" :options="selectOptions3"
                                        :clearable="false" @update:modelValue="handleChange3" required></v-select>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="mt-3 mt-md-3">
                                    <label for="create_role" class="form-label">Role</label>
                                    <v-select id="create_role" v-model="selectedValue4" :options="selectOptions4"
                                        :clearable="false" @update:modelValue="handleChange4" required
                                        placeholder="Harap pilih role">
                                    </v-select>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="mt-3 mt-md-3">
                                    <label for="create_unit_kerja" class="form-label">Unit Kerja</label>
                                    <v-select id="create_unit_kerja" v-model="selectedValueUnitKerja"
                                        :options="selectOptionsUnitKerja" :clearable="true"
                                        @update:modelValue="handleChangeUnitKerjaCreate"
                                        placeholder="Harap pilih unit kerja" required :disabled="!validateitem4"></v-select>
                                </div>
                            </div>
                            <div class="col-12 col-md-12">
                                <div class="mt-3 mt-md-3">
                                    <label for="create_group" class="form-label">Group</label>
                                    <v-select id="create_group" v-model="selectedValueGroup"
                                        :options="selectOptionsGroup" :clearable="false"
                                        @update:modelValue="handleChangeGroupCreate" multiple required
                                        placeholder="Harap pilih group" :disabled="!validateitem4">
                                    </v-select>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="submit" form="create-user-form" class="btn btn-success"
                        data-bs-dismiss="modal">Simpan</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="card">
            <div class="card-header">
                <h5 class="mb-0">User</h5>
            </div>
            <div class="card-body">
                <button class="btn btn-primary mb-2" @click="createUser">Tambah User</button>
                <DataTable :columns="columns" :data="tableData" :options="dataTableOptions" ref="table1"
                    class="table table-bordered table-striped" width="100%"></DataTable>
            </div>
        </div>
    </div>
</template>

<style>
@import 'bootstrap';
@import 'datatables.net-bs5';
</style>

<style scoped>
.select2-dropdown {
    z-index: 99999 !important;
    /* Ensure this is higher than the modal z-index */
}

.xxx {
    position: relative;
    /* Ensure the parent has positioning */
}
</style>