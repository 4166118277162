import axios from 'axios';

const url = 'https://api-windspeed.jasamaritim.co.id'

const Api = axios.create({
    baseURL: url
})

const whitelistedUrls = ['/api/login', '/api/logout']
let isLoggingOut = false;

Api.interceptors.request.use(config => {
    if (isLoggingOut) {
        return Promise.reject(new axios.Cancel('Operation canceled due to logout.'));
    }
    return config;
}, error => {
    return Promise.reject(error);
});

Api.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        const requestUrl = error.config?.url;

        if (!whitelistedUrls.includes(requestUrl) && error.response?.status === 401) {
            if (!isLoggingOut) {
                isLoggingOut = true;
                console.log("Unauthorized: Redirecting to login page...");
                alert("User token invalid/expired, logging out...");
                try {
                    await axios.post(`${url}/api/logout`, {}, {
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json",
                            'Authorization': 'Bearer ' + localStorage.token
                        },
                    });
                } catch (error) {
                    console.error(error);
                    
                }
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('pathlist')
                localStorage.setItem('position', '/')
                window.location.href = '/';
            }
        }
        return Promise.reject(error);
    }
);

export default Api