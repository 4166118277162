<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import { useLoading } from 'vue-loading-overlay'
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';
import Api from '../../services/api';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Swal from 'sweetalert2';

import 'vue-select/dist/vue-select.css';

import $ from 'jquery';
import moment from 'moment';

import Datepicker from 'vue3-datepicker'

const fileUrl = ref('')

const selectedPeriode = ref(new Date())
const periodeFormat = 'yyyy'
const inputPeriodeRef = ref(null)

const selectedTglKalibrasi = ref(new Date())
const selectedTglNextKalibrasi = ref(new Date())
const tglKalibrasiFormat = 'dd MMMM yyyy'
const tglNextKalibrasiFormat = 'MMMM yyyy'
const inputTglPeriodeRef = ref(null)
const inputTglNextPeriodeRef = ref(null)

let tableLokasiInstance;
const tableLokasi = ref();
const tableLokasiData = ref([]);

const lokasiModalLabel = ref('')
const kalibrasiModalLabel = ref('')
const penggunaModalLabel = ref('')

const modalLokasiData = ref({ key_id: null, key_id_alat: null });
let modalLokasiInstance = null;
const modalKalibrasiData = ref({ key_id: null, key_id_header: null, periode: null, tgl_kalibrasi: null, file_kalibrasi: null, path_file: null, tgl_next_kalibrasi: null });
let modalKalibrasiInstance = null;

const selectedLokasiValue = ref(null);
const selectLokasiOptions = ref([]);


const reminder = ref(1)
const modalReminderData = ref({ key_id: null, reminder: null });
let modalReminderInstance = null;


const loading = useLoading({});
const configObject = { keyboard: false, backdrop: 'static' }
const toast = ref(null);
const toastEl = ref({});
const dataTableOptions = ref({
    order: [],
    scrollX: true,
    initComplete: function () {
        // 
    },
    drawCallback: function (settings) {
        const elementsToRemoveShown = document.querySelectorAll('tr.shown > td.details-control');
        elementsToRemoveShown.forEach(element => {
            const parentTR = element.closest('tr');
            if (parentTR) {
                parentTR.classList.remove('shown');
            }
        });
    },
});

DataTable.use(DataTablesCore);

const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

const columns = [
    {
        className: 'details-control text-center',
        data: null,
        defaultContent: '',
        width: '20px',
        title: "#",
        orderable: false
    },
    // { title: "ID", data: 'key_id', width: "20px" },
    { title: "No.", data: 'row_', width: "20px" },
    { title: "Lokasi", data: 'lokasi' },
    {
        title: 'Next Kalibrasi',
        data: 'next_kalibrasi',
        className: 'text-start',
        render: function (datax, type, row) {
            const div = datax ? moment(`${datax.slice(2, 6)}-${datax.slice(0, 2)}-01`).format('MMMM YYYY') : '-';
            return div;
        }
    },
    {
        title: 'Reminder',
        data: 'reminder',
        className: 'text-start',
        render: function (datax, type, row) {
            const div = datax ? datax + " hari" : '-';
            return div;
        }
    },
    {
        title: 'Aksi',
        orderable: false,
        data: null,
        width: '80px',
        render: function (data, type, row) {
            const disabled = false ? "disabled" : ""
            const div = `<div class="d-flex">
                            <button class="btn btn-sm btn-outline-primary btn-set-reminder mr-2" ${disabled} title="Set Reminder" data-id=${row.key_id} data-reminder="${row.reminder}"><i class="operation-icon fas fa-bell"></i></button>                
                            <button class="btn btn-sm btn-outline-danger btn-remove-lokasi" ${disabled} title="Hapus Lokasi" data-id=${row.key_id}><i class="operation-icon fas fa-trash-alt"></i></button>
                        </div>`;
            return div;
        }
    },
];

const createLokasi = async () => {
    const loader = loading.show({});
    try {
        const { data } = await Api.get(`api/master/kalibrasi/get_info_create`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        selectLokasiOptions.value = data.lokasi
        selectedLokasiValue.value = null

        modalLokasiData.value.key_id = null;
        modalLokasiData.value.key_id_alat = null;
        showLokasiModal(null, 'create')
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide()
    }
}

const createKalibrasi = async header_id => {
    $('#kalibrasi-form').trigger("reset")
    fileUrl.value = ''
    $('#fileTargetIdEl').hide()
    const loader = loading.show({});
    try {
        const { data } = await Api.get(`api/master/kalibrasi/get_info_d_next_kalibrasi`, {
            params: { header_id },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        // const nextKalibrasi = data.data[0].next_kalibrasi

        modalKalibrasiData.value.key_id = null;
        modalKalibrasiData.value.key_id_header = header_id;
        modalKalibrasiData.value.periode = new Date().getFullYear();
        modalKalibrasiData.value.tgl_kalibrasi = moment(new Date()).format('YYYY-MM-DD')
        modalKalibrasiData.value.file_kalibrasi = null
        modalKalibrasiData.value.path_file = null
        modalKalibrasiData.value.tgl_next_kalibrasi = moment(`${data.data[0].next_kalibrasi.slice(2, 6)}-${data.data[0].next_kalibrasi.slice(0, 2)}-01`).format('YYYY-MM-DD')

        selectedPeriode.value = new Date()
        selectedTglKalibrasi.value = new Date()
        selectedTglNextKalibrasi.value = moment(`${data.data[0].next_kalibrasi.slice(2, 6)}-${data.data[0].next_kalibrasi.slice(0, 2)}-01`, 'YYYY-MM-DD').toDate()
        showKalibrasiModal(null, 'create')
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide()
    }
}

const editReminder = async (header_id, reminder) => {
    // $('#reminder-form').trigger("reset")
    // const loader = loading.show({});
    try {
        modalReminderData.value.key_id = header_id;
        modalReminderData.value.reminder = reminder;
        // console.log(modalReminderData.value);
        showReminderModal(null, 'edit')
    } catch (error) {
        console.error(error);
    } finally {
        // loader.hide()
    }
}

const editKalibrasi = async (id, header_id) => {
    $('#kalibrasi-form').trigger("reset")
    const loader = loading.show({});
    try {
        const { data } = await Api.get(`api/master/kalibrasi/get_info_d`, {
            params: { id, header_id },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        modalKalibrasiData.value.key_id = id;
        modalKalibrasiData.value.key_id_header = header_id;
        modalKalibrasiData.value.periode = data.data[0].periode;
        modalKalibrasiData.value.tgl_kalibrasi = moment(new Date(data.data[0].tgl_kalibrasi)).format('YYYY-MM-DD')
        modalKalibrasiData.value.file_kalibrasi = null
        modalKalibrasiData.value.path_file = data.data[0].path_file

        // const month = data.data[0].next_kalibrasi.slice(0, 2)
        // const year = data.data[0].next_kalibrasi.slice(2, 6)
        modalKalibrasiData.value.tgl_next_kalibrasi = moment(`${data.data[0].next_kalibrasi.slice(2, 6)}-${data.data[0].next_kalibrasi.slice(0, 2)}-01`).format('YYYY-MM-DD')
        // console.log(moment(`${data.data[0].next_kalibrasi.slice(2, 6)}-${data.data[0].next_kalibrasi.slice(0, 2)}-01`).format('YYYY-MM-DD'));

        if (data.data[0].path_file) {
            fileUrl.value = data.url
            $('#fileTargetIdEl').show()
        } else {
            fileUrl.value = ''
            $('#fileTargetIdEl').hide()
        }

        selectedPeriode.value = new Date(data.data[0].periode, 0)
        selectedTglKalibrasi.value = new Date(data.data[0].tgl_kalibrasi)
        // selectedTglNextKalibrasi.value = new Date(data.data[0].next_kalibrasi)
        selectedTglNextKalibrasi.value = moment(`${data.data[0].next_kalibrasi.slice(2, 6)}-${data.data[0].next_kalibrasi.slice(0, 2)}-01`, 'YYYY-MM-DD').toDate()

        showKalibrasiModal(id, 'edit')
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide()
    }
}

const showLokasiModal = (id, operation) => {
    lokasiModalLabel.value = operation === 'edit' ? 'Ubah Lokasi' : 'Tambah Lokasi';

    if (!modalLokasiInstance) {
        const modalElement = document.getElementById('lokasi-modal');
        modalLokasiInstance = new bootstrap.Modal(modalElement, configObject);
    }
    modalLokasiInstance.show();
};

const showKalibrasiModal = (id, operation) => {
    kalibrasiModalLabel.value = operation === 'edit' ? 'Ubah Kalibrasi' : 'Tambah Kalibrasi';

    if (!modalKalibrasiInstance) {
        const modalElement = document.getElementById('kalibrasi-modal');
        modalKalibrasiInstance = new bootstrap.Modal(modalElement, configObject);
    }
    modalKalibrasiInstance.show();
};

const showReminderModal = (id, operation) => {

    if (!modalReminderInstance) {
        const modalElement = document.getElementById('reminder-modal');
        modalReminderInstance = new bootstrap.Modal(modalElement, configObject);
    }
    modalReminderInstance.show();
};

const handleChangeLokasi = (value) => {
    const temp = modalLokasiData
    if (value) {
        temp.value.key_id_alat = value.code
    } else {
        temp.value.key_id_alat = null
    }
    modalLokasiData.value = temp.value
};

const handleChangePeriode = (value) => {
    if (inputPeriodeRef.value) {
        inputPeriodeRef.value.blur()
    }
    const temp = modalKalibrasiData
    temp.value.periode = value.getFullYear()
    modalKalibrasiData.value = temp.value
};

const handleChangeTglKalibrasi = (value) => {
    if (inputTglPeriodeRef.value) {
        inputTglPeriodeRef.value.blur()
    }
    const temp = modalKalibrasiData
    temp.value.tgl_kalibrasi = moment(value).format('YYYY-MM-DD')
    modalKalibrasiData.value = temp.value
};

const handleChangeTglNextKalibrasi = (value) => {
    if (inputTglNextPeriodeRef.value) {
        inputTglNextPeriodeRef.value.blur()
    }
    const temp = modalKalibrasiData
    temp.value.tgl_next_kalibrasi = moment(value).format('YYYY-MM-DD')
    modalKalibrasiData.value = temp.value
};

const handleFileKalibrasiChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        modalKalibrasiData.value.file_kalibrasi = file;
    }
};

const filePreviewHandler = () => {
    const url = fileUrl.value;
    window.open(url, '_blank');
}

const submitLokasiHandler = async () => {
    if (isEmptyObject(modalLokasiData.value.key_id_alat) || modalLokasiData.value.key_id_alat == null) {
        Swal.fire({
            icon: "warning",
            text: "Harap isi lokasi",
        });

        return
    }

    const loader = loading.show({});
    try {
        const { data } = await Api.post(`api/master/kalibrasi/save`, modalLokasiData.value, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        if (data.success) {
            modalLokasiInstance.hide();
            showToast(data.message, 'success');
            fetchData();
        } else {
            showToast(data.message, 'danger');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        showToast(error, 'danger');
    } finally {
        loader.hide()
    }
};

const submitKalibrasiHandler = async () => {
    // console.log(modalKalibrasiData.value.tgl_kalibrasi);
    // console.log(modalKalibrasiData.value.tgl_next_kalibrasi);
    if (modalKalibrasiData.value.tgl_kalibrasi == modalKalibrasiData.value.tgl_next_kalibrasi) {
        Swal.fire({
            icon: "warning",
            // text: "Bulan next kalibrasi tidak boleh sama dengan bulan tgl. Kalibrasi",
            text: "Bulan next kalibrasi harus lebih besar dari bulan tgl. kalibrasi"
        });
        return
    } else if (modalKalibrasiData.value.tgl_kalibrasi > modalKalibrasiData.value.tgl_next_kalibrasi) {
        Swal.fire({
            icon: "warning",
            text: "Bulan next kalibrasi harus lebih besar dari bulan tgl. kalibrasi",
        });
        return
    }
    // return

    if (modalKalibrasiData.value.file_kalibrasi) {
        const fileLaporan = document.getElementById('fileKalibrasiIdEl').files[0];

        // check fileFormat
        const splitedFileName = fileLaporan.name.split('.');
        let validFile = true;
        if (splitedFileName.length == 1) {
            validFile = false;
        } else {
            const ext = splitedFileName[splitedFileName.length - 1].toLowerCase()
            const allowedType = ["pdf"];
            if (!allowedType.includes(ext)) {
                console.log(`ext is ${ext}`);
                validFile = false;
            }
        }
        if (!validFile) {
            return showToast('Format file laporan tidak sesuai', 'danger');
        }
    } else if (!modalKalibrasiData.value.key_id) {
        Swal.fire({
            icon: "warning",
            text: "Harap isi file kalibrasi",
        });

        return
    }

    // console.log(modalKalibrasiData.value.key_id);
    // return


    const loader = loading.show({});
    try {
        const { data } = await Api.post(`api/master/kalibrasi/save_d`, modalKalibrasiData.value, {
            headers: {
                // "Accept": "application/json",
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        if (data.success) {
            modalKalibrasiInstance.hide();
            showToast(data.message, 'success');
            fetchData()
        } else {
            showToast(data.message, 'danger');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        showToast(error, 'danger');
    } finally {
        loader.hide()
    }
};

const submitReminderHandler = async () => {
    // console.log(modalReminderData.value);
    // return

    const loader = loading.show({});
    try {
        const { data } = await Api.post(`api/master/kalibrasi/save_reminder`, modalReminderData.value, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        if (data.success) {
            modalReminderInstance.hide();
            showToast(data.message, 'success');
            fetchData()
        } else {
            showToast(data.message, 'danger');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        showToast(error, 'danger');
    } finally {
        loader.hide()
    }
};

const fetchData = async () => {
    const loader = loading.show({});
    try {
        const { data } = await Api.get('api/master/kalibrasi', {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        tableLokasiData.value = data.data;
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        loader.hide()
        setTimeout(() => {
            tableLokasiInstance.columns.adjust().draw()
        }, 0);
    }
};

const showToast = (message, bg) => {
    toastEl.value.message = message;
    toastEl.value.bg = `text-bg-${bg}`;
    toast.value.show();

    setTimeout(() => {
        toast.value.hide();
    }, 3000);
};

const clickEventHandler = async (event) => {

    const targetDeletLokasi = event.target.closest('.btn-remove-lokasi');
    if (targetDeletLokasi) {
        const id = targetDeletLokasi.getAttribute('data-id');
        const { isConfirmed, value } = await Swal.fire({
            text: 'Anda yakin akan menghapus master lokasi ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            inputPlaceholder: 'Alasan reject',
            showLoaderOnConfirm: true,
            confirmButtonClass: 'btn btn-primary w-xs me-2',
            cancelButtonClass: 'btn btn-danger w-xs',
            buttonsStyling: false,
        })

        if (isConfirmed) {
            const loader = loading.show({});
            try {
                const { data } = await Api.delete(`api/master/kalibrasi/delete`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + localStorage.token
                    }, data: {
                        id
                    }
                });
                if (data.success) {
                    showToast(data.message, 'success');
                    fetchData();
                } else {
                    showToast(data.message, 'danger');
                }
            } catch (error) {
                console.error(error);
                showToast(error, 'danger');
            } finally {
                loader.hide()
            }
        }
    }

    const targetEditReminder = event.target.closest('.btn-set-reminder');
    if (targetEditReminder) {
        const id = targetEditReminder.getAttribute('data-id');
        const reminder = targetEditReminder.getAttribute('data-reminder') == "null" ? 10 : targetEditReminder.getAttribute('data-reminder');
        // console.log(reminder);
        editReminder(id, reminder);
    }

    const targetAddKalibrasi = event.target.closest('.add-kalibrasi-btn');
    if (targetAddKalibrasi) {
        const id = targetAddKalibrasi.getAttribute('data-id');
        createKalibrasi(id);
    }

    const targetEditKalibrasi = event.target.closest('.btn-edit-kalibrasi');
    if (targetEditKalibrasi) {
        const id = targetEditKalibrasi.getAttribute('data-id');
        const header_id = targetEditKalibrasi.getAttribute('data-id-header');
        editKalibrasi(id, header_id);
    }

    const targetDeleteKalibrasi = event.target.closest('.btn-remove-kalibrasi');
    if (targetDeleteKalibrasi) {
        const id = targetDeleteKalibrasi.getAttribute('data-id');
        const { isConfirmed, value } = await Swal.fire({
            text: 'Anda yakin akan menghapus master kalibrasi ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            inputPlaceholder: 'Alasan reject',
            showLoaderOnConfirm: true,
            confirmButtonClass: 'btn btn-primary w-xs me-2',
            cancelButtonClass: 'btn btn-danger w-xs',
            buttonsStyling: false,
        })

        if (isConfirmed) {
            const loader = loading.show({});
            try {
                const { data } = await Api.delete(`api/master/kalibrasi/delete_d`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + localStorage.token
                    }, data: {
                        id
                    }
                });
                if (data.success) {
                    showToast(data.message, 'success');
                    fetchData();
                } else {
                    showToast(data.message, 'danger');
                }
            } catch (error) {
                console.error(error);
                showToast(error, 'danger');
            } finally {
                loader.hide()
            }
        }
    }

    const targetSubGrid = event.target.closest('td.details-control');
    if (targetSubGrid) {
        const tr = event.target.closest('tr');
        const row = tableLokasiInstance.row(tr)

        if (document.getElementById(`tr-${row.data().key_id}`)) {
            tr.classList.remove('shown')
            $(`#tr-${row.data().key_id}`).remove()
        } else {
            expandSubGrid(tr, row.data())
            tr.classList.add('shown')
        }
        setTimeout(() => {
            tableLokasiInstance.columns.adjust()
        }, 0);
    }

    const targetViewFileKalibrasi = event.target.closest('.btn-view-file-kalibrasi');
    if (targetViewFileKalibrasi) {
        const pathFile = targetViewFileKalibrasi.getAttribute('data-path-file');
        const url = pathFile;
        window.open(url, '_blank');
    }
}

const expandSubGrid = async (tr, data) => {
    let subGridData = []

    const loader = loading.show({});
    try {
        const response = await Api.get('api/master/kalibrasi/d', {
            params: { header_id: data.key_id },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        subGridData = response.data.data
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide()
    }

    tr.insertAdjacentHTML('afterend', `
        <tr id="tr-${data.key_id}">
            <td></td>
            <td colspan='5' style="max-width: 0px !important;">
                <button class="btn btn-primary mb-2 add-kalibrasi-btn" id="" data-id="${data.key_id}">Tambah Kalibrasi</button>
                <table style="width: 100%; height: 100%;" id='datatable-detail-${data.key_id}' class='table table-bordered table-striped datatable-details'>
                </table>
            </td>
        </tr>
    `);

    await $(`#datatable-detail-${data.key_id}`).dataTable({
        order: [],
        scrollX: true,
        data: subGridData,
        columns: [
            // { title: "ID", data: 'key_id', width: "20px", className: 'text-start' },
            { title: "No.", data: 'row_', width: "20px", className: 'text-start' },
            { title: "Periode", data: 'periode', className: 'text-start' },
            {
                title: 'Tanggal Kalibrasi',
                data: 'tgl_kalibrasi',
                className: 'text-start',
                render: function (datax, type, row) {
                    const div = moment(new Date(datax)).format('DD MMMM YYYY');
                    return div;
                }
            },
            {
                title: 'Aksi',
                orderable: false,
                data: null,
                className: 'text-start',
                render: function (datax, type, row) {
                    // console.log(row);
                    const disabled = false ? "disabled" : ""
                    const pathEl = row.path_file ? `<button class="btn btn-sm btn-outline-secondary btn-view-file-kalibrasi mr-2" ${disabled} title="Lihat File Kalibrasi" data-path-file=${row.path_file}><i class="operation-icon fas fa-eye"></i></button>` : ""
                    const div = `<div class="d-flex">
                                ${pathEl}
                                <button class="btn btn-sm btn-outline-primary btn-edit-kalibrasi mr-2" ${disabled} title="Ubah Kalibrasi" data-id=${row.key_id} data-id-header=${data.key_id}><i class="operation-icon fas fa-pen"></i></button>
                                <button class="btn btn-sm btn-outline-danger btn-remove-kalibrasi" ${disabled} title="Hapus Kalibrasi" data-id=${row.key_id}><i class="operation-icon fas fa-trash-alt"></i></button>
                            </div>`;
                    return div;
                }
            },
        ],
        initComplete: function (settings, json) {
            $(`table#datatable-detail-${data.key_id} > thead > tr`).css({ visibility: 'collapse' });
        },
        drawCallback: function (settings) {
            $(`table#datatable-detail-${data.key_id} > thead > tr`).css({ visibility: 'collapse' });
            setTimeout(() => {
                $(`#datatable-detail-${data.key_id}`).DataTable().columns.adjust()
                $(`table#datatable-detail-${data.key_id} > thead > tr`).css({ visibility: 'collapse' });
                tableLokasiInstance.columns.adjust()
            }, 0);

        }
    })
}

onMounted(async () => {
    tableLokasiInstance = tableLokasi.value.dt;
    inputPeriodeRef.value = document.getElementById('periodeIdEl')
    inputTglPeriodeRef.value = document.getElementById('tglKalibrasiIdEl')
    inputTglNextPeriodeRef.value = document.getElementById('tglNextKalibrasiIdEl')
    await fetchData();
    document.removeEventListener('click', clickEventHandler);
    document.addEventListener('click', clickEventHandler);
    toast.value = new bootstrap.Toast(document.getElementById('toast'), { delay: 6000 });
});

onBeforeUnmount(() => {
    document.removeEventListener('click', clickEventHandler);
});
</script>

<template>
    <div class="toast-container position-fixed top-0 end-0 p-3">
        <div id="toast" :class="'toast align-items-center border-0 ' + toastEl.bg" role="alert" aria-live="assertive"
            aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    {{ toastEl.message }}
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                    aria-label="Close"></button>
            </div>
        </div>
    </div>

    <div id="lokasi-modal" class="modal fade" aria-labelledby="lokasi-modal-label" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ lokasiModalLabel }}</h5>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form method="post" id="lokasi-form" @submit.prevent="submitLokasiHandler">
                        <div class="row">
                            <input type="hidden" class="form-control" v-model="modalLokasiData.key_id" required />
                            <div class="col-12 col-md-12">
                                <div class="mt-3 mt-md-0">
                                    <label class="form-label">Lokasi</label>
                                    <v-select v-model="selectedLokasiValue" :options="selectLokasiOptions"
                                        :clearable="false" @update:modelValue="handleChangeLokasi"
                                        placeholder="Harap pilih lokasi" required></v-select>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="submit" form="lokasi-form" class="btn btn-success"
                        data-bs-dismiss="modal">Simpan</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
                </div>
            </div>
        </div>
    </div>

    <div id="reminder-modal" class="modal fade" aria-labelledby="reminder-modal-label" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Set Reminder</h5>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form method="post" id="reminder-form" @submit.prevent="submitReminderHandler">
                        <div class="row">
                            <input type="hidden" class="form-control" v-model="modalReminderData.key_id" required />
                            <div class="col-12 col-md-12">
                                <div class="mt-3 mt-md-0">
                                    <label class="form-label">Jumlah hari</label>
                                    <input type="number" class="form-control" step="1" min="1"
                                        onkeyup="this.value=this.value.replace(/[^\d]/,'')"
                                        v-model="modalReminderData.reminder" required>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="submit" form="reminder-form" class="btn btn-success"
                        data-bs-dismiss="modal">Simpan</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
                </div>
            </div>
        </div>
    </div>

    <div id="kalibrasi-modal" class="modal fade" aria-labelledby="kalibrasi-modal-label" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ kalibrasiModalLabel }}</h5>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form method="post" id="kalibrasi-form" @submit.prevent="submitKalibrasiHandler">
                        <div class="row">
                            <input type="hidden" class="form-control" v-model="modalKalibrasiData.key_id" required />
                            <input type="hidden" class="form-control" v-model="modalKalibrasiData.key_id_header"
                                required />
                            <div class="col-12 col-md-12">
                                <div class="mt-3 mt-md-0">
                                    <label class="form-label">Periode</label>
                                    <Datepicker id="periodeIdEl" class="form-control bg-white" v-model="selectedPeriode"
                                        @update:modelValue="handleChangePeriode" :inputFormat="periodeFormat"
                                        placeholder="Pilih periode" startingView="year" minimumView="year" />
                                </div>
                            </div>
                            <div class="col-12 col-md-12">
                                <div class="mt-3 mt-md-3">
                                    <label class="form-label">Tgl. Kalibrasi</label>
                                    <Datepicker id="tglKalibrasiIdEl" class="form-control bg-white"
                                        v-model="selectedTglKalibrasi" @update:modelValue="handleChangeTglKalibrasi"
                                        :inputFormat="tglKalibrasiFormat" placeholder="Pilih tanggal" />
                                </div>
                            </div>
                            <div class="col-12 col-md-12">
                                <div class="mt-3 mt-md-3">
                                    <label class="form-label">Next Kalibrasi</label>
                                    <Datepicker id="tglNextKalibrasiIdEl" class="form-control bg-white"
                                        v-model="selectedTglNextKalibrasi"
                                        @update:modelValue="handleChangeTglNextKalibrasi"
                                        :inputFormat="tglNextKalibrasiFormat" placeholder="Pilih tanggal"
                                        startingView="month" minimumView="month" />
                                </div>
                            </div>
                            <div class="col-12 col-md-12">
                                <div class="mt-3 mt-md-3">
                                    <label class="form-label">File Kalibrasi</label>
                                    <input type="file" class="form-control" id="fileKalibrasiIdEl" accept=".pdf"
                                        @change="handleFileKalibrasiChange">
                                </div>
                            </div>
                            <div class="col-12 col-md-12" id="fileTargetIdEl">
                                <div class="mt-3 mt-md-3 row">
                                    <div class="col-12">
                                        <label class="form-label">File Terupload</label>
                                    </div>
                                    <div class="col-12">
                                        <button type="button" class="btn btn-sm btn-primary" @click="filePreviewHandler"
                                            title="Pratinjau">Pratinjau</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="submit" form="kalibrasi-form" class="btn btn-success"
                        data-bs-dismiss="modal">Simpan</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="card">
            <div class="card-header">
                <h5 class="mb-0">Kalibrasi Device</h5>
            </div>
            <div class="card-body">
                <button class="btn btn-primary mb-2" @click="createLokasi">Tambah Lokasi</button>
                <DataTable :columns="columns" :data="tableLokasiData" :options="dataTableOptions" ref="tableLokasi"
                    class="table table-bordered datatableLokasiClass" width="100%"></DataTable>
            </div>
        </div>
    </div>
</template>

<style>
@import 'bootstrap';
@import 'datatables.net-bs5';
</style>

<style scoped>
.select2-dropdown {
    z-index: 99999 !important;
}
</style>

<style>
table.datatable-details>thead>tr>th,
table.datatable-details-2>thead>tr>th {
    padding: 8px 30px 8px 8px !important;
    min-height: 41.2px !important;
}

div.dt-scroll-body>table.datatableLokasiClass {
    overflow: hidden !important;
}
</style>