<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import { useLoading } from 'vue-loading-overlay'
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';
import Api from '../../services/api';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Swal from 'sweetalert2';

import 'vue-select/dist/vue-select.css';

import $ from 'jquery';

let tableGroupInstance;
const tableGroup = ref();
const tableGroupData = ref([]);

const groupModalLabel = ref('')
const lokasiModalLabel = ref('')
const penggunaModalLabel = ref('')

const modalGroupData = ref({ key_id: null, nm_group: null, is_aktif: true, keterangan: null });
let modalGroupInstance = null;
const modalLokasiData = ref({ key_id: null, key_id_header: null, key_id_alat: null });
let modalLokasiInstance = null;
const modalPenggunaData = ref({ key_id: null, key_id_grp_mon: null, key_id_users: null });
let modalPenggunaInstance = null;

const selectedStatusGroupValue = ref(null);
const selectGroupStatusOptions = ref([
    { code: true, label: 'Aktif' },
    { code: false, label: 'Tidak Aktif' }
]);

const selectedLokasiValue = ref(null);
const selectLokasiOptions = ref([]);
const selectedPenggunaValue = ref(null);
const selectPenggunaOptions = ref([]);

const loading = useLoading({});
const configObject = { keyboard: false, backdrop: 'static' }
const toast = ref(null);
const toastEl = ref({});
const dataTableOptions = ref({
    order: [],
    scrollX: true,
    initComplete: function () {
        // 
    },
    drawCallback: function (settings) {
        const elementsToRemoveShown = document.querySelectorAll('tr.shown > td.details-control');
        elementsToRemoveShown.forEach(element => {
            const parentTR = element.closest('tr');
            if (parentTR) {
                parentTR.classList.remove('shown');
            }
        });
    },
});

DataTable.use(DataTablesCore);

const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

const columns = [
    {
        className: 'details-control text-center',
        data: null,
        defaultContent: '',
        width: '20px',
        title: "#",
        orderable: false
    },
    // { title: "ID", data: 'key_id', width: "20px" },
    { title: "No.", data: 'row_', width: "20px" },
    { title: "Nama Group", data: 'nm_grp' },
    {
        title: 'Status',
        orderable: false,
        data: null,
        width: '80px',
        render: function (data, type, row) {
            const text = row.is_aktif ? 'Aktif' : 'Tidak aktif';
            const color = row.is_aktif ? 'bg-success' : 'bg-secondary';

            const div = "<span class='badge " + color + "'>" + text + "</span>"
            return div;
        }
    },
    { title: "Keterangan", data: 'keterangan' },
    {
        title: 'Aksi',
        orderable: false,
        data: null,
        width: '80px',
        render: function (data, type, row) {
            const disabled = false ? "disabled" : ""
            const div = `<div class="d-flex">
                                <button class="btn btn-sm btn-outline-primary btn-edit-group mr-2" ${disabled} title="Ubah Group" data-id=${row.key_id}><i class="operation-icon fas fa-pen"></i></button>
                                <button class="btn btn-sm btn-outline-danger btn-remove-group" ${disabled} title="Hapus Group" data-id=${row.key_id}><i class="operation-icon fas fa-trash-alt"></i></button>
                            </div>`;
            return div;
        }
    },
];

const handleStatusGroupChange = (value) => {
    const temp = modalGroupData
    temp.value.is_aktif = value.code
    modalGroupData.value = temp.value
};

const createGroup = async () => {
    const loader = loading.show({});
    try {
        modalGroupData.value.key_id = null;
        modalGroupData.value.nm_group = null;
        modalGroupData.value.keterangan = null;
        modalGroupData.value.is_aktif = true;
        selectedStatusGroupValue.value = { code: true, label: 'Aktif' }
        showGroupModal(null, 'create')
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide()
    }
}

const createLokasi = async header_id => {
    const loader = loading.show({});
    try {
        const { data } = await Api.get(`api/master/group/get_info_create_d`, {
            params: { header_id },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        selectLokasiOptions.value = data.lokasi
        selectedLokasiValue.value = null

        modalLokasiData.value.key_id = null;
        modalLokasiData.value.key_id_header = header_id;
        modalLokasiData.value.key_id_alat = null;
        showLokasiModal(null, 'create')
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide()
    }
}

const createPengguna = async header_id => {
    const loader = loading.show({});
    try {
        const { data } = await Api.get(`api/master/group/get_info_create_p`, {
            params: { header_id },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        selectPenggunaOptions.value = data.user
        selectedPenggunaValue.value = null

        modalPenggunaData.value.key_id = null;
        modalPenggunaData.value.key_id_grp_mon = header_id;
        modalPenggunaData.value.key_id_users = null;
        showPenggunaModal(null, 'create')
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide()
    }
}

const editGroup = async id => {
    const loader = loading.show({});
    try {
        const { data } = await Api.get(`api/master/group/get_info`, {
            params: { id },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        modalGroupData.value.key_id = id;
        modalGroupData.value.nm_group = data.data[0].nm_grp;
        modalGroupData.value.is_aktif = data.data[0].is_aktif;
        modalGroupData.value.keterangan = data.data[0].keterangan;
        selectedStatusGroupValue.value = data.data[0].is_aktif ? { code: true, label: 'Aktif' } : { code: false, label: 'Tidak Aktif' }
        showGroupModal(id, 'edit')
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide()
    }
}

const editLokasi = async (id, header_id) => {
    const loader = loading.show({});
    try {
        const { data } = await Api.get(`api/master/group/get_info_d`, {
            params: { id, header_id },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        selectLokasiOptions.value = data.lokasi
        selectedLokasiValue.value = data.user_lokasi

        modalLokasiData.value.key_id = id;
        modalLokasiData.value.key_id_header = data.data[0].key_id_header
        modalLokasiData.value.key_id_alat = data.data[0].key_id_alat;
        showLokasiModal(id, 'edit')
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide()
    }
}

const showGroupModal = (id, operation) => {
    groupModalLabel.value = operation === 'edit' ? 'Ubah Group' : 'Tambah Group';

    if (!modalGroupInstance) {
        const modalElement = document.getElementById('group-modal');
        modalGroupInstance = new bootstrap.Modal(modalElement, configObject);
    }
    modalGroupInstance.show();
};

const showLokasiModal = (id, operation) => {
    lokasiModalLabel.value = operation === 'edit' ? 'Ubah Lokasi' : 'Tambah Lokasi';

    if (!modalLokasiInstance) {
        const modalElement = document.getElementById('lokasi-modal');
        modalLokasiInstance = new bootstrap.Modal(modalElement, configObject);
    }
    modalLokasiInstance.show();
};

const showPenggunaModal = (id, operation) => {
    penggunaModalLabel.value = operation === 'edit' ? 'Ubah Pengguna' : 'Tambah Pengguna';

    if (!modalPenggunaInstance) {
        const modalElement = document.getElementById('pengguna-modal');
        modalPenggunaInstance = new bootstrap.Modal(modalElement, configObject);
    }
    modalPenggunaInstance.show();
};

const handleChangeLokasi = (value) => {
    const temp = modalLokasiData
    if (value) {
        temp.value.key_id_alat = value.code
    } else {
        temp.value.key_id_alat = null
    }
    modalLokasiData.value = temp.value
};

const handleChangePengguna = (value) => {
    const temp = modalPenggunaData
    if (value) {
        temp.value.key_id_users = value.code
    } else {
        temp.value.key_id_users = null
    }
    modalPenggunaData.value = temp.value
};

const submitGroupHandler = async () => {
    if (!modalGroupData.value.is_aktif) {
        const { isConfirmed, value } = await Swal.fire({
            text: `Pengguna yang menggunakan ${modalGroupData.value.nm_group} tidak akan mendapat dashboard ini. Anda yakin akan menonakitfkan ?`,
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            inputPlaceholder: 'Alasan reject',
            showLoaderOnConfirm: true,
            confirmButtonClass: 'btn btn-primary w-xs me-2',
            cancelButtonClass: 'btn btn-danger w-xs',
            buttonsStyling: false,
        })

        if (!isConfirmed) {
            return
        }
    }

    const loader = loading.show({});
    try {
        const { data } = await Api.post(`api/master/group/save`, modalGroupData.value, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        if (data.success) {
            modalGroupInstance.hide();
            showToast(data.message, 'success');
            fetchData();
        } else {
            showToast(data.message, 'danger');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        showToast(error, 'danger');
    } finally {
        loader.hide()
    }
};

const submitLokasiHandler = async () => {
    if (isEmptyObject(modalLokasiData.value.key_id_alat) || modalLokasiData.value.key_id_alat == null) {
        Swal.fire({
            icon: "warning",
            text: "Harap isi lokasi",
        });

        return
    }
    const loader = loading.show({});
    try {
        const { data } = await Api.post(`api/master/group/save_d`, modalLokasiData.value, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        if (data.success) {
            modalLokasiInstance.hide();
            showToast(data.message, 'success');
            fetchData()
        } else {
            showToast(data.message, 'danger');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        showToast(error, 'danger');
    } finally {
        loader.hide()
    }
};

const submitPenggunaHandler = async () => {
    if (isEmptyObject(modalPenggunaData.value.key_id_users) || modalPenggunaData.value.key_id_users == null) {
        Swal.fire({
            icon: "warning",
            text: "Harap isi pengguna",
        });

        return
    }
    const loader = loading.show({});
    try {
        const { data } = await Api.post(`api/master/group/save_p`, modalPenggunaData.value, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        if (data.success) {
            modalPenggunaInstance.hide();
            showToast(data.message, 'success');
            fetchData()
        } else {
            showToast(data.message, 'danger');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        showToast(error, 'danger');
    } finally {
        loader.hide()
    }
};

const fetchData = async () => {
    const loader = loading.show({});
    try {
        const { data } = await Api.get('api/master/group', {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        tableGroupData.value = data.data;
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        loader.hide()
        setTimeout(() => {
            tableGroupInstance.columns.adjust().draw()
        }, 0);
    }
};

const showToast = (message, bg) => {
    toastEl.value.message = message;
    toastEl.value.bg = `text-bg-${bg}`;
    toast.value.show();

    setTimeout(() => {
        toast.value.hide();
    }, 3000);
};

const clickEventHandler = async (event) => {
    const targetEditGroup = event.target.closest('.btn-edit-group');
    if (targetEditGroup) {
        const id = targetEditGroup.getAttribute('data-id');
        editGroup(id);
    }

    const targetDeleteGroup = event.target.closest('.btn-remove-group');
    if (targetDeleteGroup) {
        const id = targetDeleteGroup.getAttribute('data-id');
        const { isConfirmed, value } = await Swal.fire({
            text: 'Anda yakin akan menghapus master group ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            inputPlaceholder: 'Alasan reject',
            showLoaderOnConfirm: true,
            confirmButtonClass: 'btn btn-primary w-xs me-2',
            cancelButtonClass: 'btn btn-danger w-xs',
            buttonsStyling: false,
        })

        if (isConfirmed) {
            const loader = loading.show({});
            try {
                const { data } = await Api.delete(`api/master/group/delete`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + localStorage.token
                    }, data: {
                        id
                    }
                });
                if (data.success) {
                    showToast(data.message, 'success');
                    fetchData();
                } else {
                    showToast(data.message, 'danger');
                }
            } catch (error) {
                console.error(error);
                showToast(error, 'danger');
            } finally {
                loader.hide()
            }
        }
    }

    const targetAddLokasi = event.target.closest('.add-lokasi-btn');
    if (targetAddLokasi) {
        const id = targetAddLokasi.getAttribute('data-id');
        createLokasi(id);
    }

    const targetEditLokasi = event.target.closest('.btn-edit-lokasi');
    if (targetEditLokasi) {
        const id = targetEditLokasi.getAttribute('data-id');
        const header_id = targetEditLokasi.getAttribute('data-id-header');
        editLokasi(id, header_id);
    }

    const targetDeletLokasi = event.target.closest('.btn-remove-lokasi');
    if (targetDeletLokasi) {
        const id = targetDeletLokasi.getAttribute('data-id');
        const { isConfirmed, value } = await Swal.fire({
            text: 'Anda yakin akan menghapus master lokasi ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            inputPlaceholder: 'Alasan reject',
            showLoaderOnConfirm: true,
            confirmButtonClass: 'btn btn-primary w-xs me-2',
            cancelButtonClass: 'btn btn-danger w-xs',
            buttonsStyling: false,
        })

        if (isConfirmed) {
            const loader = loading.show({});
            try {
                const { data } = await Api.delete(`api/master/group/delete_d`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + localStorage.token
                    }, data: {
                        id
                    }
                });
                if (data.success) {
                    showToast(data.message, 'success');
                    fetchData();
                } else {
                    showToast(data.message, 'danger');
                }
            } catch (error) {
                console.error(error);
                showToast(error, 'danger');
            } finally {
                loader.hide()
            }
        }
    }

    const targetSubGrid = event.target.closest('td.details-control');
    if (targetSubGrid) {

        console.log('subgrid clicked');
        
        const tr = event.target.closest('tr');
        const row = tableGroupInstance.row(tr)

        if (document.getElementById(`tr-${row.data().key_id}`)) {
            tr.classList.remove('shown')
            $(`#tr-${row.data().key_id}`).remove()
        } else {
            expandSubGrid(tr, row.data())
            tr.classList.add('shown')
        }
        setTimeout(() => {
            tableGroupInstance.columns.adjust()
        }, 0);
    }

    const targetAddPengguna = event.target.closest('.add-pengguna-btn');
    if (targetAddPengguna) {
        const id = targetAddPengguna.getAttribute('data-id');
        createPengguna(id);
    }

    const targetDeletePengguna = event.target.closest('.btn-remove-pengguna');
    if (targetDeletePengguna) {
        const id = targetDeletePengguna.getAttribute('data-id');
        const { isConfirmed, value } = await Swal.fire({
            text: 'Anda yakin akan menghapus master pengguna ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            inputPlaceholder: 'Alasan reject',
            showLoaderOnConfirm: true,
            confirmButtonClass: 'btn btn-primary w-xs me-2',
            cancelButtonClass: 'btn btn-danger w-xs',
            buttonsStyling: false,
        })

        if (isConfirmed) {
            const loader = loading.show({});
            try {
                const { data } = await Api.delete(`api/master/group/delete_p`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + localStorage.token
                    }, data: {
                        id
                    }
                });
                if (data.success) {
                    showToast(data.message, 'success');
                    fetchData();
                } else {
                    showToast(data.message, 'danger');
                }
            } catch (error) {
                console.error(error);
                showToast(error, 'danger');
            } finally {
                loader.hide()
            }
        }
    }

    const targetNavLink = event.target.closest('.nav-link');
    if (targetNavLink) {
        const id = targetNavLink.getAttribute('data-id-header');

        $(`table#datatable-detail-${id} > thead > tr`).css({ visibility: 'collapse' });
        $(`table#datatable-detail-2-${id} > thead > tr`).css({ visibility: 'collapse' });
        setTimeout(() => {
            $(`#datatable-detail-${id}`).DataTable().columns.adjust()
            $(`table#datatable-detail-${id} > thead > tr`).css({ visibility: 'collapse' });
            $(`#datatable-detail-2-${id}`).DataTable().columns.adjust()
            $(`table#datatable-detail-2-${id} > thead > tr`).css({ visibility: 'collapse' });
            tableGroupInstance.columns.adjust()
        }, 0);
    }
}

const expandSubGrid = async (tr, data) => {
    let subGridData = []
    let subGridData2 = []

    const loader = loading.show({});
    try {
        const response = await Api.get('api/master/group/d', {
            params: { header_id: data.key_id },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        subGridData = response.data.data

        const response2 = await Api.get('api/master/group/p', {
            params: { header_id: data.key_id },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        subGridData2 = response2.data.data
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide()
    }

    // tr.insertAdjacentHTML('afterend', `
    //     <tr id="tr-${data.key_id}">
    //         <td></td>
    //         <td colspan='5' style="max-width: 0px !important;">
    //             <div>
    //                 <button class="btn btn-primary mb-2 add-lokasi-btn" id="" data-id="${data.key_id}">Tambah Lokasi</button>
    //                 <table style="width: 100%; height: 100%;" id='datatable-detail-${data.key_id}' class='table table-bordered table-striped datatable-details'>
    //                 </table>
    //             </div>
    //             <div>
    //                 <button class="btn btn-primary mb-2 add-pengguna-btn" id="" data-id="${data.key_id}">Tambah Pengguna</button>
    //                 <table style="width: 100%; height: 100%;" id='datatable-detail-2-${data.key_id}' class='table table-bordered table-striped datatable-details-2'>
    //                 </table>
    //             </div>
    //         </td>
    //     </tr>
    // `);

    tr.insertAdjacentHTML('afterend', `
        <tr id="tr-${data.key_id}">
            <td></td>
            <td colspan='5' style="max-width: 0px !important;">
                <nav>
                    <div class="nav nav-tabs" id="nav-tab-${data.key_id}" role="tablist">
                        <button data-id-header="${data.key_id}" class="nav-link active" id="nav-lokasi-${data.key_id}-tab" data-bs-toggle="tab" data-bs-target="#nav-lokasi-${data.key_id}"
                            type="button" role="tab" aria-controls="nav-lokasi-${data.key_id}" aria-selected="true">Lokasi</button>
                        <button data-id-header="${data.key_id}" class="nav-link" id="nav-pengguna-${data.key_id}-tab" data-bs-toggle="tab" data-bs-target="#nav-pengguna-${data.key_id}"
                            type="button" role="tab" aria-controls="nav-pengguna-${data.key_id}" aria-selected="false">Pengguna</button>
                    </div>
                </nav>
                <div class="tab-content" id="nav-tabContent-${data.key_id}">
                    <div class="tab-pane fade show active" id="nav-lokasi-${data.key_id}" role="tabpanel" aria-labelledby="nav-lokasi-${data.key_id}-tab" tabindex="0">
                        <div class="mt-3">
                            <button class="btn btn-primary mb-2 add-lokasi-btn" id="" data-id="${data.key_id}">Tambah Lokasi</button>
                            <table style="width: 100%; height: 100%;" id='datatable-detail-${data.key_id}' class='table table-bordered table-striped datatable-details'>
                            </table>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="nav-pengguna-${data.key_id}" role="tabpanel" aria-labelledby="nav-pengguna-${data.key_id}-tab" tabindex="0">
                        <div class="mt-3">
                            <button class="btn btn-primary mb-2 add-pengguna-btn" id="" data-id="${data.key_id}">Tambah Pengguna</button>
                            <table style="width: 100%; height: 100%;" id='datatable-detail-2-${data.key_id}' class='table table-bordered table-striped datatable-details-2'>
                            </table>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    `);

    await $(`#datatable-detail-${data.key_id}`).dataTable({
        order: [],
        scrollX: true,
        data: subGridData,
        columns: [
            // { title: "ID", data: 'key_id', width: "20px", className: 'text-start' },
            { title: "No.", data: 'row_', width: "20px", className: 'text-start' },
            { title: "Lokasi", data: 'keterangan', className: 'text-start' },
            {
                title: 'Aksi',
                orderable: false,
                data: null,
                className: 'text-start',
                render: function (datax, type, row) {
                    console.log(row);
                    const disabled = false ? "disabled" : ""
                    const div = `<div class="d-flex">
                                <button class="btn btn-sm btn-outline-primary btn-edit-lokasi mr-2" ${disabled} title="Ubah Lokasi" data-id=${row.key_id} data-id-header=${data.key_id}><i class="operation-icon fas fa-pen"></i></button>
                                <button class="btn btn-sm btn-outline-danger btn-remove-lokasi" ${disabled} title="Hapus Lokasi" data-id=${row.key_id}><i class="operation-icon fas fa-trash-alt"></i></button>
                            </div>`;
                    return div;
                }
            },
        ],
        initComplete: function (settings, json) {
            $(`table#datatable-detail-${data.key_id} > thead > tr`).css({ visibility: 'collapse' });
        },
        drawCallback: function (settings) {
            $(`table#datatable-detail-${data.key_id} > thead > tr`).css({ visibility: 'collapse' });
            setTimeout(() => {
                $(`#datatable-detail-${data.key_id}`).DataTable().columns.adjust()
                $(`table#datatable-detail-${data.key_id} > thead > tr`).css({ visibility: 'collapse' });
                tableGroupInstance.columns.adjust()
            }, 0);

        }
    })

    await $(`#datatable-detail-2-${data.key_id}`).dataTable({
        order: [],
        scrollX: true,
        data: subGridData2,
        columns: [
            // { title: "ID", data: 'key_id', width: "20px", className: 'text-start' },
            { title: "No.", data: 'row_', width: "20px", className: 'text-start' },
            { title: "Name", data: 'name', className: 'text-start' },
            { title: "NIP", data: 'nip', className: 'text-start' },
            { title: "Unit Kerja", data: 'nm_unit_kerja', className: 'text-start' },
            {
                title: 'Aksi',
                orderable: false,
                data: null,
                className: 'text-start',
                render: function (data, type, row) {
                    const disabled = false ? "disabled" : ""
                    const div = `<div class="d-flex">
                                <button class="btn btn-sm btn-outline-danger btn-remove-pengguna" ${disabled} title="Hapus Pengguna" data-id=${row.key_id}><i class="operation-icon fas fa-trash-alt"></i></button>
                            </div>`;
                    return div;
                }
            },
        ],
        initComplete: function (settings, json) {
            $(`table#datatable-detail-2-${data.key_id} > thead > tr`).css({ visibility: 'collapse' });
        },
        drawCallback: function (settings) {
            $(`table#datatable-detail-2-${data.key_id} > thead > tr`).css({ visibility: 'collapse' });
            setTimeout(() => {
                $(`#datatable-detail-2-${data.key_id}`).DataTable().columns.adjust()
                $(`table#datatable-detail-2-${data.key_id} > thead > tr`).css({ visibility: 'collapse' });
                tableGroupInstance.columns.adjust()
            }, 0);

        }
    })
}

// const tabs = ['Lokasi', 'Pengguna']
// const currentTab = ref(tabs[0])
// const isMounted = ref(false)
// const showTab = (tab) => {
//     currentTab.value = tab
// }

// const handleTabShown = (tab) => {
//     console.log(`Tab ${tab} is shown`)
//     setTimeout(() => {
//         // tableLokasiInstance.columns.adjust().draw() 
//         // tableDeviceInstance.columns.adjust().draw()
//     }, 0);
// }

// watch(currentTab, (newTab) => {
//     if (isMounted.value) {
//         handleTabShown(newTab)
//     }
// })

onMounted(async () => {
    tableGroupInstance = tableGroup.value.dt;
    await fetchData();
    // event listeners
    document.removeEventListener('click', clickEventHandler);
    document.addEventListener('click', clickEventHandler);

    toast.value = new bootstrap.Toast(document.getElementById('toast'), { delay: 3000 });
});

onBeforeUnmount(() => {
    document.removeEventListener('click', clickEventHandler);
});
</script>

<template>
    <div class="toast-container position-fixed top-0 end-0 p-3">
        <div id="toast" :class="'toast align-items-center border-0 ' + toastEl.bg" role="alert" aria-live="assertive"
            aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    {{ toastEl.message }}
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                    aria-label="Close"></button>
            </div>
        </div>
    </div>

    <div id="group-modal" class="modal fade" aria-labelledby="group-modal-label" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ groupModalLabel }}</h5>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form method="post" id="group-form" @submit.prevent="submitGroupHandler">
                        <div class="row">
                            <input type="hidden" class="form-control" v-model="modalGroupData.key_id" required />
                            <div class="col-12 col-md-4">
                                <div class="mt-3 mt-md-0">
                                    <label class="form-label">Nama Group</label>
                                    <input type="text" class="form-control" v-model="modalGroupData.nm_group" required placeholder="Masukkan nama group">
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="mt-3 mt-md-0">
                                    <label class="form-label">Status Group</label>
                                    <v-select v-model="selectedStatusGroupValue" :options="selectGroupStatusOptions"
                                        :clearable="false" @update:modelValue="handleStatusGroupChange"
                                        required></v-select>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="mt-3 mt-md-0">
                                    <label class="form-label">Keterangan</label>
                                    <input type="text" class="form-control" v-model="modalGroupData.keterangan"
                                        required placeholder="Masukkan keterangan">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="submit" form="group-form" class="btn btn-success"
                        data-bs-dismiss="modal">Simpan</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
                </div>
            </div>
        </div>
    </div>

    <div id="lokasi-modal" class="modal fade" aria-labelledby="lokasi-modal-label" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ lokasiModalLabel }}</h5>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form method="post" id="lokasi-form" @submit.prevent="submitLokasiHandler">
                        <div class="row">
                            <input type="hidden" class="form-control" v-model="modalLokasiData.key_id" required />
                            <input type="hidden" class="form-control" v-model="modalLokasiData.key_id_header"
                                required />
                            <div class="col-12 col-md-12">
                                <div class="mt-3 mt-md-0">
                                    <label class="form-label">Lokasi</label>
                                    <v-select v-model="selectedLokasiValue" :options="selectLokasiOptions"
                                        :clearable="false" @update:modelValue="handleChangeLokasi"
                                        placeholder="Harap pilih lokasi" required></v-select>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="submit" form="lokasi-form" class="btn btn-success"
                        data-bs-dismiss="modal">Simpan</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
                </div>
            </div>
        </div>
    </div>

    <div id="pengguna-modal" class="modal fade" aria-labelledby="pengguna-modal-label" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ penggunaModalLabel }}</h5>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form method="post" id="pengguna-form" @submit.prevent="submitPenggunaHandler">
                        <div class="row">
                            <input type="hidden" class="form-control" v-model="modalPenggunaData.key_id" required />
                            <input type="hidden" class="form-control" v-model="modalPenggunaData.key_id_grp_mon"
                                required />
                            <div class="col-12 col-md-12">
                                <div class="mt-3 mt-md-0">
                                    <label class="form-label">Pengguna</label>
                                    <v-select v-model="selectedPenggunaValue" :options="selectPenggunaOptions"
                                        :clearable="false" @update:modelValue="handleChangePengguna"
                                        placeholder="Harap pilih pengguna" required></v-select>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="submit" form="pengguna-form" class="btn btn-success"
                        data-bs-dismiss="modal">Simpan</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="card">
            <div class="card-header">
                <h5 class="mb-0">Group Monitoring</h5>
            </div>
            <div class="card-body">
                <button class="btn btn-primary mb-2" @click="createGroup">Tambah Group</button>
                <DataTable :columns="columns" :data="tableGroupData" :options="dataTableOptions" ref="tableGroup"
                    class="table table-bordered dataTableGroupClass" width="100%"></DataTable>
            </div>
        </div>
    </div>
</template>

<style>
@import 'bootstrap';
@import 'datatables.net-bs5';
</style>

<style scoped>
.select2-dropdown {
    z-index: 99999 !important;
    /* Ensure this is higher than the modal z-index */
}

.xxx {
    position: relative;
    /* Ensure the parent has positioning */
}
</style>

<style>
table.datatable-details>thead>tr>th,
table.datatable-details-2>thead>tr>th {
    padding: 8px 30px 8px 8px !important;
    min-height: 41.2px !important;
}

div.dt-scroll-body>table.datatable-details>thead,
div.dt-scroll-body>table.datatable-details-2>thead {
    /* display: none !important; */
    /* height: 1px !important; */
}

div.dt-scroll-body>table.dataTableGroupClass {
    overflow: hidden !important;
}
</style>