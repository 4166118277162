<script setup>
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import { useLoading } from 'vue-loading-overlay'
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';
// import 'datatables.net-responsive';
import * as Popper from "@popperjs/core"
import Api from '../../services/api';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Swal from 'sweetalert2';

import treeview from 'vue3-treeview';
import 'vue3-treeview/dist/style.css';

const tableData = ref([]);
const modalData = ref({ id: '', name: '' });
let modalInstance = null;
let modalInstanceRoleMenu = null;
const loading = useLoading({});
const configObject = { keyboard: false, backdrop: 'static' }
const roleModalLabel = ref('')
const toast = ref(null);
const toastEl = ref({});
const dataTableOptions = ref({
    order: [],
    scrollX: true
});
const selectedNodes = ref([]);
const roleMenuId = ref('');
const config = ref({
    roots: [
        // "id1", "id2", "id3"
    ],
    keyboardNavigation: false,
    dragAndDrop: false,
    checkboxes: true,
    checkMode: 0,
    editable: false,
    disabled: false,
    padding: 25,
});

let table1Instance;
const table1 = ref();

const nodes = ref({
    // id1: {
    //     text: "text1",
    //     children: ["id11", "id12"],
    // },
    // id11: {
    //     text: "text11",
    //     state: {
    //         checked: true
    //     }
    // },
    // id12: {
    //     text: "text12",
    // },
    // id2: {
    //     text: "text2",
    // },
});

DataTable.use(DataTablesCore);

const columns = [
    // { title: "ID", data: 'id', width: "20px" },
    {
        title: "No.",
        data: 'id',
        width: "20px",
        render: function (data, type, row, meta) {
            return meta.row + meta.settings._iDisplayStart + 1;
        }
    },
    { title: "Nama", data: 'name' },
    {
        title: 'Aksi',
        orderable: false,
        data: null,
        render: function (data, type, row) {
            // const disabled = row.id != 2 ? "disabled" : "";
            const disabled = [1, 11].includes(row.id) ? "disabled" : "";
            console.log(disabled);
            const div = `<div class="d-flex">
                            <button class="btn btn-sm btn-outline-success btn-menu-role" title="Ubah Role Menu" data-id=${row.id}><i class="operation-icon fas fa-bars"></i></button>
                            <button class="btn btn-sm btn-outline-primary btn-edit-role mx-2" ${disabled} title="Ubah Role" data-id=${row.id} data-name="${row.name}"><i class="operation-icon fas fa-pencil-alt"></i></button>
                            <button class="btn btn-sm btn-outline-danger btn-remove-role" ${disabled} title="Hapus Role" data-id=${row.id}><i class="operation-icon fas fa-trash-alt"></i></button>
                        </div>`;
            return div;
        }
    },
];

const fetchData = async () => {
    const loader = loading.show({});
    try {
        const { data } = await Api.get('api/role', {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        tableData.value = data.data;
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        loader.hide()
        setTimeout(() => {
            table1Instance.columns.adjust().draw()
        }, 0);
    }
};

const showModal = (id, name, operation) => {
    modalData.value.id = id;
    modalData.value.name = name;
    roleModalLabel.value = operation === 'edit' ? 'Ubah Role' : 'Tambah Role';

    if (!modalInstance) {
        const modalElement = document.getElementById('role-modal');
        modalInstance = new bootstrap.Modal(modalElement, configObject);
    }
    modalInstance.show();
};

const showModalRoleMenu = async (id) => {
    roleMenuId.value = id
    await fetchMenuTree(id)

    if (!modalInstanceRoleMenu) {
        const modalElement = document.getElementById('role-menu-modal');
        modalInstanceRoleMenu = new bootstrap.Modal(modalElement, configObject);
    }
    modalInstanceRoleMenu.show();
};

const handleSubmit = async () => {
    const loader = loading.show({});
    try {
        // v1
        // const formData = new FormData(document.getElementById('role-form'));
        // const role_id = formData.get('role_id');
        // const role_name = formData.get('role_name');

        // v2
        // console.log(modalData.value);
        const new_ = modalData.value.name;
        // console.log(new_);

        const { data } = await Api.post(`api/role/save`, modalData.value, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        // console.log(data.message);
        if (data.success) {
            modalInstance.hide();
            showToast(data.message, 'success');
            fetchData();
        } else {
            showToast(data.message, 'danger');
        }

    } catch (error) {
        console.error('Error submitting form:', error);
        showToast(error, 'danger');
    } finally {
        loader.hide()
    }
};

const createRole = () => {
    modalData.value.id = '';
    modalData.value.name = '';
    showModal('', '', 'create')
}

const showToast = (message, bg) => {
    toastEl.value.message = message;
    toastEl.value.bg = `text-bg-${bg}`;
    toast.value.show();

    setTimeout(() => {
        toast.value.hide();
    }, 3000);
};

const fetchMenuTree = async (id) => {
    const loader = loading.show({});
    await menuTreeInit();
    try {
        const { data } = await Api.get('api/menutree', {
            params: {
                'root': 1,
                'role_id': id
            },
            headers: {
                "Accept": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        nodes.value = data.data.nodes
        config.value.roots = data.data.roots
        selectedNodes.value = data.role_menu_ids
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide();
    }
}

const menuTreeInit = async (id) => {
    // const loader = loading.show({});
    try {
        const { data } = await Api.get('api/menutree', {
            params: {
                'root': 1,
            },
            headers: {
                "Accept": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        nodes.value = data.data.nodes
        config.value.roots = data.data.roots
        selectedNodes.value = []
    } catch (error) {
        console.error(error);
    } finally {
        // loader.hide();
    }
}

const submitRoleMenu = async () => {
    // console.log(selectedNodes.value);
    // console.log(nodes.value);

    const newArray = selectedNodes.value.filter(item => item != 0);
    const loader = loading.show({});
    try {
        const { data } = await Api.post(`api/role/save_role_menu`,
            {
                'menu_id': newArray,
                'role_id': roleMenuId.value
            }, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        // console.log(data.message);
        if (data.success) {
            modalInstanceRoleMenu.hide();
            showToast(data.message, 'success');
            fetchData();
        } else {
            showToast(data.message, 'danger');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        showToast(error, 'danger');
    } finally {
        loader.hide()
    }
}

const recursiveNodeCheckParent = (val, newArr) => {
    if (val.parent == null) {
        // nothing
    } else {
        newArr.push(nodes.value[val.parent].key.toString())

        if (nodes.value[val.parent].parent == null) {
            // nothing
        } else {
            recursiveNodeCheckParent(nodes.value[val.parent], newArr)
        }
    }
}

const recursiveNodeCheckChildren = (val, newArr) => {
    if (val.children.length == 0) {
        // nothing
    } else {
        val.children.map(item => {
            newArr.push(nodes.value[item].key.toString())

            if (nodes.value[item].children.length == 0) {
                // nothing
            } else {
                nodes.value[item].children.map(item2 => {
                    newArr.push(nodes.value[item2].key.toString())
                    recursiveNodeCheckChildren(nodes.value[item2], newArr)
                })
            }
        })
    }
}

const nodeCheck = (val) => {
    const newArr = [...selectedNodes.value]
    newArr.push(val.key.toString())
    recursiveNodeCheckParent(val, newArr)
    recursiveNodeCheckChildren(val, newArr)
    selectedNodes.value = [...new Set(newArr)]
}

const recursiveNodeUncheckParent = (val, newArr) => {
    if (val.parent == null) {
        // nothing
    } else {
        const siblings = nodes.value[val.parent].children;
        const allUnchecked = siblings.every(sibling => !newArr.includes(nodes.value[sibling].key.toString()));

        if (allUnchecked) {
            const parentKey = nodes.value[val.parent].key.toString();
            const index = newArr.indexOf(parentKey);
            if (index > -1) {
                newArr.splice(index, 1);
            }

            recursiveNodeUncheckParent(nodes.value[val.parent], newArr);
        }
    }
}

const recursiveNodeUncheckChildren = (val, newArr) => {
    if (val.children.length == 0) {
        // nothing
    } else {
        val.children.map(item => {
            const itemKey = nodes.value[item].key.toString();
            const index = newArr.indexOf(itemKey);
            if (index > -1) {
                newArr.splice(index, 1);
            }

            if (nodes.value[item].children.length == 0) {
                // nothing
            } else {
                nodes.value[item].children.map(itemy => {
                    const itemKeyy = nodes.value[itemy].key.toString();
                    const indexx = newArr.indexOf(itemKeyy);
                    if (indexx > -1) {
                        newArr.splice(indexx, 1);
                    }
                    recursiveNodeUncheckChildren(nodes.value[itemy], newArr)
                })
            }
        })
    }
}

const nodeUncheck = (val) => {
    let newArr = [...selectedNodes.value]
    const index = newArr.indexOf(val.key.toString());
    if (index > -1) {
        newArr.splice(index, 1);
    }
    recursiveNodeUncheckParent(val, newArr)
    recursiveNodeUncheckChildren(val, newArr)
    selectedNodes.value = [...new Set(newArr)]
}

const clickEventHandler = async event => {
    const targetEdit = event.target.closest('.btn-edit-role');
    if (targetEdit) {
        const id = targetEdit.getAttribute('data-id');
        const name = targetEdit.getAttribute('data-name');
        showModal(id, name, 'edit');
    }

    const targetEditRoleMenu = event.target.closest('.btn-menu-role');
    if (targetEditRoleMenu) {
        const id = targetEditRoleMenu.getAttribute('data-id');
        showModalRoleMenu(id);
    }

    const targetEditRoleMenuSubmit = event.target.closest('#submit-role-menu');
    if (targetEditRoleMenuSubmit) {
        submitRoleMenu();
    }

    const targetDelete = event.target.closest('.btn-remove-role');
    if (targetDelete) {
        const id = targetDelete.getAttribute('data-id');
        const { isConfirmed, value } = await Swal.fire({
            text: 'Anda yakin akan menghapus role ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            inputPlaceholder: 'Alasan reject',
            showLoaderOnConfirm: true,
            confirmButtonClass: 'btn btn-primary w-xs me-2',
            cancelButtonClass: 'btn btn-danger w-xs',
            buttonsStyling: false,
        })

        if (isConfirmed) {
            const loader = loading.show({});
            try {
                const { data } = await Api.delete(`api/role/delete`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + localStorage.token
                    }, data: {
                        id
                    }
                });
                if (data.success) {
                    showToast(data.message, 'success');
                    fetchData();
                } else {
                    showToast(data.message, 'danger');
                }
            } catch (error) {
                console.error(error);
                showToast(error, 'danger');
            } finally {
                loader.hide()
            }
        }
    }
}

onMounted(async () => {
    table1Instance = table1.value.dt;
    await fetchData();

    // event listeners

    // v1
    // document.addEventListener('click', async (event) => {
    // });

    // v2
    document.removeEventListener('click', clickEventHandler);
    document.addEventListener('click', clickEventHandler);

    toast.value = new bootstrap.Toast(document.getElementById('toast'), { delay: 3000 });
});

onBeforeUnmount(() => {
    document.removeEventListener('click', clickEventHandler);
});
</script>

<template>
    <div class="toast-container position-fixed top-0 end-0 p-3">
        <div id="toast" :class="'toast align-items-center border-0 ' + toastEl.bg" role="alert" aria-live="assertive"
            aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    {{ toastEl.message }}
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                    aria-label="Close"></button>
            </div>
        </div>
    </div>

    <div id="role-modal" class="modal fade" tabindex="-1" aria-labelledby="role-modal-label" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="role-modal-label">{{ roleModalLabel }}</h5>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form method="post" id="role-form" @submit.prevent="handleSubmit">
                        <input type="hidden" name="role_id" class="form-control" v-model="modalData.id" required />
                        <div class="mb-3">
                            <label for="role_name" class="form-label">Nama</label>
                            <input type="text" name="role_name" class="form-control" v-model="modalData.name" required placeholder="Masukkan nama role">
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="submit" form="role-form" class="btn btn-success"
                        data-bs-dismiss="modal">Simpan</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
                </div>
            </div>
        </div>
    </div>

    <div id="role-menu-modal" class="modal fade" tabindex="-1" aria-labelledby="role-menu-modal-label"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Role Menu</h5>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div id="target-tree">
                        <treeview :nodes="nodes" :config="config" selectable @nodeChecked="nodeCheck"
                            @nodeUnchecked="nodeUncheck"></treeview>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" id="submit-role-menu" class="btn btn-success"
                        data-bs-dismiss="modal">Simpan</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="card">
            <div class="card-header">
                <h5 class="mb-0">Role</h5>
            </div>
            <div class="card-body">
                <button class="btn btn-primary mb-2" @click="createRole">Tambah Role</button>
                <DataTable :columns="columns" :data="tableData" :options="dataTableOptions" ref="table1"
                    class="table table-bordered table-striped" width="100%"></DataTable>
                <!-- <table id="myDataTable" class="table table-bordered table-striped"></table> -->
            </div>
        </div>
    </div>
</template>

<style>
@import 'bootstrap';
@import 'datatables.net-bs5';
</style>
