<template>
	<router-view />
</template>

<style>
td.details-control, td.details-control-2 {
	background: url('~@/assets/button/details_open.png') no-repeat center center !important;
	cursor: pointer !important;
	padding: 20px !important;
}

tr.shown td.details-control, tr.shown td.details-control-2 {
	background: url('~@/assets/button/details_close.png') no-repeat center center !important;
	cursor: pointer !important;
	padding: 20px !important;
}
</style>
