import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//import pinia
import { createPinia } from 'pinia'

import ContentHeader from './components/ContentHeader.vue'
import Content from './components/Content.vue'

// sweetalert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// easydatatable
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import VueLazyload from 'vue-lazyload'

// Load Loading 
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

// treeview
import Tree from "vue3-treeview";
import "vue3-treeview/dist/style.css";
import vSelect from 'vue-select'

//initialize pinia
const pinia = createPinia()

// datepicker
import Datepicker from 'vue3-datepicker'

// init websocket (nonaktifkan dulu krn laravel-websocket blm bisa up di docker)
import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')

// This assumes you have already saved your bearer token in your browsers local storage
// const token = localStorage.getItem('user-token')

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  wsHost: process.env.VUE_APP_PUSHER_APP_HOST,
  // authEndpoint: 'http://api.somedomain.com/broadcasting/auth',
  encrypted: true,
  forceTLS: false,
  // wsPort: process.env.VUE_APP_PUSHER_APP_PORT,
  // wssPort: process.env.VUE_APP_PUSHER_APP_PORT,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
  // enabledTransports: ['ws'],
  // auth: {
  //   headers: {
  //     authorization: 'Bearer ' + token,
  //   }
  // }
})

const app =  createApp(App)

app.component('content-header', ContentHeader).use(LoadingPlugin)
.component('content', Content)
.component('EasyDataTable', Vue3EasyDataTable)
.component('Tree', Tree)
.component('v-select', vSelect)
.component('Datepicker', Datepicker)
.use(VueLazyload)
.use(VueSweetalert2)
.use(store).use(pinia).use(router).mount('#app')
