<script setup>
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import { useLoading } from 'vue-loading-overlay'
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';
// import 'datatables.net-responsive';
import * as Popper from "@popperjs/core"
import Api from '../../services/api';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Swal from 'sweetalert2';

import treeview from 'vue3-treeview';
import 'vue3-treeview/dist/style.css';

import moment from 'moment';
import 'moment/locale/id';

const tableData = ref([]);
const loading = useLoading({});
const toast = ref(null);
const toastEl = ref({});
const dataTableOptions = ref({
    order: [],
    scrollX: true
});

let table1Instance;
const table1 = ref();

DataTable.use(DataTablesCore);

const columns = [
    {
        title: "No.",
        data: 'key_id',
        width: "20px",
        render: function (data, type, row, meta) {
            return meta.row + meta.settings._iDisplayStart + 1;
        }
    },
    // { title: "Nama", data: 'name' },
    {
        title: 'Periode Awal',
        orderable: true,
        data: null,
        render: function (data, type, row) {
            const text = moment(new Date(row.start_delete)).format('DD MMMM YYYY')
            return text
        }
    },
    {
        title: 'Periode Akhir',
        orderable: true,
        data: null,
        render: function (data, type, row) {
            const text = moment(new Date(row.end_delete)).format('DD MMMM YYYY')
            return text
        }
    },
    {
        title: 'Waktu Hapus',
        orderable: true,
        data: null,
        render: function (data, type, row) {
            const text = moment(new Date(row.end_delete)).format('DD MMMM YYYY')
            return text
        }
    },
    {
        title: 'Dilakukan oleh',
        orderable: true,
        data: null,
        render: function (data, type, row) {
            const text = row.delete_by == 0 ? 'Administrator' : row.delete_by == 1 ? 'System' : row.delete_by == 2 ? 'Reset by Administrator' : row.delete_by == 3 ? 'Reset by Administrator (tanpa backup)' : 'unknown'
            return text
        }
    },
    {
        title: 'Download File',
        orderable: false,
        data: null,
        width: '160px',
        render: function (data, type, row) {
            const disabled = false ? "disabled" : ""
            const btn = row.path_file_real ? `<button class="btn btn-sm btn-outline-primary btn-download-backup" ${disabled} title="Unduh backup" data-filename="${row.file_name}" data-url="${row.path_file_real}" data-id=${row.key_id}><i class="operation-icon fas fa-file-csv"></i></button>` : ''
            const div = `<div class="d-flex">
                                ${btn}
                            </div>`;
            return div;
        }
    },
];

const fetchData = async () => {
    const loader = loading.show({});
    try {
        const { data } = await Api.get('api/logger_delete_history/getTableData', {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        tableData.value = data.data;
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        loader.hide()
        setTimeout(() => {
            table1Instance.columns.adjust().draw()
        }, 0);
    }
};

const showToast = (message, bg) => {
    toastEl.value.message = message;
    toastEl.value.bg = `text-bg-${bg}`;
    toast.value.show();

    setTimeout(() => {
        toast.value.hide();
    }, 3000);
};

const clickEventHandler = async event => {
    const targetDownloadBackup = event.target.closest('.btn-download-backup');
    if (targetDownloadBackup) {
        const id = targetDownloadBackup.getAttribute('data-id');
        const url = targetDownloadBackup.getAttribute('data-url');
        const filename = targetDownloadBackup.getAttribute('data-filename');
        // console.log({id, url});
        const loader = loading.show({});
        try {
            const response = await Api.get(url, {
                responseType: 'blob',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + localStorage.token
                },
            });

            setTimeout(() => {
                const blob = new Blob([response.data], { type: 'text/csv' });
                const link = document.createElement('a');
                const url = window.URL.createObjectURL(blob);
                link.href = url;
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                showToast('Backup downloaded', 'success');
            }, 0);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            loader.hide()
        }
    }
}

onMounted(async () => {
    table1Instance = table1.value.dt;
    await fetchData();

    document.removeEventListener('click', clickEventHandler);
    document.addEventListener('click', clickEventHandler);

    toast.value = new bootstrap.Toast(document.getElementById('toast'), { delay: 3000 });
});

onBeforeUnmount(() => {
    document.removeEventListener('click', clickEventHandler);
});
</script>

<template>
    <div class="toast-container position-fixed top-0 end-0 p-3">
        <div id="toast" :class="'toast align-items-center border-0 ' + toastEl.bg" role="alert" aria-live="assertive"
            aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    {{ toastEl.message }}
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                    aria-label="Close"></button>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="card">
            <div class="card-header">
                <h5 class="mb-0">Logger Hapus History</h5>
            </div>
            <div class="card-body">
                <DataTable :columns="columns" :data="tableData" :options="dataTableOptions" ref="table1"
                    class="table table-bordered table-striped" width="100%"></DataTable>
                <!-- <table id="myDataTable" class="table table-bordered table-striped"></table> -->
            </div>
        </div>
    </div>
</template>

<style>
@import 'bootstrap';
@import 'datatables.net-bs5';
</style>
