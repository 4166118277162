<script>
//import services api
import Api from '../../services/api'
import { reactive, onMounted, ref } from 'vue'
// import { LoadingPlugin } from 'vue-loading-overlay'

export default {
  data () {
    return {
      modeBool: false,
      config: {
        roots: ["id1"],
        keyboardNavigation: false,
        dragAndDrop: false,
        // checkboxes: true,
        editable: false,
        disabled: false,
        padding: 25,
        // checkMode: true,
      },
      nodes: {
        id1: {
          text: "text1",
          children: ["id11", "id12", "id13"],
        },
        id11: {
          text: "text11",
          children: ["id111", "id112", "id113"],
        },
        id111: {
          text: "id111",
        },
        id112: {
          text: "id112",
        },
        id113: {
          text: "id113",
        },
        id12: {
          text: "text12",
          children: [],
        },
        id13: {
          text: "text13",
          children: ["id131", "id132"],
        },
        id131: {
          text: "text131",
        },
        id132: {
          text: "text132",
        },
        id2: {
          text: "text2",
          children: ["id21", "id22"],
        },
        id21: {
          text: "text21",
          children: ["id211", "id212"],
        },
        id211: {
          text: "id211",
        },
        id212: {
          text: "id212",
        },
        id22: {
          text: "text12",
          children: [],
        },
        id3: {
          text: "text3",
        },
      },
      items: [
        {
          name: 'Root',
          id: 0,
          children: [
            {
              name: 'Beranda',
              id: 1,
              children: [],
              expanded: false,
              route: '/notfound',
              sequence: 1,
            },
            {
              name: 'Pengaturan',
              id: 2,
              children: [
                {
                  name: 'Menu',
                  id: 3,
                  children: [],
                  expanded: false,
                  route: '/notfound',
                  sequence: 1,
                },
                {
                  name: 'Role',
                  id: 4,
                  children: [],
                  expanded: false,
                  route: '/notfound',
                  sequence: 2,
                },
              ],
              expanded: false,
              route: '/notfound',
              sequence: 2,
            },
          ],
          expanded: false,
          route: '/notfound',
          sequence: 1,
        }
      ],
      loading: false,
      fullPage: true,
      selectedNode: null,
      showForm: false,
      onControlForm: false,
      parentname: '',
      titleform: '',
      dataForm: {
        id: '',
        name: '',
        route: '',
        parent_id: '',
        icon: '',
        sequence: '',
        is_active: '',
        created_by: '',
        updated_by: '',
      },
    };
  },
  methods: {
    async fetchMenuTree() {
      this.loading = true;
      const loader = this.$loading.show();
      try {
        const {data} = await Api.get('api/menutree?root=1', {
          headers: {
            "Accept": "application/json",
            'Authorization': 'Bearer '+localStorage.token
          },
        });
        console.log(data);
        // this.menus = data.data;
        this.config.roots = data.data.roots
        this.nodes = data.data.nodes
        this.selectedNode = data.data.nodes.id0
        // console.log('root: ', data.data.nodes.id0)
        // this.dataForm.parent_id = this.selectedNode.id
        // this.dataForm.name
        // this.dataForm.route
      } catch (error) {
        // console.log(error);
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: error.code + ': ' + error.message,
        });
      } finally {
        this.loading = false;
        loader.hide();
      }
    },
    onItemSelected(item) {
      console.log('itemselected: ', item);
    },
    nodeFocus(node) {
      console.log('node-focus: ', node);
      this.selectedNode = node
      if (this.showForm) {
        this.dataForm.parent_id = this.selectedNode.key
        this.parentname = this.selectedNode.text
      }
    },
    nodeToggle(node) {
      console.log('node-toggle: ', node)
    },
    tambahMenu() {
      this.titleform = 'Tambah Menu'
      this.dataForm.sequence = ''
      this.dataForm.name = ''
      this.dataForm.route = ''
      this.dataForm.icon = ''
      this.showForm = true
      this.dataForm.parent_id = this.selectedNode.key
      this.parentname = this.selectedNode.text
      this.dataForm.is_active = true
      this.dataForm.created_by = JSON.parse(localStorage.user).id;
      this.dataForm.updated_by = null;
    },
    cancelMenu() {
      this.showForm = false
    },
    async save() {
      console.log('data: ', this.dataForm)
      // this.$swal('Simpan..')
      const loader = this.$loading.show();
      try {
        let datarespon = null
        if (this.titleform == 'Tambah Menu') {
          const {data} = await Api.post('api/menu', this.dataForm, {
            headers: {
              "Accept": "application/json",
              'Authorization': 'Bearer '+localStorage.token
            },
          });
          datarespon = data
        } else {
          const {data} = await Api.put('api/menu/'+this.dataForm.id, this.dataForm, {
            headers: {
              "Accept": "application/json",
              'Authorization': 'Bearer '+localStorage.token
            },
          });
          datarespon = data
        }
        console.log(datarespon);
        this.showForm = false
        this.dataForm.sequence = ''
        this.dataForm.name = ''
        this.dataForm.route = ''
        this.dataForm.icon = ''
        this.fetchMenuTree()
      } catch (error) {
        // console.log(error);
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: error.code + ': ' + error.message,
        });
      } finally {
        this.loading = false;
        loader.hide();
      }
    },
    konfirmDelete() {
      this.$swal({
        text: "Anda yakin akan menghapus "+this.selectedNode.text+"?",
        icon: "question",
        // showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Ya",
        // denyButtonText: `Don't save`
        cancelButtonText: 'Batal',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          // this.$swal("Saved!", "", "success");
          this.delete()
        }
      })
    },
    async delete() {
      // if (this.selectedNode) {

      // }
      const loader = this.$loading.show();
      try {
        const {data} = await Api.delete('api/menu/'+this.selectedNode.key, {
          headers: {
            "Accept": "application/json",
            'Authorization': 'Bearer '+localStorage.token
          },
        });
        console.log(data);
        this.showForm = false
        this.dataForm.sequence = ''
        this.dataForm.name = ''
        this.dataForm.route = ''
        this.dataForm.icon = ''
        this.fetchMenuTree()
      } catch (error) {
        // console.log(error);
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: error.code + ': ' + error.message,
        });
      } finally {
        this.loading = false;
        loader.hide();
      }
    },
    editMenu() {
      if (this.selectedNode.id == 'id0') {
        this.$swal({
          icon: 'error',
          title: "Oops...",
          text: 'Silakan pilih node lebih dulu',
        })
        return
      }
      console.log('lanjut: ', JSON.parse(localStorage.user));
      this.titleform = 'Edit Menu'
      this.dataForm.id = this.selectedNode.key
      this.dataForm.name = this.selectedNode.text
      this.dataForm.route = this.selectedNode.route
      this.dataForm.icon = this.selectedNode.icon
      this.dataForm.sequence = this.selectedNode.sequence
      this.dataForm.is_active = this.selectedNode.is_active
      this.parentname = this.selectedNode.parentName
      this.dataForm.updated_by = JSON.parse(localStorage.user).id;
      this.dataForm.created_by = null;
      this.showForm = true;
    },
  },
  mounted () {
    this.fetchMenuTree()
  }
}
</script>

<template>
    <div class="container">
        <div class="card">
            <div class="card-header">
                <h5 class="mb-0">Menu</h5>
            </div>
            <div class="card-body">
              <div class="row">
                  <div class="col-md-7">
                    <div class="card">
                      <div class="card-body">
                          <Tree 
                              :config="config" 
                              :nodes="nodes"
                              @nodeFocus="nodeFocus"
                              @nodeToggle="nodeToggle"
                          />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="card">
                      <div class="card-header">
                        <h3 class="card-title" v-if="showForm">{{titleform}}</h3>
                        <div v-if="!showForm">
                          <button class="btn btn-primary" @click="tambahMenu">Tambah Menu</button>&nbsp
                          <button class="btn btn-warning" @click="editMenu">Edit Menu</button>&nbsp
                          <button class="btn btn-danger" @click="konfirmDelete">Hapus Menu</button>&nbsp
                        </div>
                      </div>
                      <form @submit.prevent="save" class="form-horizontal" v-if="showForm">
                        <div class="card-body">
                          <input type="hidden" class="form-control" v-model="dataForm.parent_id">
                          <div class="form-group row">
                            <label for="parentid" class="col-sm-4 col-form-label">Menu Induk</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" v-model="parentname" disabled>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="parentid" class="col-sm-4 col-form-label">Nama Menu</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" v-model="dataForm.name">
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="parentid" class="col-sm-4 col-form-label">URL Menu</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" v-model="dataForm.route">
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="parentid" class="col-sm-4 col-form-label">Icon</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" v-model="dataForm.icon">
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="parentid" class="col-sm-4 col-form-label">No Urut</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" v-model="dataForm.sequence">
                            </div>
                          </div>
                          <div class="form-group row">
                            <div class="offset-sm-4 col-sm-8">
                              <div class="form-check">
                                <input type="checkbox" class="form-check-input" v-model="dataForm.is_active">
                                <label class="form-check-label" for="exampleCheck2">Aktif</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer">
                          <button type="submit" class="btn btn-primary">Simpan</button>&nbsp
                          <button type="button" class="btn btn-outline-secondary" @click="cancelMenu">Batal</button>&nbsp
                        </div>
                      </form>
                    </div>
                  </div>
              </div>
            </div>
        </div>
    </div>
</template>