<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useLoading } from 'vue-loading-overlay'
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';
import * as Popper from "@popperjs/core"
import Api from '../../services/api';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Swal from 'sweetalert2';

import { insert_rows } from '../../utils/customXlsxUtils'

import 'vue-select/dist/vue-select.css';
import $ from 'jquery';

import moment from 'moment';
import Datepicker from 'vue3-datepicker'
// import * as XLSX from 'xlsx';
import * as XLSX from 'xlsx-js-style';

const tableData = ref([]);
const tableDataD1 = ref([]);
const tableDataD2 = ref([]);
const loading = useLoading({});
const configObject = { keyboard: false, backdrop: 'static' }
const toast = ref(null);
const toastEl = ref({});
const baseBackend = ref('')
const dataTableOptions = ref({
    lengthMenu: [
        [10, 25, 50, 100, -1],
        [10, 25, 50, 100, 'All'],
    ],
    pageLength: -1,
    searching: false,
    order: [],
    scrollX: true,
    initComplete: function () {
        // 
    },
    drawCallback: function (settings) {
        // 
        if (table1Instance) {
            gridTableProp.value.current_page = table1Instance.page() + 1
            gridTableProp.value.page_length = table1Instance.page.len()
        }
        // 
    },
});

const gridData = ref({ lokasi: '', periode_awal: '', periode_akhir: '', speed_type: '' });
const gridTableProp = ref({ current_page: 0, page_length: 0 })
const selectedLokasiValue = ref(null);
const selectLokasiOptions = ref([]);
const selectedSpeedTypeValue = ref({ code: 'knot', label: 'Knots' });
const selectSpeedTypeOptions = ref([
    { code: 'knot', label: 'Knots' },
    { code: 'kmph', label: 'km/jam' }
]);
const selectedPeriodeAwal = ref(new Date())
const selectedPeriodeAkhir = ref(new Date())
const inputPeriodeAwalRef = ref(null)
const inputPeriodeAkhirRef = ref(null)
const periodeFormat = 'dd-MM-yyyy'
const periodeAwalLimit1 = ref(new Date());
const periodeAkhirLimit1 = ref(new Date());
const periodeAwalLimit2 = ref(new Date());
const periodeAkhirLimit2 = ref(new Date());

let table1Instance;
const table1 = ref();

DataTable.use(DataTablesCore);

const columns = [
    {
        className: 'details-control text-center',
        data: null,
        defaultContent: '',
        width: '20px',
        title: "#",
        orderable: false
    },
    // { title: "No.", data: 'row_', width: "20px" },
    { title: "Waktu", data: 'conv_waktu' },
];

const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

const fetchData = async () => {
    const loader = loading.show({});
    try {
        const { data } = await Api.get('api/report/average', {
            params: { ...gridData.value, ...gridTableProp.value },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        tableData.value = data.data;
        tableDataD1.value = data.data_d1;
        tableDataD2.value = data.data_d2;
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        loader.hide()
        setTimeout(() => {
            table1Instance.columns.adjust().draw()
            //
            const elementsToAddShown = document.querySelectorAll('tr > td.details-control');
            elementsToAddShown.forEach(element => {
                const parentTR = element.closest('tr');
                const parentRow = table1Instance.row(parentTR)
                if (parentTR) {
                    expandSubGrid(parentTR, parentRow.data())
                    parentTR.classList.add('shown');
                }
            });

            const elementsToAddShown2 = document.querySelectorAll('tr > td.details-control-2');
            elementsToAddShown2.forEach(element => {
                const parentTR = element.closest('tr');
                const parentId = element.parentElement.parentElement.parentElement.id
                const parentRow = $(`#${parentId}`).dataTable().api().row(parentTR);
                if (parentTR) {
                    expandSubGrid2(parentTR, parentRow.data())
                    parentTR.classList.add('shown');
                }
            });
            //
        }, 0);
    }
};

const fetchDataSelect = async () => {
    const loader = loading.show({});
    try {
        const user_id = JSON.parse(localStorage.user).id
        const { data } = await Api.get('api/report/average/preload', {
            params: { user_id },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        baseBackend.value = data.baseurl

        periodeAwalLimit1.value = new Date(data.periode[0].min_);
        periodeAwalLimit2.value = new Date(data.periode[0].max_);
        periodeAkhirLimit1.value = new Date(data.periode[0].max_);
        periodeAkhirLimit2.value = new Date(data.periode[0].max_);

        // v1
        // selectedPeriodeAwal.value = new Date(data.periode[0].min_)
        // selectedPeriodeAkhir.value = new Date(data.periode[0].min_)
        // v2
        // selectedPeriodeAwal.value = new Date(data.periode[0].max_)
        // selectedPeriodeAkhir.value = new Date(data.periode[0].max_)
        // v3
        // selectedPeriodeAwal.value = new Date(Date.now())
        // selectedPeriodeAkhir.value = new Date(Date.now())
        // v4
        selectedPeriodeAwal.value = new Date(data.periode[0].max_)
        selectedPeriodeAkhir.value = new Date(data.periode[0].max_)

        selectLokasiOptions.value = data.lokasi
        selectedLokasiValue.value = data.lokasi[0]

        gridData.value.lokasi = data.lokasi.length > 0 ? data.lokasi[0].code : null
        gridData.value.speed_type = selectedSpeedTypeValue.value.code

        // v1
        // gridData.value.periode_awal = moment(data.periode[0].min_).format('YYYY-MM-DD')
        // gridData.value.periode_akhir = moment(data.periode[0].min_).format('YYYY-MM-DD')
        // v2
        // gridData.value.periode_awal = moment(data.periode[0].max_).format('YYYY-MM-DD')
        // gridData.value.periode_akhir = moment(data.periode[0].max_).format('YYYY-MM-DD')
        // v3
        // gridData.value.periode_awal = moment(new Date(Date.now())).format('YYYY-MM-DD')
        // gridData.value.periode_akhir = moment(new Date(Date.now())).format('YYYY-MM-DD')
        // v4
        gridData.value.periode_awal = moment(new Date(data.periode[0].max_)).format('YYYY-MM-DD')
        gridData.value.periode_akhir = moment(new Date(data.periode[0].max_)).format('YYYY-MM-DD')
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        loader.hide()
    }
};

const printPDF = async () => {
    // return console.log('under development')
    let data = {
        file_report: 'AverageWindspeed.jrxml',
        params: {
            id_lokasi: gridData.value.lokasi,
            periode_awal: gridData.value.periode_awal,
            periode_akhir: gridData.value.periode_akhir,
            nm_lokasi: selectedLokasiValue.value.label,
            periode_text: `Periode ${moment(new Date(gridData.value.periode_awal)).format('DD-MM-YYYY')} sampai ${moment(new Date(gridData.value.periode_akhir)).format('DD-MM-YYYY')}`,
            print_text: `Dicetak ${moment(new Date()).format('DD-MM-YYYY HH:mm:ss')}`
        }
    };
    console.log(data.params);
    let qstring = $.param(data);
    let url = baseBackend.value + '?' + qstring;
    window.open(url, '_blank');
}

const printExcel = async () => {
    const params = {
        id_lokasi: gridData.value.lokasi,
        periode_awal: gridData.value.periode_awal,
        periode_akhir: gridData.value.periode_akhir,
        nm_lokasi: selectedLokasiValue.value.label,
        periode_text: `Periode ${moment(new Date(gridData.value.periode_awal)).format('DD-MM-YYYY')} sampai ${moment(new Date(gridData.value.periode_akhir)).format('DD-MM-YYYY')}`,
        print_text: `Dicetak ${moment(new Date()).format('DD-MM-YYYY HH:mm:ss')}`
    }

    const loader = loading.show({});
    let data = []
    try {
        const res = await Api.get('api/report/average/get_excel_data', {
            params: { ...gridData.value, ...gridTableProp.value },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        data = [...res.data.data]
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        loader.hide()
    }

    XLSX.utils.insert_rows = insert_rows;
    let newData = []

    data.map((item, i) => {
        if (item.type_ == 1 && item.no_ == 'No.' && i != 0) {
            newData.push(
                {
                    type: '',
                    row: '',
                    row_2: '',
                    row_3: '',
                    no_: '',
                    conv_waktu: '',
                    min: '',
                    max: '',
                    avg: '',
                    min_km: '',
                    max_km: '',
                    avg_km: '',
                    direction: '',
                    is_min_windspeed: '',
                    is_min_windspeed_km: '',
                    is_max_windspeed: '',
                    is_max_windspeed_km: ''
                }
            )
        }
        newData.push(
            {
                type: item.type_ || '',
                row: item.row_ || '',
                row_2: item.row_2 || '',
                row_3: item.row_3 || '',
                no_: item.no_ || '',
                conv_waktu: item.conv_waktu || '',
                min: item.min_ || '',
                max: item.max_ || '',
                avg: item.avg_ || '',
                min_km: item.min_km || '',
                max_km: item.max_km || '',
                avg_km: item.avg_km || '',
                direction: item.direction_ || '',
                is_min_windspeed: item.is_min_windspeed || '',
                is_min_windspeed_km: item.is_min_windspeed_km || '',
                is_max_windspeed: item.is_max_windspeed || '',
                is_max_windspeed_km: item.is_max_windspeed_km || ''
            }
        )
    })

    // return console.log(newData);

    const borderS = {
        right: {
            style: "thin",
            color: "000000"
        },
        left: {
            style: "thin",
            color: "000000"
        },
        top: {
            style: "thin",
            color: "000000"
        },
        bottom: {
            style: "thin",
            color: "000000"
        }
    }

    let sheet = XLSX.utils.json_to_sheet([]);
    let merge = []
    const spaceKosong = 6

    for (var i = 0; i < newData.length; i++) {
        var j = 0;

        let isHeadeGroup = [1, 3, 4, 6].includes(newData[i].type)
        let isHead1 = newData[i].type == 1
        let isHead2 = [3, 4].includes(newData[i].type)
        let isHead3 = newData[i].type == 6
        let isSpacer = newData[i].type == ''
        let isDetail = !isHeadeGroup
        let isType7 = [7].includes(newData[i].type)
        let isMinVal = (newData[i].is_min_windspeed == 'true' || newData[i].is_min_windspeed_km == 'true') ? true : false
        let isMaxVal = (newData[i].is_max_windspeed == 'true' || newData[i].is_max_windspeed_km == 'true') ? true : false


        for (var rw in newData[i]) {
            var cellRef = XLSX.utils.encode_cell({ r: i, c: j });
            var rawValue = newData[i][rw]
            var cellValue = { v: rawValue, t: 's' };

            // if (rw.includes('windspeed')) {
            //     cellValue.s.alignment = { horizontal: 'right' }
            // }

            if (!isSpacer) {
                cellValue.s = { border: borderS }
                if (isHeadeGroup) {
                    cellValue.s.font = { bold: true }
                }

                if (rw.includes('no_')) {
                    cellValue.s.alignment = { horizontal: 'center' }
                } else if (isDetail && ['min', 'max', 'avg', 'min_km', 'max_km', 'avg_km'].includes(rw)) {
                    cellValue.s.alignment = { horizontal: 'right' }
                }

                if (isHead1) {
                    cellValue.s.fill = { fgColor: { rgb: 'C6F6D5' } }
                } else if (isHead2) {
                    cellValue.s.fill = { fgColor: { rgb: 'FED7E2' } }
                    if (newData[i].type == 3 && (rw.includes('min') || rw.includes('min_km'))) {
                        cellValue.s.alignment = { horizontal: 'center' }
                    } else if (newData[i].type == 4 && (['min', 'max', 'avg', 'min_km', 'max_km', 'avg_km'].includes(rw))) {
                        cellValue.s.alignment = { horizontal: 'right' }
                    }
                } else if (isHead3) {
                    cellValue.s.fill = { fgColor: { rgb: 'FFFFB3' } }
                    if (newData[i].type == 6 && (rw.includes('min') || rw.includes('min_km'))) {
                        cellValue.s.alignment = { horizontal: 'center' }
                    }
                }

                // red green
                if (isType7 && ['min', 'min_km'].includes(rw) && isMinVal) {
                    cellValue.s.fill = { fgColor: { rgb: '198754' } }
                    cellValue.s.font = { color: { rgb: 'ffffff' } }
                } else if (isType7 && ['min', 'min_km'].includes(rw) && isMaxVal) {
                    cellValue.s.fill = { fgColor: { rgb: 'dc3545' } }
                    cellValue.s.font = { color: { rgb: 'ffffff' } }
                }
            }

            // merge
            if ([1, 2].includes(newData[i].type) && rw.includes('conv_waktu')) {
                merge.push(
                    { s: { r: i + spaceKosong, c: 5 }, e: { r: i + spaceKosong, c: 12 } }
                )
            }
            if ([3].includes(newData[i].type) && rw.includes('direction')) {
                merge.push(
                    { s: { r: i + spaceKosong, c: 12 }, e: { r: i + spaceKosong + 1, c: 12 } }
                )
            }
            if ([3].includes(newData[i].type) && ['conv_waktu', 'no_'].includes(rw)) {
                cellValue.s.alignment = { horizontal: 'center', vertical: 'center' }
                merge.push(
                    { s: { r: i + spaceKosong, c: j }, e: { r: i + spaceKosong + 1, c: j } }
                )
            }
            if ([3, 6].includes(newData[i].type) && ['min', 'min_km'].includes(rw)) {
                merge.push(
                    { s: { r: i + spaceKosong, c: j }, e: { r: i + spaceKosong, c: j + 2 } }
                )
            }
            if ([7].includes(newData[i].type) && ['min', 'min_km'].includes(rw)) {
                merge.push(
                    { s: { r: i + spaceKosong, c: j }, e: { r: i + spaceKosong, c: j + 2 } }
                )
            }

            XLSX.utils.sheet_add_aoa(sheet, [[cellValue]], { origin: { r: i, c: j } });

            j++;

        }
    }

    XLSX.utils.insert_rows(sheet, 0, spaceKosong);
    XLSX.utils.sheet_add_aoa(sheet, [[{
        v: 'Average Windspeed', t: 's', s: {
            font: {
                bold: true,
                // sz: 14
            }
        }
    }]], { origin: { r: 0, c: 4 } });
    XLSX.utils.sheet_add_aoa(sheet, [[{
        v: '', t: 's', s: {
            font: {
                bold: true,
                // sz: 14
            }
        }
    }]], { origin: { r: 1, c: 4 } });
    XLSX.utils.sheet_add_aoa(sheet, [[{
        v: params.nm_lokasi, t: 's', s: {
            font: {
                bold: true,
                // sz: 14
            }
        }
    }]], { origin: { r: 2, c: 4 } });
    XLSX.utils.sheet_add_aoa(sheet, [[{
        v: params.periode_text, t: 's', s: {
            font: {
                bold: true,
                // sz: 14
            }
        }
    }]], { origin: { r: 3, c: 4 } });
    XLSX.utils.sheet_add_aoa(sheet, [[{
        v: params.print_text, t: 's', s: {
            font: {
                bold: false,
                // sz: 14
            }
        }
    }]], { origin: { r: 4, c: 4 } });
    XLSX.utils.sheet_add_aoa(sheet, [[{
        v: '', t: 's', s: {
            font: {
                bold: true,
                // sz: 14
            }
        }
    }]], { origin: { r: 5, c: 4 } });
    merge.push(
        { s: { r: 0, c: 4 }, e: { r: 0, c: 8 } },
        { s: { r: 2, c: 4 }, e: { r: 2, c: 8 } },
        { s: { r: 3, c: 4 }, e: { r: 3, c: 8 } },
        { s: { r: 4, c: 4 }, e: { r: 4, c: 8 } },
    )

    sheet['!cols'] = [];
    sheet['!cols'][0] = { hidden: true };
    sheet['!cols'][1] = { hidden: true };
    sheet['!cols'][2] = { hidden: true };
    sheet['!cols'][3] = { hidden: true };
    sheet['!cols'][4] = { wch: 10 };
    sheet['!cols'][5] = { wch: 30 };
    sheet['!cols'][6] = { wch: 20 };
    sheet['!cols'][7] = { wch: 20 };
    sheet['!cols'][8] = { wch: 20 };
    sheet['!cols'][9] = { wch: 20 };
    sheet['!cols'][10] = { wch: 20 };
    sheet['!cols'][11] = { wch: 20 };
    sheet['!cols'][12] = { wch: 30 };
    sheet['!cols'][13] = { hidden: true };
    sheet['!cols'][14] = { hidden: true };
    sheet['!cols'][15] = { hidden: true };
    sheet['!cols'][16] = { hidden: true };

    sheet["!merges"] = merge;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, params.periode_text.replace("Periode ", ' ').replace("sampai", "-"));
    // XLSX_CALC(workbook);
    XLSX.writeFile(workbook, "Average Windspeed " + params.nm_lokasi + params.periode_text.replace("Periode ", ' ').replace("sampai", "-") + ".xlsx", { compression: true });
}

const showToast = (message, bg) => {
    toastEl.value.message = message;
    toastEl.value.bg = `text-bg-${bg}`;
    toast.value.show();

    setTimeout(() => {
        toast.value.hide();
    }, 3000);
};

const handleChangeSpeedType = (value) => {
    const temp = gridData
    if (value) {
        temp.value.speed_type = value.code
    } else {
        temp.value.speed_type = null
    }
    gridData.value = temp.value
    fetchData();
};

const handleChangePeriodeAwal = (value) => {
    if (inputPeriodeAwalRef.value) {
        inputPeriodeAwalRef.value.blur()
    }
    const temp = gridData
    temp.value.periode_awal = moment(value).format('YYYY-MM-DD')
    // temp.value.periode_akhir = moment(value).format('YYYY-MM-DD')
    gridData.value = temp.value

    periodeAwalLimit2.value = new Date(value);
    // selectedPeriodeAkhir.value = new Date(value);
    fetchData();
};

const handleChangePeriodeAkhir = (value) => {
    if (inputPeriodeAkhirRef.value) {
        inputPeriodeAkhirRef.value.blur()
    }
    const temp = gridData
    temp.value.periode_akhir = moment(value).format('YYYY-MM-DD')
    gridData.value = temp.value

    periodeAkhirLimit1.value = new Date(value)
    fetchData();
};

const handleChangeLokasi = (value) => {
    const temp = gridData
    if (value) {
        temp.value.lokasi = value.code
    } else {
        temp.value.lokasi = null
    }
    gridData.value = temp.value
    fetchData();
};

const expandSubGrid = async (tr, data) => {
    let subGridData = [...tableDataD1.value.filter(item => item.row_parent == data.row_)]

    tr.insertAdjacentHTML('afterend', `
        <tr id="tr-${data.row_}">
            <td></td>
            <td style="max-width: 0px !important;">
                <table style="width: 100%; height: 100%;" id='datatable-detail-${data.row_}' class='table table-bordered table-striped datatable-details'>
                </table>
            </td>
        </tr>
    `);

    await $(`#datatable-detail-${data.row_}`).dataTable({
        lengthMenu: [
            [10, 25, 50, 100, -1],
            [10, 25, 50, 100, 'All'],
        ],
        pageLength: -1,
        order: [],
        searching: false,
        scrollX: true,
        data: subGridData,
        columns: [
            {
                className: 'details-control-2 text-center',
                data: null,
                defaultContent: '',
                width: '20px',
                title: "#",
                orderable: false
            },
            { title: "Hours", data: 'conv_waktu', className: 'text-start' },
            {
                width: '200px',
                title: 'Min.',
                orderable: true,
                data: null,
                render: function (data, type, row) {
                    const text = selectedSpeedTypeValue.value.code == "knot" ? `${row.min_} ${selectedSpeedTypeValue.value.label}` : selectedSpeedTypeValue.value.code == "kmph" ? `${row.min_km} ${selectedSpeedTypeValue.value.label}` : '';
                    return text
                }
            },
            {
                width: '200px',
                title: 'Max.',
                orderable: true,
                data: null,
                render: function (data, type, row) {
                    const text = selectedSpeedTypeValue.value.code == "knot" ? `${row.max_} ${selectedSpeedTypeValue.value.label}` : selectedSpeedTypeValue.value.code == "kmph" ? `${row.max_km} ${selectedSpeedTypeValue.value.label}` : '';
                    return text
                }
            },
            {
                width: '200px',
                title: 'Avg.',
                orderable: true,
                data: null,
                render: function (data, type, row) {
                    const text = selectedSpeedTypeValue.value.code == "knot" ? `${row.avg_} ${selectedSpeedTypeValue.value.label}` : selectedSpeedTypeValue.value.code == "kmph" ? `${row.avg_km} ${selectedSpeedTypeValue.value.label}` : '';
                    return text
                }
            },
        ],
        initComplete: function (settings, json) {
            $(`table#datatable-detail-${data.row_} > thead > tr`).css({ visibility: 'collapse' });
        },
        drawCallback: function (settings) {
            $(`table#datatable-detail-${data.row_} > thead > tr`).css({ visibility: 'collapse' });
            setTimeout(() => {
                $(`#datatable-detail-${data.row_}`).DataTable().columns.adjust()
                $(`table#datatable-detail-${data.row_} > thead > tr`).css({ visibility: 'collapse' });
                table1Instance.columns.adjust()
            }, 0);

        }
    })
}

const expandSubGrid2 = async (tr, data) => {
    let subGridData2 = [...tableDataD2.value.filter(item => item.parent_parent_row == data.row_parent && item.parent_row == data.row_)]

    tr.insertAdjacentHTML('afterend', `
        <tr id="tr-${data.row_parent}-${data.row_}">
            <td></td>
            <td colspan='4' style="max-width: 0px !important;">
                <table style="width: 100%; height: 100%;" id='datatable-detail-${data.row_parent}-${data.row_}' class='table table-bordered table-striped datatable-details-2'>
                </table>
            </td>
        </tr>
    `);

    await $(`#datatable-detail-${data.row_parent}-${data.row_}`).dataTable({
        lengthMenu: [
            [10, 25, 50, 100, -1],
            [10, 25, 50, 100, 'All'],
        ],
        pageLength: -1,
        order: [],
        searching: false,
        scrollX: true,
        data: subGridData2,
        columns: [
            // {
            //     title: 'Timeline',
            //     orderable: true,
            //     data: null,
            //     render: function (data, type, row) {
            //         const text = moment(new Date(row.waktu)).format('DD-MM-YYYY HH:mm:ss')
            //         return text
            //     }
            // },
            { title: "Timeline", data: 'conv_waktu', className: 'text-start' },
            {
                width: '150px',
                title: 'Windspeed',
                orderable: true,
                data: null,
                render: function (data, type, row) {
                    const text = selectedSpeedTypeValue.value.code == "knot" ? `${row.windspeed} ${selectedSpeedTypeValue.value.label}` : selectedSpeedTypeValue.value.code == "kmph" ? `${row.windspeed_km} ${selectedSpeedTypeValue.value.label}` : '';
                    let classnameMin = ''
                    let classnameMax = ''
                    if (selectedSpeedTypeValue.value.code == "knot") {
                        // console.log('knot');
                        classnameMin = row.is_min_windspeed ? 'minval' : ''
                        classnameMax = row.is_max_windspeed ? 'maxval' : ''
                    } else if (selectedSpeedTypeValue.value.code == "kmph") {
                        // console.log('kmph');
                        classnameMin = row.is_min_windspeed_km ? 'minval' : ''
                        classnameMax = row.is_max_windspeed_km ? 'maxval' : ''
                    }
                    return `<div class="${classnameMin} ${classnameMax}">${text}</div>`
                }
            },
            { title: "Direction", data: 'nama_en', width: '250px' },
        ],
        initComplete: function (settings, json) {
            $(`table#datatable-detail-${data.row_parent}-${data.row_} > thead > tr`).css({ visibility: 'collapse' });
        },
        drawCallback: function (settings) {
            $(`table#datatable-detail-${data.row_parent}-${data.row_} > thead > tr`).css({ visibility: 'collapse' });
            setTimeout(() => {
                $(`#datatable-detail-${data.row_parent}-${data.row_}`).DataTable().columns.adjust()
                $(`table#datatable-detail-${data.row_parent}-${data.row_} > thead > tr`).css({ visibility: 'collapse' });
                table1Instance.columns.adjust()
            }, 0);

        }
    })
}

const clickEventHandler = async (event) => {
    const targetSubGrid = event.target.closest('td.details-control');
    if (targetSubGrid) {
        const tr = event.target.closest('tr');
        const row = table1Instance.row(tr)

        if (document.getElementById(`tr-${row.data().row_}`)) {
            tr.classList.remove('shown')
            $(`#tr-${row.data().row_}`).remove()
        } else {
            expandSubGrid(tr, row.data())
            tr.classList.add('shown')
        }
        setTimeout(() => {
            table1Instance.columns.adjust()
        }, 0);
    }

    const targetSubGrid2 = event.target.closest('td.details-control-2');
    if (targetSubGrid2) {
        const tr = event.target.closest('tr');
        const parentId = tr.parentElement.parentElement.id
        const row = $(`#${parentId}`).dataTable().api().row(tr);

        if (document.getElementById(`tr-${row.data().row_parent}-${row.data().row_}`)) {
            tr.classList.remove('shown')
            $(`#tr-${row.data().row_parent}-${row.data().row_}`).remove()
        } else {
            expandSubGrid2(tr, row.data())
            tr.classList.add('shown')
        }
        setTimeout(() => {
            table1Instance.columns.adjust()
        }, 0);
    }
}

onMounted(async () => {
    table1Instance = table1.value.dt;
    inputPeriodeAwalRef.value = document.getElementById('periode_awal')
    inputPeriodeAkhirRef.value = document.getElementById('periode_akhir')
    await fetchDataSelect();
    await fetchData();

    // event listeners
    document.removeEventListener('click', clickEventHandler);
    document.addEventListener('click', clickEventHandler);

    toast.value = new bootstrap.Toast(document.getElementById('toast'), { delay: 3000 });
});

onBeforeUnmount(() => {
    document.removeEventListener('click', clickEventHandler);
});
</script>

<template>
    <div class="toast-container position-fixed top-0 end-0 p-3">
        <div id="toast" :class="'toast align-items-center border-0 ' + toastEl.bg" role="alert" aria-live="assertive"
            aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    {{ toastEl.message }}
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                    aria-label="Close"></button>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="card">
            <div class="card-header">
                <h5 class="mb-0">Average Windspeed</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-3">
                        <label for="lokasi" class="form-label">Lokasi</label>
                        <v-select id="lokasi" v-model="selectedLokasiValue" :options="selectLokasiOptions"
                            :clearable="false" @update:modelValue="handleChangeLokasi" placeholder="Harap pilih lokasi"
                            required></v-select>
                    </div>
                    <div class="col-3">
                        <label for="periode_awal" class="form-label">Periode Awal</label>
                        <Datepicker id="periode_awal" class="form-control bg-white" v-model="selectedPeriodeAwal"
                            @update:modelValue="handleChangePeriodeAwal" :inputFormat="periodeFormat"
                            :upper-limit="periodeAkhirLimit1" :lower-limit="periodeAwalLimit1"
                            placeholder="Pilih periode awal" startingView="day" minimumView="day" />
                    </div>
                    <div class="col-3">
                        <label for="periode_akhir" class="form-label">Periode Akhir</label>
                        <Datepicker id="periode_akhir" class="form-control bg-white" v-model="selectedPeriodeAkhir"
                            @update:modelValue="handleChangePeriodeAkhir" :inputFormat="periodeFormat"
                            :upper-limit="periodeAkhirLimit2" :lower-limit="periodeAwalLimit2"
                            placeholder="Pilih periode akhir" startingView="day" minimumView="day" />
                    </div>
                    <div class="col-3">
                        <label for="speed_type" class="form-label">Satuan Kecepatan</label>
                        <v-select id="speed_type" v-model="selectedSpeedTypeValue" :options="selectSpeedTypeOptions"
                            :clearable="false" @update:modelValue="handleChangeSpeedType" required
                            placeholder="Harap pilih satuan kecepatan">
                        </v-select>
                    </div>
                    <div class="col-6 mt-0 mt-md-3">

                    </div>
                    <div class="col-6 mt-0 mt-md-3">
                        <div class="flex text-end">
                            <button class="btn btn-primary mb-2 mr-2" @click="printPDF">Cetak PDF</button>
                            <button class="btn btn-success mb-2" @click="printExcel">Cetak Excel</button>
                        </div>
                    </div>
                </div>

                <DataTable :columns="columns" :data="tableData" :options="dataTableOptions" ref="table1"
                    class="table table-bordered table-striped datatableParentClass" width="100%"></DataTable>
            </div>
        </div>
    </div>
</template>

<style>
@import 'bootstrap';
@import 'datatables.net-bs5';
</style>

<style scoped>
.select2-dropdown {
    z-index: 99999 !important;
}
</style>

<style>
table.datatable-details>thead>tr>th,
table.datatable-details-2>thead>tr>th {
    padding: 8px 30px 8px 8px !important;
    min-height: 41.2px !important;
}

div.dt-scroll-body>table.datatable-details>thead,
div.dt-scroll-body>table.datatable-details-2>thead {
    /* display: none !important; */
    /* height: 1px !important; */
}

div.dt-scroll-body>table.datatableParentClass,
div.dt-scroll-body>table.datatable-details,
div.dt-scroll-body>table.datatable-details-2 {
    overflow: hidden !important;
}

td:has(> div.minval), td:has(> div.minval.maxval) {
    background-color: #198754 !important;
    color: white !important;
}

td:has(> div.maxval) {
    background-color: #dc3545 !important;
    color: white !important;
}
</style>