<template>
    <li class="nav-item">
        <router-link class="nav-link" :to="route.children && route.children.length?'#':route.route" :class="isActive()">
            <i class="nav-icon" :class="setIcon()"></i>
            <p>{{route.title}}</p>
            <i v-if="route.children && route.children.length" class="fas fa-angle-left right"></i>
        </router-link >

        <!-- <ul v-if="route.children && route.children.length" :class="'nav nav-treeview pl-'+route.level">
            <nav-item v-for="menu in route.children" :key="menu.id" :route="menu"></nav-item>
        </ul> -->

        <ul v-if="route.children && route.children.length" :class="'nav nav-treeview'">
            <nav-item v-for="menu in route.children" :key="menu.id" :route="menu"></nav-item>
        </ul>
    </li>
</template>

<script>
export default {
    props : {
        // icon :{
        //     type : String,
        //     default : null,
        // },
        // link :{
        //     type : {String, Object},
        //     default : null
        // },
        route: {
            type: Object
        }
    },
    methods : {
        isActive() {
            // if (typeof this.route === "object") {
            //     return this.$route.name === this.route.title;
            // } else {
            //     this.$route.path === this.route;

            // }
            if (this.$route.path === this.route.route) {
                return 'active'
            }
            return ''
        },
        setIcon() {
            // console.log('url: ', this.$route.path, this.route.route)
            if (this.route.icon != '' && this.route.icon != null) {
                return this.route.icon
            } else {
                let icon = ''
                switch (this.route.level) {
                    case 1:
                        icon = 'far fa-circle nav-icon'
                        break;
                    case 2:
                        icon = 'far fa-dot-circle nav-icon'
                        break;
                    case 3:
                        icon = 'fas fa-circle nav-icon'
                        break;
                
                    default:
                        break;
                }
                return icon
            }
        }
    }
}
</script>