<template>
  <div class="wrapper">
    <navbar />
    <sidebar />
   

    <div id="target-content-div" class="content-wrapper pt-3 px-2" style="overflow-y: auto; max-height: 60vh;">
   
        <router-view />
   
    </div>

    <footer class="main-footer">
      <div class="float-right d-none d-sm-block"><b>Version</b> 1.0.0</div>
      <strong>Copyright &copy; 2024
        <a href="http://www.jasamaritim.co.id">Pelindo Jasa Maritim</a>.
      </strong> All rights reserved.
    </footer>

    <aside class="control-sidebar control-sidebar-dark"></aside>
  </div>
</template>

<script>
//import store user
import { useUser } from "../stores/user";
import Navbar from "./include/Navbar.vue";
import Sidebar from "./include/Sidebar.vue";

export default {
  components: {
    Navbar,
    Sidebar,
  },
};
</script>