<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useLoading } from 'vue-loading-overlay'
import * as Popper from "@popperjs/core"
import Api from '../../services/api';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Swal from 'sweetalert2';
import $ from 'jquery';

import 'vue-select/dist/vue-select.css';

const indikatorKerusakanData = ref({ bts_waktu: '' })
// const selectedDeletedBySystemValue = ref(null);
// const selectDeletedBySystemOptions = ref([
//     { code: true, label: 'Ya' },
//     { code: false, label: 'Tidak' }
// ]);

const loading = useLoading({});
const configObject = { keyboard: false, backdrop: 'static' }
const toast = ref(null);
const toastEl = ref({});

const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

const preload = async () => {
    const loader = loading.show({});
    try {
        const res = await Api.get('api/indikator_kerusakan/preload', {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        // console.log(res.data.data);
        indikatorKerusakanData.value.bts_waktu = res.data.data.bts_waktu
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        loader.hide()

    }
};

const showToast = (message, bg) => {
    // console.log({message, bg});
    toastEl.value.message = message;
    toastEl.value.bg = `text-bg-${bg}`;
    toast.value.show();

    setTimeout(() => {
        toast.value.hide();
    }, 10000);
};

const submitIndikatorKerusakanHandler = async () => {

    // return console.log(indikatorKerusakanData.value);

    const loader = loading.show({});
    try {
        const { data } = await Api.post(`api/indikator_kerusakan/save`, indikatorKerusakanData.value, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        if (data.success) {
            showToast(data.message, 'success');
        } else {
            showToast(data.message, 'danger');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        showToast(error, 'danger');
    } finally {
        loader.hide()
    }
};

const clickEventHandler = async (event) => {

}

onMounted(async () => {
    await preload()

    // event listeners
    document.removeEventListener('click', clickEventHandler);
    document.addEventListener('click', clickEventHandler);

    toast.value = new bootstrap.Toast(document.getElementById('toast-navbar'), { delay: 10000 });
});

onBeforeUnmount(() => {
    document.removeEventListener('click', clickEventHandler);
});
</script>

<template>
    <div class="toast-container position-fixed top-0 end-0 p-3">
        <div id="toast-navbar" :class="'toast align-items-center border-0 ' + toastEl.bg" role="alert" aria-live="assertive"
            aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    {{ toastEl.message }}
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                    aria-label="Close"></button>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="card">
            <div class="card-header">
                <h5 class="mb-0">Indikator Kerusakan</h5>
            </div>
            <div class="card-body" id="">
                <form method="post" id="indikator-kerusakan-form" @submit.prevent="submitIndikatorKerusakanHandler">
                    <div class="row">
                        <div class="col-6">
                            <label for="bts_waktu" class="form-label">Batas waktu kerusakan (satuan jam)</label>
                            <input type="number" name="bts_waktu" class="form-control" step="1" min="1"
                                onkeyup="this.value=this.value.replace(/[^\d]/,'')"
                                v-model="indikatorKerusakanData.bts_waktu" required  placeholder="Masukkan batas">
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-footer">
                <button type="submit" form="indikator-kerusakan-form" class="btn btn-success">Simpan</button>
            </div>
        </div>
    </div>
</template>

<style>
@import 'bootstrap';
@import 'datatables.net-bs5';
</style>

<style scoped>
.select2-dropdown {
    z-index: 99999 !important;
}
</style>
