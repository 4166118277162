<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useLoading } from 'vue-loading-overlay'
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';
import * as Popper from "@popperjs/core"
import Api from '../../services/api';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Swal from 'sweetalert2';

import { insert_rows } from '../../utils/customXlsxUtils'

import 'vue-select/dist/vue-select.css';

import * as echarts from 'echarts';

import moment from 'moment';
import Datepicker from 'vue3-datepicker'
// import * as XLSX from 'xlsx';
import * as XLSX from 'xlsx-js-style';

let windspeedChart = null;

const chart = ref(null);
const optionChart = ref(null)
const tableData = ref([]);
const loading = useLoading({});
const configObject = { keyboard: false, backdrop: 'static' }
const toast = ref(null);
const toastEl = ref({});
const baseBackend = ref('')
const dataTableOptions = ref({
    lengthMenu: [
        [10, 25, 50, 100, -1],
        [10, 25, 50, 100, 'All'],
    ],
    pageLength: -1,
    order: [],
    scrollX: true,
    initComplete: function () {
        // You can add your custom code here
    },
    drawCallback: function (settings) {
        // console.log('DataTable draw event.');
        // console.log(settings);
        if (table1Instance) {
            gridTableProp.value.current_page = table1Instance.page() + 1
            gridTableProp.value.page_length = table1Instance.page.len()
        }
        // console.log(gridTableProp.value);
    },
});

const gridData = ref({ lokasi: '', periode_awal: '', periode_akhir: '', speed_type: '' });
const gridTableProp = ref({ current_page: 0, page_length: 0 })
const selectedLokasiValue = ref(null);
const selectLokasiOptions = ref([]);
const selectedSpeedTypeValue = ref({ code: 'knot', label: 'Knots' });
const selectSpeedTypeOptions = ref([
    { code: 'knot', label: 'Knots' },
    { code: 'kmph', label: 'km/jam' }
]);
const selectedPeriodeAwal = ref(new Date())
const selectedPeriodeAkhir = ref(new Date())
const inputPeriodeAwalRef = ref(null)
const inputPeriodeAkhirRef = ref(null)
const periodeFormat = 'dd-MM-yyyy'
const periodeAwalLimit1 = ref(new Date());
const periodeAkhirLimit1 = ref(new Date());
const periodeAwalLimit2 = ref(new Date());
const periodeAkhirLimit2 = ref(new Date());

const maxChartDateBtn = ref(null)

let table1Instance;
const table1 = ref();

DataTable.use(DataTablesCore);

const columns = [
    { title: "No.", data: 'row_', width: "20px" },
    // {
    //     title: "No.",
    //     data: null,
    //     width: '20px',
    //     orderable: false,
    //     className: "text-center",
    //     render: function (data, type, row, meta) {
    //         return meta.row + meta.settings._iDisplayStart + 1;
    //     }
    // },
    // { title: "Waktu", data: 'waktu', className: 'text-start' },
    {
        title: 'Waktu',
        orderable: true,
        data: null,
        render: function (data, type, row) {
            const text = moment(new Date(row.waktu)).format('DD-MM-YYYY HH:mm:ss')
            return text
        }
    },
    {
        title: 'Windspeed',
        orderable: true,
        data: null,
        render: function (data, type, row) {
            const text = selectedSpeedTypeValue.value.code == "knot" ? `${row.windspeed} ${selectedSpeedTypeValue.value.label}` : selectedSpeedTypeValue.value.code == "kmph" ? `${row.windspeed_km} ${selectedSpeedTypeValue.value.label}` : '';
            return text
        }
    },
    { title: "Direction", data: 'nama_en' },
];

const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

const fetchData = async () => {
    const loader = loading.show({});
    try {
        const res = await Api.get('api/report/history', {
            params: { ...gridData.value, ...gridTableProp.value },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        tableData.value = res.data.data;
        const buttonChartDate = res.data.chart_button

        //
        $('#button-chart-date-container').empty()
        let currentDate = '';
        buttonChartDate.map((item, i) => {
            if (i == buttonChartDate.length - 1) {
                currentDate = item.conv_waktu
            }
            $('#button-chart-date-container').append(`<button style='flex: 0 0 auto;' class="btn btn-sm ${i == buttonChartDate.length - 1 ? "btn-danger" : "btn-secondary"} button-chart-date" data-id="${item.row_}" data-date="${item.conv_waktu}">${item.conv_waktu}</button>`)
        })

        if (buttonChartDate.length > 0) {
            await genChart(currentDate, false)
            
            // v1
            // await sleepCustom(20)
            // scrollToRight()

            // v3
            setTimeout(() => {
                scrollToRight()
                maxChartDateBtn.value = currentDate
            }, 20);
        } else {
            if (windspeedChart) {
                windspeedChart.dispose();
            }
            maxChartDateBtn.value = null
        }
        
        //

    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        loader.hide()
        setTimeout(() => {
            table1Instance.columns.adjust().draw()
        }, 0);
    }
};

const sleepCustom = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const genIconDirectionLabel = (degree) => {
    const svgIconStr = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="transform: rotate(${-45 + degree}deg);"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M429.6 92.1c4.9-11.9 2.1-25.6-7-34.7s-22.8-11.9-34.7-7l-352 144c-14.2 5.8-22.2 20.8-19.3 35.8s16.1 25.8 31.4 25.8H224V432c0 15.3 10.8 28.4 25.8 31.4s30-5.1 35.8-19.3l144-352z" fill="#DC3545"/></svg>`
    return svgIconStr
}


const encodeSvgToDataURI = (svg) => {
    return (
        'data:image/svg+xml;charset=utf8,' +
        encodeURIComponent(
            svg.replace(
                '<svg',
                svg.indexOf('xmlns') > -1
                    ? '<svg'
                    : '<svg xmlns="http://www.w3.org/2000/svg"'
            )
        )
    );
}

const genChart = async (date, withLoading = true) => {
    const dayName = moment(date, "DD-MM-YYYY").format('dddd')

    let loader
    if (withLoading) {
        loader = loading.show({});
    }
    try {
        const res2 = await Api.get('api/report/history/chart', {
            params: { ...gridData.value, current_date: date },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        const xAxisData = res2.data.chart.map(item => item.conv_waktu)
        const yAxisData = selectedSpeedTypeValue.value.code == "knot" ? res2.data.chart.map(item => parseFloat(item.windspeed)) : selectedSpeedTypeValue.value.code == "kmph" ? res2.data.chart.map(item => parseFloat(item.windspeed_km)) : res2.data.chart.map(item => parseFloat(item.windspeed))
        const windDirDegree = res2.data.chart.map(item => parseFloat(item.derajat))
        const windDirDegree2 = res2.data.chart.map(item => parseFloat(item.derajat) + "°")
        const windDirShort = res2.data.chart.map(item => item.kode_en)

        const generateWinDirRaw = [...res2.data.chart.map(item => (
            {
                degree: parseFloat(item.derajat),
                text: "direction_" + parseFloat(item.derajat).toString().replace(/\./g, '_'),
            }
        ))]

        let generateWinDir = generateWinDirRaw.reduce((acc, obj) => {
            let found = acc.find(item => item.degree === obj.degree);
            if (!found) {
                acc.push(obj);
            }
            return acc;
        }, []);

        // const labelFormatters = [
        //     '{direction_0|}',
        //     '$23,87M (-0,00M {directionIconLabel|})',
        //     '$3,32M (20,55M {directionIconLabel|})'
        // ];

        optionChart.value = {
            grid: {
                // left: 50,
                // right: 70,
                // top: 50,
                // bottom: 20,
                left: 40,
                // right: 70,
                top: 30,
                bottom: 70,
                containLabel: true
            },
            dataZoom: [
                {
                    type: 'slider',
                    show: true,
                    xAxisIndex: [0, 1],
                    start: 0,
                    end: 100,
                    // handleSize: '50'
                    height: 10,
                    labelFormatter: ''
                },
                {
                    type: 'slider',
                    show: true,
                    yAxisIndex: [0],
                    left: '0%',
                    start: 0,
                    end: 100,
                    // handleSize: '50px'
                    width: 10, 
                    labelFormatter: ''
                },
                // {
                //     type: 'inside',
                //     xAxisIndex: [0, 1],
                //     start: 0,
                //     end: 100
                // },
                // {
                //     type: 'inside',
                //     yAxisIndex: [0],
                //     start: 0,
                //     end: 100
                // },
            ],
            xAxis: [
                {
                    name: 'Time',
                    type: 'category',
                    data: xAxisData, 
                    nameLocation: 'center', 
                    nameGap: 30
                },
                {
                    name: 'Direction',
                    type: 'category',
                    data: windDirDegree2,
                    nameLocation: 'center', 
                    nameGap: 30,
                    axisLabel: {
                        interval: 0,
                        formatter: function (value) {
                            // console.log(value);
                            const text = "direction_" + parseFloat(value).toString().replace(/\°/g, '').replace(/\./g, '_')
                            // console.log(text);
                            // return `${labelFormatters[0]}`
                            return `{${text}|}`
                        },
                        // rich: {
                        //     directionIconLabel: {
                        //         height: 20,
                        //         align: 'center',
                        //         backgroundColor: {
                        //             image: encodeSvgToDataURI(genIconDirectionLabel(180))
                        //         }
                        //     }, 
                        //     directionIconLabel2: {
                        //         height: 20,
                        //         align: 'center',
                        //         backgroundColor: {
                        //             image: encodeSvgToDataURI(genIconDirectionLabel(90))
                        //         }
                        //     }, 
                        // }
                        rich: Object.assign(...generateWinDir.map((item, i) => ({
                            [`${item.text}`]: {
                                height: 20,
                                align: 'center',
                                backgroundColor: {
                                    image: encodeSvgToDataURI(genIconDirectionLabel(item.degree))
                                }
                            }
                        })))
                    }
                }
            ],
            yAxis: {
                name: `${selectedSpeedTypeValue.value.code == "knot" ? "(knots)" : "(km/j)"}`,
                type: 'value', 
                nameLocation: 'center', 
                nameGap: 30
            },
            series: [
                {
                    data: yAxisData,
                    type: 'line'
                }
            ],
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        show: false,
                    }
                },
                formatter: function (params) {
                    const data = params[0];
                    const value = data.value;
                    const xAxisLabel = data.axisValueLabel;

                    const degree = `${windDirDegree[data.dataIndex]}°`
                    const dirShort = `${windDirShort[data.dataIndex]}`

                    let customDiv = `
                    <div class="d-flex flex-column text-dark">
                        <div class="fw-bold">
                            ${dayName}, ${date} ${xAxisLabel}
                        </div>
                        <div class="d-flex flex-row">
                            <div class="d-flex justify-content-center align-items-center">
                                <i class="fa fa-location-arrow fa-2x" style="transform: rotate(${-45 + parseFloat(windDirDegree[data.dataIndex])}deg);"></i>
                            </div>
                            <div class="ms-2">
                                <div class="">Wind direction: ${degree} / ${dirShort}</div>
                                <div class="">Wind speed: ${value} ${selectedSpeedTypeValue.value.code == "knot" ? "knots" : "km/j"}</div>
                            </div>
                        </div>
                    </div>
                    `;

                    return customDiv;
                }
            },
        }

        // console.log(optionChart.value.xAxis[1].axisLabel.rich);

        if (windspeedChart) {
            windspeedChart.dispose();
        }

        // const widthChart = (xAxisData.length * 70) + 500 + 'px'
        // const heightChart = (yAxisData.length * 10) + 300 + 'px'
        // $('#chart-target-el').css('width', widthChart)
        // $('#chart-target-el').css('height', heightChart)

        // console.log($('#sample-width').width());
        const newWidth = $('#sample-width').width()
        $('#chart-target-el').css('width', newWidth)
        $('#chart-target-el').css('height', '70vh')

        windspeedChart = echarts.init(chart.value);
        windspeedChart.setOption(optionChart.value);

    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        if (withLoading) {
            loader.hide()
        }
    }

}

const fetchDataSelect = async () => {
    const loader = loading.show({});
    try {
        const user_id = JSON.parse(localStorage.user).id
        const { data } = await Api.get('api/report/history/preload', {
            params: { user_id },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        baseBackend.value = data.baseurl

        periodeAwalLimit1.value = new Date(data.periode[0].min_);
        periodeAwalLimit2.value = new Date(data.periode[0].max_);
        periodeAkhirLimit1.value = new Date(data.periode[0].max_);
        periodeAkhirLimit2.value = new Date(data.periode[0].max_);

        // v1
        // selectedPeriodeAwal.value = new Date(data.periode[0].min_)
        // selectedPeriodeAkhir.value = new Date(data.periode[0].min_)
        // v2
        // selectedPeriodeAwal.value = new Date(data.periode[0].max_)
        // selectedPeriodeAkhir.value = new Date(data.periode[0].max_)
        // v3
        // selectedPeriodeAwal.value = new Date(Date.now())
        // selectedPeriodeAkhir.value = new Date(Date.now())
        // v4
        selectedPeriodeAwal.value = new Date(data.periode[0].max_)
        selectedPeriodeAkhir.value = new Date(data.periode[0].max_)

        selectLokasiOptions.value = data.lokasi
        selectedLokasiValue.value = data.lokasi[0]

        gridData.value.lokasi = data.lokasi.length > 0 ? data.lokasi[0].code : null
        gridData.value.speed_type = selectedSpeedTypeValue.value.code

        // v1
        // gridData.value.periode_awal = moment(data.periode[0].min_).format('YYYY-MM-DD')
        // gridData.value.periode_akhir = moment(data.periode[0].min_).format('YYYY-MM-DD')
        // v2
        // gridData.value.periode_awal = moment(data.periode[0].max_).format('YYYY-MM-DD')
        // gridData.value.periode_akhir = moment(data.periode[0].max_).format('YYYY-MM-DD')
        // v3
        // gridData.value.periode_awal = moment(new Date(Date.now())).format('YYYY-MM-DD')
        // gridData.value.periode_akhir = moment(new Date(Date.now())).format('YYYY-MM-DD')
        // v4
        gridData.value.periode_awal = moment(new Date(data.periode[0].max_)).format('YYYY-MM-DD')
        gridData.value.periode_akhir = moment(new Date(data.periode[0].max_)).format('YYYY-MM-DD')
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        loader.hide()
    }
};

const printPDF = async () => {
    let data = {
        // file_report: 'HistoryWindspeed.jrxml',
        // file_report: 'HistoryWindspeed_v2.jrxml',
        // file_report: 'HistoryWindspeed_v3.jrxml',
        file_report: 'HistoryWindspeed_v4.jrxml',
        params: {
            id_lokasi: gridData.value.lokasi,
            periode_awal: gridData.value.periode_awal,
            periode_akhir: gridData.value.periode_akhir,
            nm_lokasi: selectedLokasiValue.value.label,
            periode_text: `Periode ${moment(new Date(gridData.value.periode_awal)).format('DD-MM-YYYY')} sampai ${moment(new Date(gridData.value.periode_akhir)).format('DD-MM-YYYY')}`,
            print_text: `Dicetak ${moment(new Date()).format('DD-MM-YYYY HH:mm:ss')}`
        }
    };
    // console.log(data.params);
    let qstring = $.param(data);
    let url = baseBackend.value + '?' + qstring;
    window.open(url, '_blank');
}

const printExcel = async () => {
    const params = {
        id_lokasi: gridData.value.lokasi,
        periode_awal: gridData.value.periode_awal,
        periode_akhir: gridData.value.periode_akhir,
        nm_lokasi: selectedLokasiValue.value.label,
        periode_text: `Periode ${moment(new Date(gridData.value.periode_awal)).format('DD-MM-YYYY')} sampai ${moment(new Date(gridData.value.periode_akhir)).format('DD-MM-YYYY')}`,
        print_text: `Dicetak ${moment(new Date()).format('DD-MM-YYYY HH:mm:ss')}`
    }
    XLSX.utils.insert_rows = insert_rows;
    let data = [...tableData.value];
    let newData = []

    data.map((item, i) => {
        if (i == 0) {
            newData.push(
                {
                    no_: 'No.',
                    waktu: 'Waktu',
                    windspeed: 'Windspeed (knots)',
                    windspeed_km: 'Windspeed (km/h)',
                    direction: 'Direction',
                }
            )
        }
        newData.push(
            {
                no_: item.row_ || '',
                waktu: item.conv_waktu || '',
                windspeed: item.windspeed,
                windspeed_km: item.windspeed_km,
                direction: item.nama_en || '',
            }
        )
    })

    const borderS = {
        right: {
            style: "thin",
            color: "000000"
        },
        left: {
            style: "thin",
            color: "000000"
        },
        top: {
            style: "thin",
            color: "000000"
        },
        bottom: {
            style: "thin",
            color: "000000"
        }
    }

    let sheet = XLSX.utils.json_to_sheet([]);
    let merge = []
    const spaceKosong = 6

    for (var i = 0; i < newData.length; i++) {
        var j = 0;

        let isHead = i == 0

        for (var rw in newData[i]) {
            var cellRef = XLSX.utils.encode_cell({ r: i, c: j });
            var rawValue = newData[i][rw];
            var cellValue = { v: rawValue, t: 's' };
            cellValue.s = { border: borderS }

            if (rw.includes('windspeed')) {
                cellValue.s.alignment = { horizontal: 'right' }
            }

            if (isHead) {
                cellValue.s.fill = { fgColor: { rgb: 'C6F6D5' } }
                cellValue.s.font = { bold: true }
            } else {
                cellValue.s.fill = { fgColor: { rgb: 'F0FFF4' } }
            }

            XLSX.utils.sheet_add_aoa(sheet, [[cellValue]], { origin: { r: i, c: j } });

            j++;

        }
    }

    XLSX.utils.insert_rows(sheet, 0, spaceKosong);
    XLSX.utils.sheet_add_aoa(sheet, [[{
        v: 'History Windspeed', t: 's', s: {
            font: {
                bold: true,
                // sz: 14
            }
        }
    }]], { origin: { r: 0, c: 0 } });
    XLSX.utils.sheet_add_aoa(sheet, [[{
        v: '', t: 's', s: {
            font: {
                bold: true,
                // sz: 14
            }
        }
    }]], { origin: { r: 1, c: 0 } });
    XLSX.utils.sheet_add_aoa(sheet, [[{
        v: params.nm_lokasi, t: 's', s: {
            font: {
                bold: true,
                // sz: 14
            }
        }
    }]], { origin: { r: 2, c: 0 } });
    XLSX.utils.sheet_add_aoa(sheet, [[{
        v: params.periode_text, t: 's', s: {
            font: {
                bold: true,
                // sz: 14
            }
        }
    }]], { origin: { r: 3, c: 0 } });
    XLSX.utils.sheet_add_aoa(sheet, [[{
        v: params.print_text, t: 's', s: {
            font: {
                bold: false,
                // sz: 14
            }
        }
    }]], { origin: { r: 4, c: 0 } });
    XLSX.utils.sheet_add_aoa(sheet, [[{
        v: '', t: 's', s: {
            font: {
                bold: true,
                // sz: 14
            }
        }
    }]], { origin: { r: 5, c: 0 } });
    merge.push(
        { s: { r: 0, c: 0 }, e: { r: 0, c: 4 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: 4 } },
        { s: { r: 3, c: 0 }, e: { r: 3, c: 4 } },
        { s: { r: 4, c: 0 }, e: { r: 4, c: 4 } },
    )

    sheet['!cols'] = [];
    sheet['!cols'][0] = { wch: 10 };
    sheet['!cols'][1] = { wch: 20 };
    sheet['!cols'][2] = { wch: 20 };
    sheet['!cols'][3] = { wch: 20 };
    sheet['!cols'][4] = { wch: 20 };

    sheet["!merges"] = merge;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, params.periode_text.replace("Periode ", ' ').replace("sampai", "-"));
    // XLSX_CALC(workbook);
    // addFormulasAndCalculate(workbook)
    XLSX.writeFile(workbook, "History Windspeed " + params.nm_lokasi + params.periode_text.replace("Periode ", ' ').replace("sampai", "-") + ".xlsx", { compression: true });
}

const showToast = (message, bg) => {
    toastEl.value.message = message;
    toastEl.value.bg = `text-bg-${bg}`;
    toast.value.show();

    setTimeout(() => {
        toast.value.hide();
    }, 3000);
};

const handleChangeSpeedType = (value) => {
    const temp = gridData
    if (value) {
        temp.value.speed_type = value.code
    } else {
        temp.value.speed_type = null
    }
    gridData.value = temp.value
    fetchData();
};

const handleChangePeriodeAwal = (value) => {
    if (inputPeriodeAwalRef.value) {
        inputPeriodeAwalRef.value.blur()
    }
    const temp = gridData
    temp.value.periode_awal = moment(value).format('YYYY-MM-DD')
    // temp.value.periode_akhir = moment(value).format('YYYY-MM-DD')
    gridData.value = temp.value

    periodeAwalLimit2.value = new Date(value);
    // selectedPeriodeAkhir.value = new Date(value);
    fetchData();
};

const handleChangePeriodeAkhir = (value) => {
    if (inputPeriodeAkhirRef.value) {
        inputPeriodeAkhirRef.value.blur()
    }
    const temp = gridData
    temp.value.periode_akhir = moment(value).format('YYYY-MM-DD')
    gridData.value = temp.value

    periodeAkhirLimit1.value = new Date(value)
    fetchData();
};

const handleChangeLokasi = (value) => {
    const temp = gridData
    if (value) {
        temp.value.lokasi = value.code
    } else {
        temp.value.lokasi = null
    }
    gridData.value = temp.value
    fetchData();
};

const clickEventHandler = async (event) => {
    const targetChartDateButton = event.target.closest('.button-chart-date');
    if (targetChartDateButton) {

        let sameButton = false
        if (targetChartDateButton.classList.contains('btn-danger')) {
            // console.log('same');
            sameButton = true
        }
        const id = targetChartDateButton.getAttribute('data-id');
        const date = targetChartDateButton.getAttribute('data-date');
        // console.log({id, date});
        const arrEl = document.getElementsByClassName('button-chart-date')
        const arr = Array.from(arrEl)
        arr.map(elementC => {
            elementC.classList.remove('btn-danger')
            elementC.classList.remove('btn-secondary')
            elementC.classList.add('btn-secondary')
        })
        targetChartDateButton.classList.remove('btn-secondary')
        targetChartDateButton.classList.add('btn-danger')

        if (!sameButton) {
            await genChart(date)
        }
    }
}

const handleTabChange = (event) => {
    const activeTab = event.target.getAttribute('aria-controls');
    console.log(`Active tab changed to: ${activeTab}`);
    setTimeout(() => {
        table1Instance.columns.adjust().draw()
        // console.log(maxChartDateBtn.value);
        if (activeTab == 'nav-chart') {
            // console.log('yay');
            // console.log(maxChartDateBtn.value);
            if (maxChartDateBtn.value) {
                const btnDanger = document.querySelector('#button-chart-date-container button.btn-danger');
                // console.log(btnDanger.textContent);    
                if (btnDanger.textContent == maxChartDateBtn.value) {
                    scrollToRight()
                }
            }
        }
    }, 300);
};

const scrollToRight = () => {
    const scrollableDiv = document.getElementById('button-chart-date-container');
    // Scrolls to the maximum right
    scrollableDiv.scrollLeft = scrollableDiv.scrollWidth;
    console.log('scrolled');
    
  }

onMounted(async () => {
    table1Instance = table1.value.dt;
    inputPeriodeAwalRef.value = document.getElementById('periode_awal')
    inputPeriodeAkhirRef.value = document.getElementById('periode_akhir')
    await fetchDataSelect();
    // console.log(gridData.value)
    await fetchData();

    // event listeners
    document.removeEventListener('click', clickEventHandler);
    document.addEventListener('click', clickEventHandler);

    toast.value = new bootstrap.Toast(document.getElementById('toast'), { delay: 3000 });

    const tabElements = document.querySelectorAll('#nav-tab-data button[data-bs-toggle="tab"]');
    tabElements.forEach(tab => {
        tab.addEventListener('shown.bs.tab', handleTabChange);
    });
});

onBeforeUnmount(() => {
    document.removeEventListener('click', clickEventHandler);
});
</script>

<template>
    <div class="toast-container position-fixed top-0 end-0 p-3">
        <div id="toast" :class="'toast align-items-center border-0 ' + toastEl.bg" role="alert" aria-live="assertive"
            aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    {{ toastEl.message }}
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                    aria-label="Close"></button>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="card">
            <div class="card-header">
                <h5 class="mb-0">History Windspeed</h5>
            </div>
            <div class="card-body" id="sample-width">
                <div class="row">
                    <div class="col-3">
                        <label for="lokasi" class="form-label">Lokasi</label>
                        <v-select id="lokasi" v-model="selectedLokasiValue" :options="selectLokasiOptions"
                            :clearable="false" @update:modelValue="handleChangeLokasi" placeholder="Harap pilih lokasi"
                            required></v-select>
                    </div>
                    <div class="col-3">
                        <label for="periode_awal" class="form-label">Periode Awal</label>
                        <Datepicker id="periode_awal" class="form-control bg-white" v-model="selectedPeriodeAwal"
                            @update:modelValue="handleChangePeriodeAwal" :inputFormat="periodeFormat"
                            :upper-limit="periodeAkhirLimit1" :lower-limit="periodeAwalLimit1"
                            placeholder="Pilih periode awal" startingView="day" minimumView="day" />
                    </div>
                    <div class="col-3">
                        <label for="periode_akhir" class="form-label">Periode Akhir</label>
                        <Datepicker id="periode_akhir" class="form-control bg-white" v-model="selectedPeriodeAkhir"
                            @update:modelValue="handleChangePeriodeAkhir" :inputFormat="periodeFormat"
                            :upper-limit="periodeAkhirLimit2" :lower-limit="periodeAwalLimit2"
                            placeholder="Pilih periode akhir" startingView="day" minimumView="day" />
                    </div>
                    <div class="col-3">
                        <label for="speed_type" class="form-label">Satuan Kecepatan</label>
                        <v-select id="speed_type" v-model="selectedSpeedTypeValue" :options="selectSpeedTypeOptions"
                            :clearable="false" @update:modelValue="handleChangeSpeedType" required
                            placeholder="Harap pilih satuan kecepatan">
                        </v-select>
                    </div>
                    <div class="col-6 mt-0 mt-md-3">

                    </div>
                    <div class="col-6 mt-0 mt-md-3">
                        <div class="flex text-end">
                            <button class="btn btn-primary mb-2 mr-2" @click="printPDF">Cetak PDF</button>
                            <button class="btn btn-success mb-2" @click="printExcel">Cetak Excel</button>
                        </div>
                    </div>
                </div>

                <nav>
                    <div class="nav nav-tabs" id="nav-tab-data" role="tablist">
                        <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#nav-data" type="button"
                            role="tab" aria-controls="nav-data" aria-selected="true">Data</button>
                        <button class="nav-link" data-bs-toggle="tab" data-bs-target="#nav-chart" type="button"
                            role="tab" aria-controls="nav-chart" aria-selected="false">Chart</button>
                    </div>
                </nav>
                <div class="tab-content">
                    <div class="tab-pane fade show active" id="nav-data" role="tabpanel" aria-labelledby="nav-data-tab"
                        tabindex="0">
                        <DataTable :columns="columns" :data="tableData" :options="dataTableOptions" ref="table1"
                            class="table table-bordered table-striped" width="100%"></DataTable>
                    </div>
                    <div class="tab-pane fade" id="nav-chart" role="tabpanel" aria-labelledby="nav-chart-tab"
                        tabindex="0">
                        <div class="mt-3">
                            <div id="button-chart-date-container" class="d-flex overflow-x-auto gap-2 p-2">

                            </div>
                            <div class="table-responsivex" id="chart-container">
                                <div id="chart-target-el" ref="chart" style="width: 100vw; height: 400px;"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<style>
@import 'bootstrap';
@import 'datatables.net-bs5';
</style>

<style scoped>
.select2-dropdown {
    z-index: 99999 !important;
}

#button-chart-date-container {
    white-space: nowrap;
}
</style>
