<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useLoading } from 'vue-loading-overlay'
import * as Popper from "@popperjs/core"
import Api from '../../services/api';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Swal from 'sweetalert2';
import $ from 'jquery';

import 'vue-select/dist/vue-select.css';

const deleteHistoryData = ref({ bts_hari_delete: 1, delete_system: false, bts_system: 1 })
const selectedDeletedBySystemValue = ref(null);
const selectDeletedBySystemOptions = ref([
    { code: true, label: 'Ya' },
    { code: false, label: 'Tidak' }
]);

import Datepicker from 'vue3-datepicker'
import moment from 'moment';
import 'moment/locale/id';

const modalDelHistoryManualData2 = ref({ periode_awal: '', periode_akhir: '' });
const periodeFormat2 = 'dd-MM-yyyy'
const selectedPeriodeAwal2 = ref(new Date())
const selectedPeriodeAkhir2 = ref(new Date())
const inputPeriodeAwalRef2 = ref(null)
const inputPeriodeAkhirRef2 = ref(null)
const periodeAwalLimit12 = ref(new Date());
const periodeAkhirLimit12 = ref(new Date());
const periodeAwalLimit22 = ref(new Date());
const periodeAkhirLimit22 = ref(new Date());

const loading = useLoading({});
const configObject = { keyboard: false, backdrop: 'static' }
const toast = ref(null);
const toastEl = ref({});

const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

const preload = async () => {
    const loader = loading.show({});
    try {
        const res = await Api.get('api/delete_history/preload', {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        // console.log(res.data.data);
        deleteHistoryData.value.bts_hari_delete = res.data.data.bts_hari_delete
        deleteHistoryData.value.delete_system = res.data.data.delete_system
        deleteHistoryData.value.bts_system = res.data.data.bts_system

        selectedDeletedBySystemValue.value = res.data.data.delete_system ? { code: true, label: 'Ya' } : { code: false, label: 'Tidak' }
        if (res.data.data.delete_system) {
            // $("#target_1").show()
        } else {
            // $("#target_1").hide()
        }

        // nav kedua
        const { data } = await Api.get('api/delete_history/manualInfo', {
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + localStorage.token
			},
		});

		// console.log(data.data[0]);

		periodeAwalLimit12.value = new Date(data.data[0].min_param);
		periodeAwalLimit22.value = new Date(data.data[0].min_param);
		periodeAkhirLimit12.value = new Date(data.data[0].max_param);
		periodeAkhirLimit22.value = new Date(data.data[0].max_param);

		selectedPeriodeAwal2.value = new Date(data.data[0].min_param)
		selectedPeriodeAkhir2.value = new Date(data.data[0].max_param)

		modalDelHistoryManualData2.value.periode_awal = moment(data.data[0].min_param).format('YYYY-MM-DD')
		modalDelHistoryManualData2.value.periode_akhir = moment(data.data[0].max_param).format('YYYY-MM-DD')
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        loader.hide()

    }
};

const handleChangePeriodeAwal2 = (value) => {
	if (inputPeriodeAwalRef2.value) {
		inputPeriodeAwalRef2.value.blur()
	}
	const temp = modalDelHistoryManualData2
	temp.value.periode_awal = moment(value).format('YYYY-MM-DD')
	modalDelHistoryManualData2.value = temp.value

	periodeAwalLimit22.value = new Date(value)
};

const handleChangePeriodeAkhir2 = (value) => {
	if (inputPeriodeAkhirRef2.value) {
		inputPeriodeAkhirRef2.value.blur()
	}
	const temp = modalDelHistoryManualData2
	temp.value.periode_akhir = moment(value).format('YYYY-MM-DD')
	modalDelHistoryManualData2.value = temp.value

	periodeAkhirLimit12.value = new Date(value)
};

const submitDelHistoryManualHandler2 = async () => {

// return console.log(moment(modalDelHistoryManualData2.value.periode_akhir).format('DD MMMM YYYY'));
// return console.log(modalDelHistoryManualData2.value);

const { isConfirmed, value } = await Swal.fire({
    text: 'Anda yakin akan menghapus data transaksi mulai ' + moment(modalDelHistoryManualData2.value.periode_awal).format('DD MMMM YYYY') + ' s/d ' + moment(modalDelHistoryManualData2.value.periode_akhir).format('DD MMMM YYYY') + ' ?',
    showCancelButton: true,
    confirmButtonText: 'Ya',
    cancelButtonText: 'Batal',
    inputPlaceholder: 'Alasan reject',
    showLoaderOnConfirm: true,
    confirmButtonClass: 'btn btn-primary w-xs me-2',
    cancelButtonClass: 'btn btn-danger w-xs',
    buttonsStyling: false,
})

if (!isConfirmed) {
    return
}

const loader = loading.show({});
try {
    const { data } = await Api.post(`api/delete_history/saveManual`, modalDelHistoryManualData2.value, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + localStorage.token
        },
    });

    if (data.success) {
        await preload()
        showToast(data.message, 'success');
    } else {
        showToast(data.message, 'danger');
    }
} catch (error) {
    console.error('Error submitting form:', error);
    showToast(error, 'danger');
} finally {
    loader.hide()
}
};

const showToast = (message, bg) => {
    // console.log({message, bg});
    toastEl.value.message = message;
    toastEl.value.bg = `text-bg-${bg}`;
    toast.value.show();

    setTimeout(() => {
        toast.value.hide();
    }, 10000);
};

const handleChangeDeletedBySystem = (value) => {
    const temp = deleteHistoryData
    if (value) {
        temp.value.delete_system = value.code
        if (value.code) {
            // $("#target_1").show()
        } else {
            // $("#target_1").hide()
        }
    } else {
        temp.value.delete_system = null
    }
    deleteHistoryData.value = temp.value
};

const submitDeleteHistoryHandler = async () => {

    // return console.log(deleteHistoryData.value);

    const loader = loading.show({});
    try {
        const { data } = await Api.post(`api/delete_history/save`, deleteHistoryData.value, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        if (data.success) {
            showToast(data.message, 'success');
            await preload()
        } else {
            showToast(data.message, 'danger');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        showToast(error, 'danger');
    } finally {
        loader.hide()
    }
};

const clickEventHandler = async (event) => {

}

onMounted(async () => {
    await preload()

    inputPeriodeAwalRef2.value = document.getElementById('periode_awal_2')
	inputPeriodeAkhirRef2.value = document.getElementById('periode_akhir_2')
    // event listeners
    document.removeEventListener('click', clickEventHandler);
    document.addEventListener('click', clickEventHandler);

    toast.value = new bootstrap.Toast(document.getElementById('toast-navbar'), { delay: 10000 });
});

onBeforeUnmount(() => {
    document.removeEventListener('click', clickEventHandler);
});
</script>

<template>
    <div class="toast-container position-fixed top-0 end-0 p-3">
        <div id="toast-navbar" :class="'toast align-items-center border-0 ' + toastEl.bg" role="alert"
            aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    {{ toastEl.message }}
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                    aria-label="Close"></button>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="card">
            <div class="card-header">
                <h5 class="mb-0">Hapus History</h5>
            </div>
            <div class="card-body" id="">
                <nav>
                    <div class="nav nav-tabs" id="nav-tab-data" role="tablist">
                        <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#nav-scheduler"
                            type="button" role="tab" aria-controls="nav-scheduler"
                            aria-selected="true">Scheduler</button>
                        <button class="nav-link" data-bs-toggle="tab" data-bs-target="#nav-manual" type="button"
                            role="tab" aria-controls="nav-manual" aria-selected="false">Manual</button>
                    </div>
                </nav>
                <div class="tab-content">
                    <div class="tab-pane fade show active" id="nav-scheduler" role="tabpanel"
                        aria-labelledby="nav-scheduler-tab" tabindex="0">
                        <form method="post" id="delete-history-form" @submit.prevent="submitDeleteHistoryHandler">
                            <div class="row mt-3">
                                <div class="col-4">
                                    <label for="bts_hari_delete" class="form-label">Batas hapus data (satuan hari)</label>
                                    <input type="number" name="bts_hari_delete" class="form-control" step="1" min="1"
                                        onkeyup="this.value=this.value.replace(/[^\d]/,'')"
                                        v-model="deleteHistoryData.bts_hari_delete" required placeholder="Masukkan batas">
                                </div>
                                <div class="col-4">
                                    <label for="delete_system" class="form-label">Hapus by system?</label>
                                    <v-select id="delete_system" v-model="selectedDeletedBySystemValue"
                                        :options="selectDeletedBySystemOptions" :clearable="false"
                                        @update:modelValue="handleChangeDeletedBySystem" required
                                        placeholder="Hapus by system?">
                                    </v-select>
                                </div>
                                <div class="col-4 d-none" id="target_1">
                                    <label for="bts_system" class="form-label">Batas notifikasi system</label>
                                    <input type="number" name="bts_system" class="form-control" step="1" min="1"
                                        onkeyup="this.value=this.value.replace(/[^\d]/,'')"
                                        v-model="deleteHistoryData.bts_system" required>
                                </div>
                            </div>
                            <button type="submit" form="delete-history-form" class="btn btn-success mt-3">Simpan</button>
                        </form>
                    </div>
                    <div class="tab-pane fade" id="nav-manual" role="tabpanel" aria-labelledby="nav-manual-tab"
                        tabindex="0">
                        <form method="post" id="del-history-manual-form-2"
                            @submit.prevent="submitDelHistoryManualHandler2">
                            <div class="row mt-3">
                                <div class="col-12">
                                    <label for="periode_awal_2" class="form-label">Periode
                                        Awal</label>
                                    <Datepicker id="periode_awal_2" class="form-control bg-white"
                                        v-model="selectedPeriodeAwal2" :inputFormat="periodeFormat2" @update:modelValue="handleChangePeriodeAwal2"
                                        :upper-limit="periodeAkhirLimit12" :lower-limit="periodeAwalLimit12"
                                        placeholder="Pilih periode awal" startingView="day" minimumView="day" />
                                </div>
                                <div class="col-12 mt-3">
                                    <label for="periode_akhir_2" class="form-label">Periode
                                        Akhir</label>
                                    <Datepicker id="periode_akhir_2" class="form-control bg-white"
                                        v-model="selectedPeriodeAkhir2" @update:modelValue="handleChangePeriodeAkhir2"
                                        :inputFormat="periodeFormat2" :upper-limit="periodeAkhirLimit22"
                                        :lower-limit="periodeAwalLimit22" placeholder="Pilih periode akhir"
                                        startingView="day" minimumView="day" />
                                </div>
                            </div>
                            <button type="submit" form="del-history-manual-form-2" class="btn btn-success mt-3">Hapus</button>
                        </form>
                    </div>
                </div>
            </div>
            <!-- <div class="card-footer">
                <button type="submit" form="delete-history-form" class="btn btn-success">Simpan</button>
            </div> -->
        </div>
    </div>
</template>

<style>
@import 'bootstrap';
@import 'datatables.net-bs5';
</style>

<style scoped>
.select2-dropdown {
    z-index: 99999 !important;
}
</style>
