<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useLoading } from 'vue-loading-overlay'
import * as Popper from "@popperjs/core"
import Api from '../../services/api';
import { useRouter } from 'vue-router'

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Swal from 'sweetalert2';

import 'vue-select/dist/vue-select.css';
import moment from 'moment';

const startDateStr = ref('')
const endDateStr = ref('')

const loading = useLoading({});
const configObject = { keyboard: false, backdrop: 'static' }
const toast = ref(null);
const toastEl = ref({});
const backupChecked = ref(false)

const router = useRouter()

const preload = async () => {
    const loader = loading.show({});
    try {
        const { data } = await Api.get('api/reset_data/preload', {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        startDateStr.value = moment(new Date(data.data.min_)).format('DD MMMM YYYY');
        endDateStr.value = moment(new Date(data.data.max_)).format('DD MMMM YYYY');
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        loader.hide()

    }
};

const showAfterModal = async () => {
    await Swal.fire({
        icon: "success",
        html: `Data windspeed berhasil di-reset`,
    });
    router.push({ name: 'windspeed' })
}

const showToast = (message, bg) => {
    toastEl.value.message = message;
    toastEl.value.bg = `text-bg-${bg}`;
    toast.value.show();

    setTimeout(() => {
        toast.value.hide();
    }, 10000);
};

const resetHandler = async () => {
    const text_ = backupChecked.value ? 'Anda yakin akan me-reset data Windspeed ?' : 'Anda yakin akan me-reset data Windspeed tanpa backup ?';
    const confirmBtn = backupChecked.value ? 'Ya' : 'Lanjut'
    const { isConfirmed, value } = await Swal.fire({
        text: text_,
        showCancelButton: true,
        confirmButtonText: confirmBtn,
        cancelButtonText: 'Batal',
        inputPlaceholder: 'Alasan reject',
        showLoaderOnConfirm: true,
        confirmButtonClass: 'btn btn-primary w-xs me-2',
        cancelButtonClass: 'btn btn-danger w-xs',
        buttonsStyling: false,
    })

    if (!isConfirmed) {
        return
    }

    const loader = loading.show({});
    try {
        const { data } = await Api.post(`api/reset_data/reset`,
            {
                backup: backupChecked.value
            },
            {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + localStorage.token
                },
            });

        if (data.success) {
            loader.hide()
            await showAfterModal()
        } else {
            loader.hide()
            showToast(data.message, 'danger');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        showToast(error, 'danger');
        loader.hide()
    } finally {
        // loader.hide()
    }
};

const clickEventHandler = async (event) => {

}

onMounted(async () => {
    await preload()

    // event listeners
    document.removeEventListener('click', clickEventHandler);
    document.addEventListener('click', clickEventHandler);

    toast.value = new bootstrap.Toast(document.getElementById('toast-navbar'), { delay: 10000 });
});

onBeforeUnmount(() => {
    document.removeEventListener('click', clickEventHandler);
});
</script>

<template>
    <div class="toast-container position-fixed top-0 end-0 p-3">
        <div id="toast-navbar" :class="'toast align-items-center border-0 ' + toastEl.bg" role="alert"
            aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    {{ toastEl.message }}
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                    aria-label="Close"></button>
            </div>
        </div>
    </div>

    <div class="container">
        <div :class="['card', (startDateStr != '' && endDateStr != '') ? '' : 'd-none']">
            <div class="card-header">
                <h5 class="mb-0">Reset Data</h5>
            </div>
            <div class="card-body">
                <div>
                    <span>Reset data Windspeed:</span>
                    <li>
                        Data dalam system {{ startDateStr }} sd {{ endDateStr }}
                    </li>
                    <li>
                        Reset sequence (start with 1)
                    </li>
                    <br>
                    <div class="form-check">
                        <input class="form-check-input border border-secondary border-2" type="checkbox" v-model="backupChecked" value=""
                            id="checkBackup">
                        <label class="form-check-label" for="checkBackup">
                            Backup data Windspeed
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <button type="button" class="btn btn-success" @click="resetHandler">Reset</button>
            </div>
        </div>
    </div>
</template>

<style>
@import 'bootstrap';
@import 'datatables.net-bs5';
</style>

<style scoped>
.select2-dropdown {
    z-index: 99999 !important;
}
</style>
