<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import { useLoading } from 'vue-loading-overlay'
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';
import Api from '../../services/api';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Swal from 'sweetalert2';

import 'vue-select/dist/vue-select.css';

import $ from 'jquery';

let tableLokasiInstance;
const tableLokasi = ref();
const tableLokasiData = ref([]);
let tableDeviceInstance;
const tableDevice = ref();
const tableDeviceData = ref([]);

const lokasiModalLabel = ref('')
const deviceModalLabel = ref('')

const typeSubmit = ref()
const isIdDeviceDisabled = ref(true)
const isIdDeviceHintShow = ref(true)

const modalLokasiData = ref({ key_id: null, key_id_unit_kerja: null, keterangan: null, status_lokasi: true, sound: null, time_zone: null });
let modalLokasiInstance = null;
const modalDeviceData = ref({ key_id: null, key_id_header: null, key_id_kategori: null, nama_alat: null, merek: null, sn_alat: null, id_device: null, status_alat: true, keterangan: null });
let modalDeviceInstance = null;

const modalAudioUrl = ref(null)

const selectedUnitKerjaValue = ref(null);
const selectUnitKerjaOptions = ref([]);
const selectedStatusLokasiValue = ref(null);
const selectUnitStatusOptions = ref([
    { code: true, label: 'Aktif' },
    { code: false, label: 'Tidak Aktif' }
]);

const selectedTimeZoneValue = ref(null);
const selectTimeZoneOptions = ref([
    { code: 'WIB', label: 'WIB' },
    { code: 'WITA', label: 'WITA' }, 
    { code: 'WIT', label: 'WIT' }
]);

const selectedKategoriValue = ref(null);
const selectKategoriOptions = ref([]);
const selectedStatusAlatValue = ref(null);
const selectStatusAlatOption = ref([
    { code: true, label: 'Aktif' },
    { code: false, label: 'Tidak Aktif' }
]);

const loading = useLoading({});
const configObject = { keyboard: false, backdrop: 'static' }
const toast = ref(null);
const toastEl = ref({});
const dataTableOptions = ref({
    order: [],
    scrollX: true,
    initComplete: function () {
        // 
    },
    drawCallback: function (settings) {
        const elementsToRemoveShown = document.querySelectorAll('tr.shown > td.details-control');
        elementsToRemoveShown.forEach(element => {
            const parentTR = element.closest('tr');
            if (parentTR) {
                parentTR.classList.remove('shown');
            }
        });
    },
});

DataTable.use(DataTablesCore);

const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

const columns = [
    {
        className: 'details-control text-center',
        data: null,
        defaultContent: '',
        width: '20px',
        title: "#",
        orderable: false
    },
    // { title: "ID", data: 'key_id', width: "20px" },
    { title: "No.", data: 'row_', width: "20px" },
    { title: "Keterangan", data: 'keterangan' },
    { title: "Zona Waktu", data: 'time_zone' },
    {
        title: 'Status',
        orderable: false,
        data: null,
        width: '80px',
        render: function (data, type, row) {
            const text = row.status_lokasi ? 'Aktif' : 'Tidak aktif';
            const color = row.status_lokasi ? 'bg-success' : 'bg-secondary';

            const div = "<span class='badge " + color + "'>" + text + "</span>"
            return div;
        }
    },
    {
        title: 'Aksi',
        orderable: false,
        data: null,
        width: '80px',
        render: function (data, type, row) {
            const disabled = false ? "disabled" : ""
            const btn_play_lokasi = row.path_file_real ? `<button class="btn btn-sm btn-outline-secondary btn-play-lokasi mr-2" ${disabled} title="Play Sound" data-id=${row.key_id} data-url="${row.path_file_real}"><i class="operation-icon fas fa-volume-up"></i></button>` : ''
            const div = `<div class="d-flex">
                                ${btn_play_lokasi}
                                <button class="btn btn-sm btn-outline-primary btn-edit-lokasi mr-2" ${disabled} title="Ubah Lokasi" data-id=${row.key_id} data-url="${row.path_file_real}"><i class="operation-icon fas fa-pen"></i></button>
                                <button class="btn btn-sm btn-outline-danger btn-remove-lokasi" ${disabled} title="Hapus Lokasi" data-id=${row.key_id}><i class="operation-icon fas fa-trash-alt"></i></button>
                            </div>`;
            return div;
        }
    },
];

const columnsDevice = [
    { title: "ID", data: 'key_id', width: "20px" },
    { title: "Kategori", data: 'kategori' },
    { title: "Nama Alat", data: 'nama_alat' },
    { title: "Merek", data: 'merek' },
    { title: "SN. Alat", data: 'sn_alat' },
    { title: "ID Device", data: 'id_device' },
    {
        title: 'Status',
        orderable: false,
        data: null,
        render: function (data, type, row) {
            const text = row.status_alat ? 'Aktif' : 'Tidak aktif';
            const color = row.status_alat ? 'bg-success' : 'bg-secondary';

            const div = "<span class='badge " + color + "'>" + text + "</span>"
            return div;
        }
    },
    { title: "Keterangan", data: 'keterangan' },
    {
        title: 'Aksi',
        orderable: false,
        data: null,
        render: function (data, type, row) {
            const disabled = false ? "disabled" : ""
            const div = `<div class="d-flex">
                                <button class="btn btn-sm btn-outline-primary btn-edit-device mr-2" ${disabled} title="Ubah Device" data-id=${row.key_id}><i class="operation-icon fas fa-pen"></i></button>
                                <button class="btn btn-sm btn-outline-danger btn-remove-device" ${disabled} title="Hapus Device" data-id=${row.key_id}><i class="operation-icon fas fa-trash-alt"></i></button>
                            </div>`;
            return div;
        }
    },
];

const handleStatusLokasiChange = (value) => {
    const temp = modalLokasiData
    temp.value.status_lokasi = value.code
    modalLokasiData.value = temp.value
};

const handleTimeZoneChange = (value) => {
    const temp = modalLokasiData
    temp.value.time_zone = value.code
    modalLokasiData.value = temp.value
};

const handleStatusAlatChange = (value) => {
    const temp = modalDeviceData
    temp.value.status_alat = value.code
    modalDeviceData.value = temp.value
};

const createLokasi = async () => {
    $('#fileTargetIdEl').hide()
    $("#fileSoundIdEl").val(null).trigger('reset')
    const loader = loading.show({});
    try {
        const { data } = await Api.get(`api/master/lokdev/get_info_create`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        selectUnitKerjaOptions.value = data.unit_kerja
        selectedUnitKerjaValue.value = null

        modalLokasiData.value.key_id = null;
        modalLokasiData.value.key_id_unit_kerja = null;
        modalLokasiData.value.keterangan = null;
        modalLokasiData.value.status_lokasi = true;
        modalLokasiData.value.sound = null
        selectedStatusLokasiValue.value = { code: true, label: 'Aktif' }

        selectedTimeZoneValue.value = { code: 'WIB', label: 'WIB' }
        modalLokasiData.value.time_zone = 'WIB'

        showLokasiModal(null, 'create')
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide()
    }
}

const createDevice = async header_id => {
    const loader = loading.show({});
    try {
        const { data } = await Api.get(`api/master/lokdev/get_info_device_create`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        selectKategoriOptions.value = data.kategori
        selectedKategoriValue.value = null
        // const modalDeviceData = ref({ key_id: null, key_id_kategori: null, nama_alat: null, merek: null, sn_alat: null, status_alat: true, keterangan: null });
        modalDeviceData.value.key_id = null;
        modalDeviceData.value.key_id_header = header_id;
        modalDeviceData.value.key_id_kategori = null;
        modalDeviceData.value.nama_alat = null;
        modalDeviceData.value.merek = null;
        modalDeviceData.value.sn_alat = null;
        modalDeviceData.value.id_device = null;
        modalDeviceData.value.status_alat = true;
        modalDeviceData.value.keterangan = null;
        selectedStatusAlatValue.value = { code: true, label: 'Aktif' }
        showDeviceModal(null, 'create')
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide()
    }
}

const editLokasi = async (id, url) => {
    $("#fileSoundIdEl").val(null).trigger('reset')
    const loader = loading.show({});
    try {
        const { data } = await Api.get(`api/master/lokdev/get_info`, {
            params: { id },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        selectUnitKerjaOptions.value = data.unit_kerja
        selectedUnitKerjaValue.value = data.user_unit_kerja

        modalLokasiData.value.key_id = id;
        modalLokasiData.value.key_id_unit_kerja = data.data[0].key_id_unit_kerja;
        modalLokasiData.value.keterangan = data.data[0].keterangan;
        modalLokasiData.value.status_lokasi = data.data[0].status_lokasi;
        modalLokasiData.value.sound = null
        selectedStatusLokasiValue.value = data.data[0].status_lokasi ? { code: true, label: 'Aktif' } : { code: false, label: 'Tidak Aktif' }

        // selectedTimeZoneValue.value = data.data[0].time_zone == 'WIB' ? { code: 'WIB', label: 'WIB' } : data.data[0].time_zone == 'WITA'
        // selectedTimeZoneValue.value = selectTimeZoneOptions.value.find(item => item.label === x)
        // console.log(selectTimeZoneOptions.value.find(item => item.label == data.data[0].time_zone));
        selectedTimeZoneValue.value = selectTimeZoneOptions.value.find(item => item.label == data.data[0].time_zone)
        modalLokasiData.value.time_zone = data.data[0].time_zone
        

        if (url == 'null') {
            modalAudioUrl.value = null
            $('#fileTargetIdEl').hide()
        } else {
            modalAudioUrl.value = url
            $('#fileTargetIdEl').show()
        }

        showLokasiModal(id, 'edit')
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide()
    }
}

const editDevice = async id => {
    const loader = loading.show({});
    try {
        const { data } = await Api.get(`api/master/lokdev/get_info_device`, {
            params: { id },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        selectKategoriOptions.value = data.kategori
        selectedKategoriValue.value = data.user_kategori

        modalDeviceData.value.key_id = id;
        modalDeviceData.value.key_id_header = data.data[0].key_id_header
        modalDeviceData.value.key_id_kategori = data.data[0].key_id_kategory;
        modalDeviceData.value.nama_alat = data.data[0].nama_alat;
        modalDeviceData.value.merek = data.data[0].merek;
        modalDeviceData.value.sn_alat = data.data[0].sn_alat;
        modalDeviceData.value.id_device = data.data[0].id_device;
        modalDeviceData.value.keterangan = data.data[0].keterangan;

        // v1
        // selectedStatusAlatValue.value = data.data[0].status_alat ? { code: true, label: 'Aktif' } : { code: false, label: 'Tidak Aktif' }
        // modalDeviceData.value.status_alat = data.data[0].status_alat;
        
        // v2
        selectedStatusAlatValue.value = { code: true, label: 'Aktif' }
        modalDeviceData.value.status_alat = true;
        
        showDeviceModal(id, 'edit')
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide()
    }
}

const showLokasiModal = (id, operation) => {
    lokasiModalLabel.value = operation === 'edit' ? 'Ubah Lokasi' : 'Tambah Lokasi';
    typeSubmit.value = operation === 'edit' ? 'edit' : 'create';
    operation === 'edit' ? $('#id_device_hint').hide() : $('#id_device_hint').hide()

    if (!modalLokasiInstance) {
        const modalElement = document.getElementById('lokasi-modal');
        modalLokasiInstance = new bootstrap.Modal(modalElement, configObject);
    }
    modalLokasiInstance.show();
};

const showDeviceModal = (id, operation) => {
    deviceModalLabel.value = operation === 'edit' ? 'Ubah Device' : 'Tambah Device';
    // isIdDeviceDisabled.value = operation === 'edit' ? false : true;
    // isIdDeviceHintShow.value = operation === 'edit' ? 'd-none' : '';
    isIdDeviceDisabled.value = true;
    isIdDeviceHintShow.value = '';

    if (!modalDeviceInstance) {
        const modalElement = document.getElementById('device-modal');
        modalDeviceInstance = new bootstrap.Modal(modalElement, configObject);
    }
    modalDeviceInstance.show();
};

const handleChangeUnitKerja = (value) => {
    const temp = modalLokasiData
    if (value) {
        temp.value.key_id_unit_kerja = value.code
        temp.value.keterangan = value.label
    } else {
        temp.value.key_id_unit_kerja = null
        temp.value.keterangan = null
    }
    modalLokasiData.value = temp.value
};

const handleChangeKategori = (value) => {
    const temp = modalDeviceData
    if (value) {
        temp.value.key_id_kategori = value.code
    } else {
        temp.value.key_id_kategori = null
    }
    modalDeviceData.value = temp.value
};

const submitLokasiHandler = async () => {
    // return console.log(modalLokasiData.value);
    if (isEmptyObject(modalLokasiData.value.key_id_unit_kerja) || modalLokasiData.value.key_id_unit_kerja == null) {
        Swal.fire({
            icon: "warning",
            text: "Harap isi unit kerja",
        });

        return
    }

    if (isEmptyObject(modalLokasiData.value.time_zone) || modalLokasiData.value.time_zone == null) {
        Swal.fire({
            icon: "warning",
            text: "Harap isi zona waktu",
        });

        return
    }

    if (modalLokasiData.value.sound && $("#fileSoundIdEl").val() != '') {
        const fileSound = document.getElementById('fileSoundIdEl').files[0];

        // check fileFormat
        const splitedFileName = fileSound.name.split('.');
        let validFile = true;
        if (splitedFileName.length == 1) {
            validFile = false;
        } else {
            const ext = splitedFileName[splitedFileName.length - 1].toLowerCase()
            const allowedType = ["mp3"];
            if (!allowedType.includes(ext)) {
                console.log(`ext is ${ext}`);
                validFile = false;
            }
        }
        if (!validFile) {
            return showToast('Format sound tidak sesuai', 'danger');
        }
    } else if (!modalLokasiData.value.key_id) {
        modalLokasiData.value.sound = null
        Swal.fire({
            icon: "warning",
            text: "Harap isi file sound",
        });
        return
    } else {
        modalLokasiData.value.sound = null
    }
    
    const loader = loading.show({});
    try {
        const { data } = await Api.post(`api/master/lokdev/save`, modalLokasiData.value, {
            headers: {
                // "Accept": "application/json",
                // "Content-Type": "application/json",
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        if (data.success) {
            modalLokasiInstance.hide();
            showToast(data.message, 'success');
            fetchData();
        } else {
            showToast(data.message, 'danger');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        showToast(error, 'danger');
    } finally {
        loader.hide()
    }
};

const submitDeviceHandler = async () => {
    if (isEmptyObject(modalDeviceData.value.key_id_kategori) || modalDeviceData.value.key_id_kategori == null) {
        Swal.fire({
            icon: "warning",
            text: "Harap isi kategori",
        });

        return
    }
    // return console.log(modalDeviceData.value);
    const loader = loading.show({});
    try {
        const { data } = await Api.post(`api/master/lokdev/save_device`, modalDeviceData.value, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });

        if (data.success) {
            modalDeviceInstance.hide();
            showToast(data.message, 'success');
            // fetchDataDevice();
            fetchData()
        } else {
            showToast(data.message, 'danger');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        showToast(error, 'danger');
    } finally {
        loader.hide()
    }
};

const fetchData = async () => {
    const loader = loading.show({});
    try {
        const { data } = await Api.get('api/master/lokdev', {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        tableLokasiData.value = data.data;
        // console.log(this);
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        loader.hide()
        setTimeout(() => {
            tableLokasiInstance.columns.adjust().draw()
        }, 0);
    }
};

const fetchDataDevice = async () => {
    const loader = loading.show({});
    try {
        const { data } = await Api.get('api/master/lokdev/device', {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        tableDeviceData.value = data.data;
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        loader.hide()
        setTimeout(() => {
            tableDeviceInstance.columns.adjust().draw()
        }, 0);
    }
};

const showToast = (message, bg) => {
    toastEl.value.message = message;
    toastEl.value.bg = `text-bg-${bg}`;
    toast.value.show();

    setTimeout(() => {
        toast.value.hide();
    }, 3000);
};

const clickEventHandler = async (event) => {
    const targetEditLokasi = event.target.closest('.btn-edit-lokasi');
    if (targetEditLokasi) {
        const id = targetEditLokasi.getAttribute('data-id');
        const url = targetEditLokasi.getAttribute('data-url')
        editLokasi(id, url);
    }

    const targetDeleteLokasi = event.target.closest('.btn-remove-lokasi');
    if (targetDeleteLokasi) {
        const id = targetDeleteLokasi.getAttribute('data-id');
        const { isConfirmed, value } = await Swal.fire({
            text: 'Anda yakin akan menghapus master lokasi ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            inputPlaceholder: 'Alasan reject',
            showLoaderOnConfirm: true,
            confirmButtonClass: 'btn btn-primary w-xs me-2',
            cancelButtonClass: 'btn btn-danger w-xs',
            buttonsStyling: false,
        })

        if (isConfirmed) {
            const loader = loading.show({});
            try {
                const { data } = await Api.delete(`api/master/lokdev/delete`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + localStorage.token
                    }, data: {
                        id
                    }
                });
                if (data.success) {
                    showToast(data.message, 'success');
                    fetchData();
                } else {
                    showToast(data.message, 'danger');
                }
            } catch (error) {
                console.error(error);
                showToast(error, 'danger');
            } finally {
                loader.hide()
            }
        }
    }

    const targetAddDevice = event.target.closest('.add-device-btn');
    if (targetAddDevice) {
        const id = targetAddDevice.getAttribute('data-id');
        createDevice(id);
    }

    const targetEditDevice = event.target.closest('.btn-edit-device');
    if (targetEditDevice) {
        const id = targetEditDevice.getAttribute('data-id');
        editDevice(id);
    }

    const targetDeleteDevice = event.target.closest('.btn-remove-device');
    if (targetDeleteDevice) {
        const id = targetDeleteDevice.getAttribute('data-id');
        const { isConfirmed, value } = await Swal.fire({
            text: 'Anda yakin akan menghapus master device ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            inputPlaceholder: 'Alasan reject',
            showLoaderOnConfirm: true,
            confirmButtonClass: 'btn btn-primary w-xs me-2',
            cancelButtonClass: 'btn btn-danger w-xs',
            buttonsStyling: false,
        })

        if (isConfirmed) {
            const loader = loading.show({});
            try {
                const { data } = await Api.delete(`api/master/lokdev/delete_device`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + localStorage.token
                    }, data: {
                        id
                    }
                });
                if (data.success) {
                    showToast(data.message, 'success');
                    fetchData();
                } else {
                    showToast(data.message, 'danger');
                }
            } catch (error) {
                console.error(error);
                showToast(error, 'danger');
            } finally {
                loader.hide()
            }
        }
    }

    const playButton = event.target.closest('.btn-play-lokasi');
    if (playButton) {
        const url = playButton.getAttribute('data-url');
        const audio = new Audio(cacheBustedUrl(url));
        audio.play();
    }

    const targetSubGrid = event.target.closest('td.details-control');
    if (targetSubGrid) {
        const tr = event.target.closest('tr');
        // const row = event.target.closest('table').dataTable().api().row(tr);
        const row = tableLokasiInstance.row(tr)

        // console.log(`tr-${row.data().key_id}`);
        if (document.getElementById(`tr-${row.data().key_id}`)) {
            // console.log(true);
            tr.classList.remove('shown')
            $(`#tr-${row.data().key_id}`).remove()
        } else {
            // console.log(false);
            expandSubGrid(tr, row.data())
            tr.classList.add('shown')
        }
        setTimeout(() => {
            tableLokasiInstance.columns.adjust()
        }, 0);
    }
}

const expandSubGrid = async (tr, data) => {
    let subGridData = []
    // let tableDev = ref()
    // let tableDeviceInstanceX = ref([])

    const loader = loading.show({});
    try {
        const response = await Api.get('api/master/lokdev/device', {
            params: { header_id: data.key_id },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.token
            },
        });
        subGridData = response.data.data
    } catch (error) {
        console.error(error);
    } finally {
        loader.hide()
    }

    tr.insertAdjacentHTML('afterend', `
        <tr id="tr-${data.key_id}">
            <td></td>
            <td colspan='5' style="max-width: 0px !important;">
                <button class="btn btn-primary mb-2 add-device-btn" id="" data-id="${data.key_id}">Tambah Device</button>
                <table style="width: 100%; height: 100%;" id='datatable-detail-${data.key_id}' class='table table-bordered table-striped datatable-details'>
                </table>
            </td>
        </tr>
    `);

    await $(`#datatable-detail-${data.key_id}`).dataTable({
        order: [],
        scrollX: true,
        data: subGridData,
        columns: [
            // { title: "ID", data: 'key_id', width: "20px", className: 'text-start' },
            { title: "No.", data: 'row_', width: "20px", className: 'text-start' },
            { title: "Kategori", data: 'kategori', className: 'text-start' },
            { title: "Nama Alat", data: 'nama_alat', className: 'text-start' },
            { title: "Merek", data: 'merek', className: 'text-start' },
            { title: "SN. Alat", data: 'sn_alat', className: 'text-start' },
            { title: "ID Device", data: 'id_device' },
            // {
            //     title: 'Status',
            //     orderable: false,
            //     data: null,
            //     className: 'text-start',
            //     render: function (data, type, row) {
            //         const text = row.status_alat ? 'Aktif' : 'Tidak aktif';
            //         const color = row.status_alat ? 'bg-success' : 'bg-secondary';

            //         const div = "<span class='badge " + color + "'>" + text + "</span>"
            //         return div;
            //     }
            // },
            { title: "Keterangan", data: 'keterangan', className: 'text-start' },
            {
                title: 'Aksi',
                orderable: false,
                data: null,
                className: 'text-start',
                render: function (data, type, row) {
                    const disabled = false ? "disabled" : ""
                    const div = `<div class="d-flex">
                                <button class="btn btn-sm btn-outline-primary btn-edit-device mr-2" ${disabled} title="Ubah Device" data-id=${row.key_id}><i class="operation-icon fas fa-pen"></i></button>
                                <button class="btn btn-sm btn-outline-danger btn-remove-device" ${disabled} title="Hapus Device" data-id=${row.key_id}><i class="operation-icon fas fa-trash-alt"></i></button>
                            </div>`;
                    return div;
                }
            },
        ],
        initComplete: function (settings, json) {
            //
            // $(`table#datatable-detail-${data.key_id} thead tr th`).removeClass('text-end');
            $(`table#datatable-detail-${data.key_id} > thead > tr`).css({visibility:'collapse'});
        },
        drawCallback: function (settings) {
            $(`table#datatable-detail-${data.key_id} > thead > tr`).css({visibility:'collapse'});
            setTimeout(() => {
                $(`#datatable-detail-${data.key_id}`).DataTable().columns.adjust()
                $(`table#datatable-detail-${data.key_id} > thead > tr`).css({visibility:'collapse'});
                tableLokasiInstance.columns.adjust()
            }, 0);

        }
    })
}

const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        modalLokasiData.value.sound = file;
    }
};

const playModalPreviewSound = () => {
    const audio = new Audio(cacheBustedUrl(modalAudioUrl.value));
    audio.play();
}

const cacheBustedUrl = (url) => {
    const timestamp = new Date().getTime();
    const separator = url.includes('?') ? '&' : '?';
    return `${url}${separator}cb=${timestamp}`;
};

onMounted(async () => {
    // console.log(Api)
    tableLokasiInstance = tableLokasi.value.dt;
    // tableDeviceInstance = tableDevice.value.dt;
    await fetchData();
    // await fetchDataDevice();

    // event listeners
    document.removeEventListener('click', clickEventHandler);
    document.addEventListener('click', clickEventHandler);

    toast.value = new bootstrap.Toast(document.getElementById('toast'), { delay: 3000 });
});

onBeforeUnmount(() => {
    document.removeEventListener('click', clickEventHandler);
});
</script>

<template>
    <div class="toast-container position-fixed top-0 end-0 p-3">
        <div id="toast" :class="'toast align-items-center border-0 ' + toastEl.bg" role="alert" aria-live="assertive"
            aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    {{ toastEl.message }}
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                    aria-label="Close"></button>
            </div>
        </div>
    </div>

    <div id="lokasi-modal" class="modal fade" aria-labelledby="lokasi-modal-label" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ lokasiModalLabel }}</h5>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form method="post" id="lokasi-form" @submit.prevent="submitLokasiHandler">
                        <div class="row">
                            <input type="hidden" class="form-control" v-model="modalLokasiData.key_id" required />
                            <div class="col-12">
                                <div class="mt-0">
                                    <label class="form-label">Unit Kerja</label>
                                    <v-select v-model="selectedUnitKerjaValue" :options="selectUnitKerjaOptions"
                                        :clearable="false" @update:modelValue="handleChangeUnitKerja"
                                        placeholder="Harap pilih unit kerja" required></v-select>
                                </div>
                            </div>
                            <div class="col-12 d-none">
                                <div class="mt-3">
                                    <label class="form-label">Keterangan</label>
                                    <input type="text" disabled class="form-control"
                                        v-model="modalLokasiData.keterangan" required>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="mt-3">
                                    <label class="form-label">Status Lokasi</label>
                                    <v-select v-model="selectedStatusLokasiValue" :options="selectUnitStatusOptions"
                                        :clearable="false" @update:modelValue="handleStatusLokasiChange"
                                        required></v-select>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="mt-3">
                                    <label class="form-label">Zona Waktu</label>
                                    <v-select v-model="selectedTimeZoneValue" :options="selectTimeZoneOptions"
                                        :clearable="false" @update:modelValue="handleTimeZoneChange"
                                        required placeholder="Isi zona waktu"></v-select>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="mt-3">
                                    <label class="form-label">File Sound</label>
                                    <input type="file" class="form-control" id="fileSoundIdEl" accept=".mp3"
                                        @change="handleFileChange">
                                </div>
                            </div>
                            <div class="col-12" id="fileTargetIdEl">
                                <div class="mt-3 row">
                                    <div class="col-12">
                                        <label class="form-label">Uploaded File Sound</label>
                                    </div>
                                    <div class="col-12">
                                        <button type="button" class="btn btn-sm btn-primary"
                                            @click="playModalPreviewSound" title="Pratinjau">Play</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="submit" form="lokasi-form" class="btn btn-success"
                        data-bs-dismiss="modal">Simpan</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
                </div>
            </div>
        </div>
    </div>

    <div id="device-modal" class="modal fade" aria-labelledby="device-modal-label" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ deviceModalLabel }}</h5>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form method="post" id="device-form" @submit.prevent="submitDeviceHandler">
                        <div class="row">
                            <input type="hidden" class="form-control" v-model="modalDeviceData.key_id" required />
                            <input type="hidden" class="form-control" v-model="modalDeviceData.key_id_header" required />
                            <div class="col-12 col-md-4">
                                <div class="mt-3 mt-md-0">
                                    <label class="form-label">Kategori</label>
                                    <v-select v-model="selectedKategoriValue" :options="selectKategoriOptions"
                                        :clearable="false" @update:modelValue="handleChangeKategori"
                                        placeholder="Harap pilih kategori" required></v-select>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="mt-3 mt-md-0">
                                    <label class="form-label">Nama Alat</label>
                                    <input type="text" class="form-control" v-model="modalDeviceData.nama_alat" placeholder="Masukkan nama alat">
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="mt-3 mt-md-0">
                                    <label class="form-label">Merek</label>
                                    <input type="text" class="form-control" v-model="modalDeviceData.merek" placeholder="Masukkan merek">
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="mt-3 mt-md-3">
                                    <label class="form-label">S.N. Alat</label>
                                    <input type="text" class="form-control" v-model="modalDeviceData.sn_alat" required placeholder="Masukkan S.N. alat">
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="mt-3 mt-md-3">
                                    <label class="form-label">ID Device</label>
                                    <input type="text" class="form-control" :disabled="isIdDeviceDisabled" v-model="modalDeviceData.id_device" required>
                                    <small id="id_device_hint" :class="['form-text', 'text-muted', isIdDeviceHintShow]">Generated by system.</small>
                                </div>
                            </div>
                            <div class="col-12 col-md-4 d-none">
                                <div class="mt-3 mt-md-3">
                                    <label class="form-label">Status Lokasi</label>
                                    <v-select v-model="selectedStatusAlatValue" :options="selectStatusAlatOption"
                                        :clearable="false" @update:modelValue="handleStatusAlatChange"
                                        required></v-select>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="mt-3 mt-md-3">
                                    <label class="form-label">Keterangan</label>
                                    <input type="text" class="form-control" v-model="modalDeviceData.keterangan" placeholder="Masukkan keterangan alat">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="submit" form="device-form" class="btn btn-success"
                        data-bs-dismiss="modal">Simpan</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="card">
            <div class="card-header">
                <h5 class="mb-0">Lokasi & Device</h5>
            </div>
            <div class="card-body">
                <button class="btn btn-primary mb-2" @click="createLokasi">Tambah Lokasi</button>
                <DataTable :columns="columns" :data="tableLokasiData" :options="dataTableOptions" ref="tableLokasi" 
                    class="table table-bordered dataTableLokasiClass" width="100%"></DataTable>
            </div>
            <!-- <button class="btn btn-primary mb-2" @click="createDevice">Tambah Device</button>
            <DataTable id="tableDeviceEl" :columns="columnsDevice" :data="tableDeviceData" :options="dataTableOptions"
                ref="tableDevice" class="table table-bordered table-striped" width="100%"></DataTable> -->
        </div>
    </div>
</template>

<style>
@import 'bootstrap';
@import 'datatables.net-bs5';
</style>

<style scoped>
.select2-dropdown {
    z-index: 99999 !important;
    /* Ensure this is higher than the modal z-index */
}

.xxx {
    position: relative;
    /* Ensure the parent has positioning */
}
</style>

<style>
table.datatable-details > thead > tr > th {
    padding: 8px 30px 8px 8px !important;
    min-height: 41.2px !important;
}

div.dt-scroll-body > table.datatable-details > thead {
    /* display: none !important; */
    /* height: 1px !important; */
}

div.dt-scroll-body > table.dataTableLokasiClass {
    overflow: hidden !important;
}
</style>